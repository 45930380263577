import * as React from "react";
import { Alert } from "react-bootstrap";
import "./Alert.css";
import { apiErrorStr } from "../../helpers";

interface AlertInfo {
  id?: number;
  title?: string;
  body?: string;
  bsClass?: string;
  bsStyle?: string;
  dismissAfterMs?: number;
}

export interface State {
  alerts: Array<AlertInfo>;
}

export default class MyAlerts extends React.Component<{}, State> {
  state: State = {
    alerts: [],
  };

  nextId = 1;

  // convenience
  addApiErrorAlert(e: any) {
    this.addAlert({
      title: "Error",
      bsClass: "alert-danger",
      body: apiErrorStr(e),
    });
  }

  addApiSuccessAlert(body: string, opts?: Partial<AlertInfo>) {
    this.addAlert({
      title: "Success!",
      bsClass: "alert-success",
      dismissAfterMs: 5000,
      body,
      ...opts,
    });
  }

  addAlert(alert: AlertInfo) {
    const id = this.nextId++;
    this.setState((prevState) => ({
      alerts: [...prevState.alerts, { ...alert, id }],
    }));

    if (alert.dismissAfterMs) {
      setTimeout(() => {
        this.dismissAlert(id);
      }, alert.dismissAfterMs);
    }
  }

  dismissAlert = (id: number) => {
    this.setState((prevState) => ({
      alerts: prevState.alerts.filter((a) => a.id !== id),
    }));
  };

  render() {
    const { alerts } = this.state;

    return (
      <div className="sticky-alert">
        <div style={{ position: "absolute", left: 0, right: 0 }}>
          {alerts.map((alert) => (
            <Alert
              key={"a_" + alert.id}
              onDismiss={() => alert.id && this.dismissAlert(alert.id)}
              bsClass={`alert ${alert.bsClass}`}
              role="alert"
            >
              <strong>{alert.title}</strong>
              <br />
              {alert.body}
            </Alert>
          ))}
        </div>
      </div>
    );
  }
}
