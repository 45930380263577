import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";
import {
  FetchParams,
  reduceSetFetchParams,
  defaultPageSize,
  reduceNextPage,
  reducePrevPage,
  apiClientFactory,
} from "../helpers";

import { TenantResponse } from "../api/ApiClient";

export interface TenantsState extends FetchParams {
  isLoading: boolean;
  tenants: TenantResponse[];
}

interface RegisterTenantActionPayload {
  ownerClientId: string;
}
interface RegisterTenantAction extends RegisterTenantActionPayload {
  type: "REGISTER_TENANT";
}

interface RequestTenantsAction {
  type: "REQUEST_TENANTS";
}

interface SetFetchParams extends FetchParams {
  type: "SETFETCHPARAMS_TENANTS";
}

interface NextPage {
  type: "NEXTPAGE_TENANTS";
}

interface PrevPage {
  type: "PREVPAGE_TENANTS";
}

interface ReceiveTenantsAction extends FetchParams {
  type: "RECEIVE_TENANTS";
  tenants: TenantResponse[];
}

interface AddUserToTenantActionPayload {
  tenantId: string;
  userId: string;
  role: string;
}
interface AddUserToTenantAction extends AddUserToTenantActionPayload {
  type: "ADD_USER_TO_TENANT";
}

type KnownAction =
  | RequestTenantsAction
  | RegisterTenantAction
  | SetFetchParams
  | NextPage
  | PrevPage
  | ReceiveTenantsAction
  | AddUserToTenantAction;

export const actionCreators = {
  setFetchParamsTenants:
    ({
      page,
      pageSize,
      searchTerm,
    }: FetchParams): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const state = getState();
      if (state.tenants.isLoading) return;

      dispatch({
        type: "SETFETCHPARAMS_TENANTS",
        page,
        pageSize,
        searchTerm,
      });

      await dispatch(actionCreators.requestTenants() as any);
    },
  nextTenantsPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.tenants.isLoading) return;

      dispatch({
        type: "NEXTPAGE_TENANTS",
      });

      await dispatch(actionCreators.requestTenants() as any);
    },
  prevTenantsPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.tenants.isLoading) return;

      dispatch({
        type: "PREVPAGE_TENANTS",
      });

      await dispatch(actionCreators.requestTenants() as any);
    },

  requestTenants: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const {
      tenants: { page, pageSize, searchTerm, tenants: curTenants },
    } = getState();

    const fetchTask = apiClientFactory()
      .getTenants(page, pageSize, searchTerm ? searchTerm : undefined)
      .then(
        (data) => {
          dispatch({
            type: "RECEIVE_TENANTS",
            tenants: data,
            page,
            pageSize,
            searchTerm,
          });
        },
        (_error) => {
          dispatch({
            type: "RECEIVE_TENANTS",
            tenants: curTenants,
            page,
            pageSize,
            searchTerm,
          });
        }
      );

    dispatch({
      type: "REQUEST_TENANTS",
    });
    return fetchTask;
  },

  addUserToTenant:
    (info: AddUserToTenantActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const json = await apiClientFactory().inviteToTenant(
        info.userId,
        info.tenantId,
        info.role
      );
      return json;
    },

  addBackendUserToTenant:
    (info: {
      tenantExternalId: string;
      backendNameClaimId: string;
    }): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const json = await apiClientFactory().inviteBackendToTenant({
        tenantExternalId: info.tenantExternalId,
        backendNameClaimId: info.backendNameClaimId,
      });

      return json;
    },

  addAccessControlGroupToTenant:
    (info: {
      accessControlGroupId: string;
      tenantId: string;
    }): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const json = await apiClientFactory().addAcgToTenant(
        info.accessControlGroupId,
        info.tenantId
      );

      return json;
    },

  registerTenant:
    (info: RegisterTenantActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const json = await apiClientFactory().postTenant({
        externalId: info.ownerClientId,
      });

      return json;
    },
};

const unloadedState: TenantsState = {
  tenants: [],
  isLoading: false,
  page: 0,
  pageSize: defaultPageSize,
  searchTerm: "",
};

export const reducer: Reducer<TenantsState> = (
  state: TenantsState = unloadedState,
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "REQUEST_TENANTS":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVE_TENANTS":
      return {
        ...state,
        tenants: action.tenants,
        isLoading: false,
        page: action.page,
        pageSize: action.pageSize,
        searchTerm: action.searchTerm,
      };

    case "SETFETCHPARAMS_TENANTS":
      return reduceSetFetchParams(state.isLoading, action, state);
    case "NEXTPAGE_TENANTS":
      return reduceNextPage(state.tenants, state.isLoading, state);
    case "PREVPAGE_TENANTS":
      return reducePrevPage(state.isLoading, state);

    case "ADD_USER_TO_TENANT":
    case "REGISTER_TENANT":
    default:
      return state;
  }
};
