import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as SitesState from "../../store/Sites";
import * as ScreensState from "../../store/Screens";
import { confirmPrompt, isAdmin } from "../../helpers";
import { ScreenResponse, ScreenLayoutType } from "../../api/ApiClient";
import SelectScreen from "../shared/SelectScreen";
import * as Auth0State from "../../store/Auth0";

import Modal from "react-bootstrap-modal";

import "../shared/customModal.css";
import { MeState } from "../../store/Me";

type OwnProps = RouteComponentProps<{ startDateIndex: string }>;

interface Injected {}
type Props = Injected &
  typeof ScreensState.actionCreators &
  typeof SitesState.actionCreators &
  OwnProps;

const initialPlayerIds = [""];
const initialTextAndImages = [
  {
    text: "",
    imageUrl: "",
    imagePublicId: "",
    description: "",
    minImageWidth: "",
    minImageHeight: "",
  },
];

interface State {
  inProgress: Object | null;
  editingScreen: ScreenResponse | null;
}

type ArrType = "playerIds" | "tileFragments";

class AdminScreens extends React.Component<Props, State> {
  state: State = {
    inProgress: null,
    editingScreen: null,
  };

  deleteScreen = (screen: ScreenResponse) => {
    confirmPrompt({
      promptMessage: "Are you sure you want to delete this screen?",
      onConfirm: async () => {
        try {
          await this.props.deleteScreen({
            id: screen.id || "",
          });
          await this.props.requestScreens();
        } catch (e) {
          alert("Error deleting screen: " + JSON.stringify(e));
        }
      },
    });
  };

  showEditScreenModal = (screen: ScreenResponse) => {
    this.setState({ editingScreen: screen });
  };

  closeModal = () => {
    this.setState({ editingScreen: null });
  };

  onUpdatedScreen = async () => {
    this.closeModal();
    await this.props.requestScreens();
  };

  public render() {
    const { editingScreen } = this.state;

    return (
      <div>
        <div>{this.renderScreensTable()}</div>

        <Modal
          show={!!editingScreen}
          onHide={this.closeModal}
          aria-labelledby="ModalHeader"
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="ModalHeader">Edit Screen Tile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <RegisterOrEditScreen
              //@ts-ignore
              existingScreen={editingScreen}
              accountId={""}
              onRegistered={() => this.onUpdatedScreen()}
            />
          </Modal.Body>
          <Modal.Footer>
            <Modal.Dismiss className="btn btn-default">Close</Modal.Dismiss>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private renderScreenButton = (screen: ScreenResponse) => {
    return (
      <div>
        <button
          type="button"
          className={`btn btn-danger`}
          onClick={() => this.deleteScreen(screen)}
        >
          Delete
        </button>
        <p />
        <button
          type="button"
          className={`btn btn-secondary`}
          onClick={() => this.showEditScreenModal(screen)}
        >
          Edit
        </button>
      </div>
    );
  };

  private renderScreensTable() {
    return (
      <div>
        <p />
        {
          //@ts-ignore
          <SelectScreen
            pageSize={5}
            renderButton={this.renderScreenButton}
            unselectedLabel="Delete"
            unselectedBtnClass="btn-danger"
          />
        }
        <hr />
      </div>
    );
  }
}

type RegisterOwnProps = {
  accountId: string; // if creating
  existingScreen?: ScreenResponse; // if editing
  screenInviteId?: string; // editing as guest
  onRegistered: () => any;
};

type RegisterProps = Injected &
  typeof ScreensState.actionCreators &
  typeof SitesState.actionCreators &
  RegisterOwnProps;

interface TileFragmentInfo {
  description: string;
  text: string;
  imageUrl: string;
  imagePublicId: string;
  minImageWidth: string;
  minImageHeight: string;
}

interface RegisterState {
  inProgress: Object | null;
  //create screen
  layoutType: ScreenLayoutType;
  name: string;
  appId: string;
  description: string;
  //tiles
  playerIds: string[];
  tileFragments: TileFragmentInfo[];
}

function stateFromScreen(screen: ScreenResponse): Partial<RegisterState> {
  return {
    name: screen.name || "",
    appId: screen.appId || "",
    description: screen.description || "",
    playerIds: screen.playerIds ? screen.playerIds.map((p) => p.playerId) : [],
    tileFragments: screen.tileFragments
      ? screen.tileFragments.map((t) => ({
          text: t.text || "",
          imagePublicId: t.imagePublicId || "",
          imageUrl: t.imageUrl || "",
          description: t.description || "",
          minImageWidth: t.minImageWidth ? t.minImageWidth + "" : "",
          minImageHeight: t.minImageHeight ? t.minImageHeight + "" : "",
        }))
      : [],
  };
}

class RegisterOrEditScreenComp extends React.Component<
  RegisterProps & { auth0: Auth0State.Auth0State; me: MeState },
  RegisterState
> {
  state: RegisterState = {
    inProgress: null,
    layoutType: ScreenLayoutType.SomeTiles,
    name: "",
    appId: "",
    description: "",
    //tiles
    playerIds: initialPlayerIds,
    tileFragments: initialTextAndImages,
    ...(this.props.existingScreen
      ? stateFromScreen(this.props.existingScreen)
      : {}),
  };

  createCloudinaryCb =
    (i: number, curTileFragment: TileFragmentInfo) =>
    (error: any, result: any) => {
      if (result && result.event === "success") {
        this.updateArrVal(
          "tileFragments",
          {
            ...curTileFragment,
            imageUrl: result.info.secure_url,
            imagePublicId: result.info.public_id,
          },
          i
        );
      }
    };

  uploadToCloudinary = (i: number, curTileFragment: TileFragmentInfo) => {
    const { appId } = this.state;
    const minW = curTileFragment.minImageWidth
      ? parseFloat(curTileFragment.minImageWidth)
      : undefined;

    const minH = curTileFragment.minImageHeight
      ? parseFloat(curTileFragment.minImageHeight)
      : undefined;
    const ratio = minW && minH && minH > 0 ? minW / minH : undefined;

    const cloudinaryWidget = (window as any).createAndOpenCloudinaryWidget(
      {
        folder: `signage/${appId}`,
        croppingValidateDimensions: true,
        multiple: false,
        minImageWidth: minW,
        minImageHeight: minH,
        croppingAspectRatio: ratio,
        cropping: true,
        croppingShowDimensions: true,
        croppingShowBackButton: true,
      },
      this.createCloudinaryCb(i, curTileFragment)
    );
    if (!cloudinaryWidget) {
      alert("No upload widget found");
      return;
    }
  };

  registerScreen = (
    e: any,
    { acceptAndDeploy } = { acceptAndDeploy: false }
  ) => {
    e.preventDefault();

    const { session: auth0Session } = this.props.auth0;

    this.setState(
      {
        inProgress: { ...(this.state.inProgress || {}), registerScreen: true },
      },
      async () => {
        const state = this.state;

        // TODO delete stale images from cloudinary
        // TODO add new images to cloudinary

        try {
          const json =
            this.props.screenInviteId && this.props.existingScreen
              ? ((await this.props.guestUpdateScreen({
                  screenInviteId: this.props.screenInviteId,
                  accessToken: auth0Session
                    ? auth0Session.accessToken || ""
                    : "",
                  updateRequest: {
                    id: this.props.existingScreen.id || "",
                    acceptAndDeploy: false,
                    accountId: this.props.existingScreen.accountId || "",
                    layoutType: state.layoutType,
                    name: state.name,
                    appId: state.appId,
                    description: state.description,
                    playerIds: state.playerIds,
                    tileFragments: state.tileFragments.map((f) => ({
                      ...f,
                      minImageWidth: f.minImageWidth
                        ? parseFloat(f.minImageWidth)
                        : undefined,
                      minImageHeight: f.minImageHeight
                        ? parseFloat(f.minImageHeight)
                        : undefined,
                    })),
                  },
                })) as any)
              : this.props.existingScreen
              ? ((await this.props.updateScreen({
                  id: this.props.existingScreen.id || "",
                  acceptAndDeploy,
                  accountId: this.props.existingScreen.accountId || "",
                  layoutType: state.layoutType,
                  name: state.name,
                  appId: state.appId,
                  description: state.description,
                  playerIds: state.playerIds,
                  tileFragments: state.tileFragments.map((f) => ({
                    ...f,
                    minImageWidth: f.minImageWidth
                      ? parseFloat(f.minImageWidth)
                      : undefined,
                    minImageHeight: f.minImageHeight
                      ? parseFloat(f.minImageHeight)
                      : undefined,
                  })),
                })) as any)
              : ((await this.props.registerScreen({
                  accountId: this.props.accountId,
                  layoutType: state.layoutType,
                  name: state.name,
                  appId: state.appId,
                  description: state.description,
                  playerIds: state.playerIds,
                  tileFragments: state.tileFragments.map((f) => ({
                    ...f,
                    minImageWidth: f.minImageWidth
                      ? parseFloat(f.minImageWidth)
                      : undefined,
                    minImageHeight: f.minImageHeight
                      ? parseFloat(f.minImageHeight)
                      : undefined,
                  })),
                })) as any);
          await this.props.requestScreens();
          alert(
            "Successfully " +
              (this.props.existingScreen
                ? acceptAndDeploy
                  ? "accepted"
                  : "updated"
                : "registered") +
              " screen: " +
              JSON.stringify(json)
          );
          this.props.onRegistered();
          this.setState({
            //clear inputs
            name: "",
            description: "",
            appId: "",
            playerIds: initialPlayerIds,
            tileFragments: initialTextAndImages,
          });
        } catch (e) {
          alert("Error registering screen: " + JSON.stringify(e));
        } finally {
          this.setState({
            inProgress: {
              ...(this.state.inProgress || {}),
              registerScreen: false,
            },
          });
        }
      }
    );
  };

  render = () => {
    const { inProgress, layoutType, name, appId, description } = this.state;

    const { registerScreen: registerScreenInProgress = false } = (inProgress ||
      {}) as any;

    const canFullyEdit = this.canFullyEdit();

    return (
      <form>
        <div className="form-group">
          <label htmlFor="name">Name *</label>
          <input
            className="form-control"
            name="name"
            placeholder="Tile Name"
            value={name}
            disabled={!canFullyEdit}
            onChange={(e) => this.setState({ name: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label htmlFor="name">Tile Id *</label>
          <input
            className="form-control"
            name="appId"
            placeholder="Tile Id"
            value={appId}
            disabled={!canFullyEdit}
            onChange={(e) => this.setState({ appId: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label htmlFor="description">Description *</label>
          <textarea
            className="form-control"
            name="description"
            placeholder="Screen Description"
            value={description}
            disabled={!canFullyEdit}
            onChange={(e) => this.setState({ description: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label htmlFor="layoutType">Layout Type *</label>
          <select
            className="form-control"
            disabled={!canFullyEdit}
            name="layoutType"
            placeholder="Layout Type"
            value={layoutType}
            onChange={(e) =>
              this.setState({ layoutType: e.target.value as any })
            }
          >
            <option value="SomeTiles">SomeTiles</option>
          </select>
        </div>

        <div style={{ marginTop: 30 }}>{this.renderPlayerIds()}</div>

        {this.renderFragments()}

        <p />
        <div style={{ marginTop: 20 }}>
          <button
            type="submit"
            disabled={!!registerScreenInProgress}
            className="btn btn-primary"
            onClick={this.registerScreen}
          >
            Upload Screen Tile
          </button>
        </div>
      </form>
    );
  };

  private updateArrVal = (key: ArrType, value: any, i: number) => {
    const values = [...this.state[key]];
    values[i] = value;
    this.setState({ [key]: values } as any);
  };

  private deleteArrVal = (key: ArrType, i: number) => {
    const values = [...this.state[key]];
    values.splice(i, 1);
    this.setState({ [key]: values } as any);
  };

  private addArrVal = (key: ArrType, newVal: any) => {
    const values = [...this.state[key]];
    values.push(newVal);
    this.setState({ [key]: values } as any);
  };

  private renderPlayerIds() {
    return this.canFullyEdit()
      ? this.renderPlayerIdsFullEdit()
      : this.renderPlayerIdsDisplay();
  }
  private renderFragments() {
    return this.canFullyEdit() ? (
      <div style={{ marginTop: 30 }}>{this.renderFragmentsFullEdit()}</div>
    ) : (
      <div style={{ marginTop: 10 }}>{this.renderFragmentsPartialEdit()}</div>
    );
  }

  private canFullyEdit() {
    const { screenInviteId } = this.props;
    const canFullyEdit = !screenInviteId && isAdmin(this.props.me.user);
    return canFullyEdit;
  }

  private renderPlayerIdsDisplay() {
    const { playerIds } = this.state;

    return (
      <React.Fragment>
        <h5 className="text-muted">Player/Player Group IDs</h5>
        {playerIds.map((p, i) => (
          <React.Fragment key={"p_" + i}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <div className="form-group">
                <label htmlFor={"playerId_" + i}>Id</label>
                <input
                  className="form-control"
                  name={"playerId_" + i}
                  placeholder="player/player group id"
                  value={p}
                  disabled={true}
                  onChange={(e) =>
                    this.updateArrVal("playerIds", e.target.value, i)
                  }
                />
              </div>
            </div>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }

  private renderPlayerIdsFullEdit() {
    const { playerIds } = this.state;

    return (
      <React.Fragment>
        <h5 className="text-muted">Player/Player Group IDs</h5>
        {playerIds.map((p, i) => (
          <React.Fragment key={"p_" + i}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <button
                type="button"
                style={{ marginRight: 5, marginTop: 26 }}
                className="btn btn-sm btn-danger"
                onClick={() => this.deleteArrVal("playerIds", i)}
              >
                Delete
              </button>
              <div className="form-group">
                <label htmlFor={"playerId_" + i}>
                  Player Or Player Group Id
                </label>
                <input
                  className="form-control"
                  name={"playerId_" + i}
                  placeholder="player/player group id"
                  value={p}
                  onChange={(e) =>
                    this.updateArrVal("playerIds", e.target.value, i)
                  }
                />
              </div>
            </div>
          </React.Fragment>
        ))}
        <button
          type="button"
          className="btn btn-sm btn-default"
          onClick={() => this.addArrVal("playerIds", "")}
        >
          Add
        </button>
      </React.Fragment>
    );
  }

  // Only allows to edit text & image - not tileId/playerGroupId/name/descriptions
  private renderFragmentsPartialEdit() {
    const { tileFragments } = this.state;

    return (
      <React.Fragment>
        <h5 className="text-muted">Fragments</h5>
        <ul>
          {tileFragments.map((p, i) => {
            const imageRequirements = imageRequirementsStr(p);

            return (
              <React.Fragment key={"p_" + i}>
                <li>
                  {p.description && (
                    <h5 className="text-info">{p.description || ""}</h5>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                    }}
                  >
                    <div>
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          name={"text" + i}
                          placeholder="text"
                          value={p.text}
                          onChange={(e) =>
                            this.updateArrVal(
                              "tileFragments",
                              {
                                ...p,
                                text: e.target.value,
                              },
                              i
                            )
                          }
                        />
                      </div>
                      {imageRequirements && (
                        <React.Fragment>
                          <h5 className="text-info">
                            Image Requirements: <br />
                            {imageRequirements}
                          </h5>
                          <div className="form-group">
                            <div>{p.imageUrl}</div>
                            <button
                              type="button"
                              className="btn btn-sm btn-default"
                              onClick={() => this.uploadToCloudinary(i, p)}
                            >
                              Pick Image
                            </button>
                          </div>
                          {p.imageUrl && (
                            <img
                              src={p.imageUrl}
                              alt={p.description}
                              style={{ width: 100, height: 100 }}
                            />
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  <br />
                </li>
              </React.Fragment>
            );
          })}
        </ul>
      </React.Fragment>
    );
  }

  private renderFragmentsFullEdit() {
    const { tileFragments } = this.state;
    return (
      <React.Fragment>
        <h5 className="text-muted">Fragments</h5>
        <ul>
          {tileFragments.map((p, i) => (
            <React.Fragment key={"p_" + i}>
              <li>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <button
                    type="button"
                    style={{ marginRight: 5, marginTop: 26 }}
                    className="btn btn-sm btn-danger"
                    onClick={() => this.deleteArrVal("tileFragments", i)}
                  >
                    Delete
                  </button>
                  <div>
                    <div className="form-group">
                      <label htmlFor={"text" + i}>Description</label>
                      <input
                        className="form-control"
                        name={"description_" + i}
                        placeholder="'Title with background image', or 'Subtitle', etc."
                        value={p.description}
                        onChange={(e) =>
                          this.updateArrVal(
                            "tileFragments",
                            {
                              ...p,
                              description: e.target.value,
                            },
                            i
                          )
                        }
                      />
                      <p className="help-block">
                        Describe the purpose of this fragment, e.g Title,
                        Subtitle
                      </p>
                    </div>
                    <div className="form-group">
                      <label htmlFor={"text" + i}>Sample Text</label>
                      <textarea
                        className="form-control"
                        name={"text" + i}
                        placeholder="text"
                        value={p.text}
                        onChange={(e) =>
                          this.updateArrVal(
                            "tileFragments",
                            {
                              ...p,
                              text: e.target.value,
                            },
                            i
                          )
                        }
                      />
                    </div>

                    <h6 className="text-muted">Image Dimensions (optional):</h6>

                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div className="form-group" style={{ marginRight: 5 }}>
                        <label htmlFor={"min_w_" + i}>Min Width</label>
                        <input
                          className="form-control"
                          name={"min_w_" + i}
                          placeholder="min width"
                          value={p.minImageWidth}
                          onChange={(e) =>
                            this.updateArrVal(
                              "tileFragments",
                              {
                                ...p,
                                minImageWidth: e.target.value,
                              },
                              i
                            )
                          }
                        />
                      </div>
                      <div className="form-group" style={{ marginRight: 5 }}>
                        <label htmlFor={"min_h_" + i}>Min Height</label>
                        <input
                          className="form-control"
                          name={"min_h_" + i}
                          placeholder="min height"
                          value={p.minImageHeight}
                          onChange={(e) =>
                            this.updateArrVal(
                              "tileFragments",
                              {
                                ...p,
                                minImageHeight: e.target.value,
                              },
                              i
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <div>{p.imageUrl}</div>
                      <button
                        type="button"
                        className="btn btn-sm btn-default"
                        onClick={() => this.uploadToCloudinary(i, p)}
                      >
                        Pick Sample Image
                      </button>
                    </div>
                    {p.imageUrl && (
                      <img
                        src={p.imageUrl}
                        alt={p.description}
                        style={{ width: 100, height: 100 }}
                      />
                    )}
                  </div>
                </div>
                <br />
              </li>
            </React.Fragment>
          ))}
        </ul>
        <button
          type="button"
          className="btn btn-sm btn-default"
          onClick={() =>
            this.addArrVal("tileFragments", {
              text: "",
              imageUrl: "",
              description: "",
              minImageWidth: "",
              minImageHeight: "",
            })
          }
        >
          Add
        </button>
      </React.Fragment>
    );
  }
}

function imageRequirementsStr(tileFragment: TileFragmentInfo) {
  const minW = tileFragment.minImageWidth
    ? parseFloat(tileFragment.minImageWidth)
    : undefined;

  const minH = tileFragment.minImageHeight
    ? parseFloat(tileFragment.minImageHeight)
    : undefined;
  const ratio = minW && minH && minH > 0 ? minW / minH : undefined;

  const requirements = [
    minW && minW >= 0 ? "min width: " + minW + "px" : "",
    minH && minH >= 0 ? "min height: " + minH + "px" : "",
    ratio ? "ratio: " + ratio.toFixed(2) : "",
  ].filter((r) => !!r);

  return requirements.join(", ");
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    screens: state.screens,
  }),
  {
    ...ScreensState.actionCreators,
  }
)(AdminScreens);

export const RegisterOrEditScreen = connect<
  any,
  any,
  RegisterOwnProps,
  ApplicationState
>(
  (state) => ({
    screens: state.screens,
    auth0: state.auth0,
    me: state.me,
  }),
  {
    ...ScreensState.actionCreators,
  }
)(RegisterOrEditScreenComp);
