import { useEffect, useState } from "react";
import * as React from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { apiClientFactory } from "../helpers";

import {
  GuestResponse,
  OnboardUnitInfo,
  PhysicalKeyResponse,
} from "../api/ApiClient";

function GuestDetails() {
  // Declare a new state variable, which we'll call "count"
  const [siteGuest, setSiteGuest] = useState<GuestResponse | null>(null);
  const [siteGuestKeys, setSiteGuestKeys] = useState<PhysicalKeyResponse[]>([]);
  const [keys, setKeys] = useState<PhysicalKeyResponse[]>([]);

  const [guestLoading, setGuestLoading] = useState<boolean>(false);
  const [guestKeysLoading, setGuestKeysLoading] = useState<boolean>(false);
  const [keysLoading, setKeysLoading] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();

  const { siteId, guestId } = useParams();

  const decodedGuestId = decodeURIComponent(guestId);

  const fetchSiteGuest = async function () {
    setGuestLoading(true);
    const response = await apiClientFactory().getSiteGuest(
      siteId,
      decodedGuestId
    );
    setSiteGuest(response);
    setGuestLoading(false);
  };

  const fetchSiteGuestKeys = async function () {
    setGuestKeysLoading(true);
    const response = await apiClientFactory().getGuestPhysicalKeys(
      siteId,
      decodedGuestId
    );
    setSiteGuestKeys(response);
    setGuestKeysLoading(false);
  };

  const fetchAllKeys = async function () {
    setKeysLoading(true);
    const response = await apiClientFactory().getPhysicalKeys(
      siteId,
      undefined,
      undefined
    );
    const filtered = response.filter(
      (e) => e.guestId === null || e.guestId === undefined || e.guestId === ""
    );
    setKeys(filtered);
    setKeysLoading(false);
  };

  const UnassignKey = async function (credential: string) {
    await apiClientFactory().updatePhysicalKey(siteId, credential, {
      guestId: "",
    });
    fetchSiteGuestKeys();
    fetchAllKeys();
  };

  const AssignKey = async function (credential: string) {
    await apiClientFactory().updatePhysicalKey(siteId, credential, {
      guestId: decodedGuestId,
    });
    fetchSiteGuestKeys();
    fetchAllKeys();
  };

  const RevokeKey = async function (credential: string) {
    await apiClientFactory().updatePhysicalKey(siteId, credential, {
      revoked: true,
    });
    fetchSiteGuestKeys();
    fetchAllKeys();
  };

  const expireAccess = async function (event: React.FormEvent) {
    event.preventDefault();
    if (
      siteGuest !== null &&
      siteGuest.id !== undefined &&
      siteGuest.accessControlGroups !== undefined &&
      siteGuest.accessControlGroups.length > 0
    ) {
      const validFrom = siteGuest.validFrom;
      const today = new Date(new Date().toDateString());
      const yesterday = new Date(today);

      yesterday.setDate(yesterday.getDate() - 1);

      const validTo = yesterday;
      const acgs = siteGuest.accessControlGroups
        ? siteGuest.accessControlGroups
        : [];
      let unitAcgs: OnboardUnitInfo[] = acgs
        .filter((e) => e.unitNumber && e.unitNumber.length > 0)
        .map((e) => {
          return {
            unitUniqueName: e.unitNumber ? e.unitNumber : "n/a",
            buildingUniqueName: e.propertyExternalId
              ? e.propertyExternalId
              : "n/a",
            validFrom: validFrom,
            validTo: validTo,
          };
        });
      const additionalGroups = acgs.filter(
        (e) => !e.unitNumber || e?.unitNumber?.length === 0
      );
      additionalGroups.forEach((e) => {
        let unitAcg: OnboardUnitInfo | undefined;
        unitAcg = unitAcgs.find(
          (e2) => e.propertyExternalId === e2.buildingUniqueName
        );
        if (!unitAcg) {
          unitAcg = {
            unitUniqueName: "n/a",
            buildingUniqueName: e.propertyExternalId
              ? e.propertyExternalId
              : "n/a",
            validFrom: validFrom,
            validTo: validTo,
          };
          unitAcgs = [...unitAcgs, unitAcg];
        }
        unitAcg.groups = unitAcg.groups
          ? [
              ...unitAcg.groups,
              { groupUniqueName: e.externalId ? e.externalId : "" },
            ]
          : [{ groupUniqueName: e.externalId ? e.externalId : "" }];
      });

      await apiClientFactory().createOnboardInvite({
        validFrom: validFrom,
        validTo: validTo,
        attemptUpdatingExistingAssaEndpoints: false,
        guestId: siteGuest.id,
        guestFirstName: siteGuest.firstName,
        guestLastName: siteGuest.lastName,
        units: unitAcgs,
      });

      history.goBack();
    }
  };

  const progressStyle: React.CSSProperties = {
    width: "100%",
  };

  useEffect(() => {
    (async () => {
      fetchSiteGuest();
      fetchSiteGuestKeys();
      fetchAllKeys();
    })();
    // eslint-disable-next-line
  }, [location]);
  return (
    <main className="container">
      <div className="row">
        <div className=" col-xs-12">
          <div className="panel panel-default">
            <div className="panel-heading">
              <h3>Guest Details</h3>
            </div>
            {guestLoading ? (
              <div className="panel-body">
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped active"
                    role="progressbar"
                    aria-valuenow={100}
                    style={progressStyle}
                  >
                    Loading
                  </div>
                </div>
              </div>
            ) : (
              <div className="panel-body">
                {siteGuest === null ? (
                  <div className="alert alert-warning">No Guest Found</div>
                ) : (
                  <div>
                    <Link
                      to={`/admin/sites/${siteId}/onboardguest?guestId=${
                        siteGuest.id ? encodeURIComponent(siteGuest.id) : ""
                      }`}
                      className="btn btn-primary"
                    >
                      Edit Guest
                    </Link>
                    {siteGuest.validTo !== undefined &&
                    siteGuest.accessControlGroups !== undefined &&
                    siteGuest.accessControlGroups.length > 0 &&
                    new Date(siteGuest.validTo) > new Date() ? (
                      <button
                        className="pull-right btn btn-danger"
                        onClick={(e) => expireAccess(e)}
                      >
                        Expire Access
                      </button>
                    ) : (
                      <React.Fragment />
                    )}
                    <dl>
                      <dt>ID</dt>
                      <dd>{siteGuest.id}</dd>
                      <dt>First Name</dt>
                      <dd>{siteGuest.firstName}</dd>
                      <dt>Last Name</dt>
                      <dd>{siteGuest.lastName}</dd>
                      <dt>Valid From</dt>
                      <dd>
                        {siteGuest.validFrom
                          ? new Date(siteGuest.validFrom).toLocaleString()
                          : ""}
                      </dd>
                      <dt>Valid To</dt>
                      <dd>
                        {siteGuest.validTo
                          ? new Date(siteGuest.validTo).toLocaleString()
                          : ""}
                      </dd>
                    </dl>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="col-xs-12 col-lg-6">
          <div className="panel panel-default">
            <div className="panel-heading">
              <h3>Locks</h3>
            </div>
            {guestLoading ? (
              <div className="panel-body">
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped active"
                    role="progressbar"
                    aria-valuenow={100}
                    style={progressStyle}
                  >
                    Loading
                  </div>
                </div>
              </div>
            ) : (
              <div className="panel-body">
                {siteGuest &&
                siteGuest.locks &&
                siteGuest.locks.length &&
                siteGuest.locks.length > 0 ? (
                  <div>
                    <table className="table table-hover table-responsive">
                      <thead>
                        <tr>
                          <th>Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {siteGuest.locks.map((e) => (
                          <tr key={e.id}>
                            <td>{e.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="alert alert-warning">No Locks Found</div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="col-xs-12 col-lg-6">
          <div className="panel panel-default">
            <div className="panel-heading">
              <h3>Access Control Groups</h3>
            </div>
            {guestLoading ? (
              <div className="panel-body">
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped active"
                    role="progressbar"
                    aria-valuenow={100}
                    style={progressStyle}
                  >
                    Loading
                  </div>
                </div>
              </div>
            ) : (
              <div className="panel-body">
                {siteGuest &&
                siteGuest.accessControlGroups &&
                siteGuest.accessControlGroups.length &&
                siteGuest.accessControlGroups.length > 0 ? (
                  <div>
                    <table className="table table-hover table-responsive">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Type</th>
                          <th>Property External ID</th>
                          <th>Unit Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        {siteGuest.accessControlGroups.map((e) => (
                          <tr key={e.id}>
                            <td>{e.name}</td>
                            <td>{e.type}</td>
                            <td>{e.propertyExternalId}</td>
                            <td>{e.unitNumber}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="alert alert-warning">
                    No Access Control Groups Found
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <div className="panel panel-default">
            <div className="panel-heading">
              <h3>Guest Keys</h3>
            </div>
            {guestKeysLoading ? (
              <div className="panel-body">
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped active"
                    role="progressbar"
                    aria-valuenow={100}
                    style={progressStyle}
                  >
                    Loading
                  </div>
                </div>
              </div>
            ) : (
              <div className="panel-body">
                {siteGuestKeys && siteGuestKeys.length > 0 ? (
                  <div>
                    <table className="table table-hover table-responsive">
                      <thead>
                        <tr>
                          <th>Badge ID</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {siteGuestKeys.map((e) => (
                          <tr key={e.badgeId}>
                            <td>{e.badgeId}</td>
                            <td>
                              <button
                                className="btn btn-default"
                                onClick={() => {
                                  if (e.credential) UnassignKey(e.credential);
                                }}
                              >
                                Unassign
                              </button>
                              <button
                                className="btn btn-danger"
                                onClick={() => {
                                  if (e.credential) RevokeKey(e.credential);
                                }}
                              >
                                Revoke
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="well">
                      <p>
                        <strong>Unassign</strong> to allow this key to be
                        assigned to another guest
                      </p>
                      <p>
                        <strong>Revoke</strong> to remove this key from
                        circulation and remove access to all locks
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="alert alert-warning">No Keys Found</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <div className="panel panel-default">
            <div className="panel-heading">
              <h3>Available Keys</h3>
              <button
                className="btn btn-default"
                onClick={() => fetchAllKeys()}
              >
                Refresh
              </button>
            </div>
            {keysLoading ? (
              <div className="panel-body">
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped active"
                    role="progressbar"
                    aria-valuenow={100}
                    style={progressStyle}
                  >
                    Loading
                  </div>
                </div>
              </div>
            ) : (
              <div className="panel-body">
                {keys && keys.length > 0 ? (
                  <div>
                    <table className="table table-hover table-responsive">
                      <thead>
                        <tr>
                          <th>Badge ID</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {keys.map((e) => (
                          <tr key={e.badgeId}>
                            <td>{e.badgeId}</td>
                            <td>
                              <button
                                className="btn btn-default"
                                onClick={() => {
                                  if (e.credential) AssignKey(e.credential);
                                }}
                              >
                                Assign
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="well">
                      <p>
                        <strong>Assign</strong> an available key to this guest.
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="alert alert-warning">
                    No Available Keys Found
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
export default GuestDetails;
