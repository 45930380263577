import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import {
  FetchParams,
  reduceSetFetchParams,
  defaultPageSize,
  reduceNextPage,
  reducePrevPage,
  apiClientFactory,
} from "../helpers";
import {
  GuestInviteResponse,
  KeyRequestCredentialType,
} from "../api/ApiClient";

export interface GuestKeyRequestsState extends FetchParams {
  isLoading: boolean;
  guestKeyRequests: GuestInviteResponse[];
}

interface RequestKeyRequestsAction {
  type: "REQUEST_GUESTKEYREQUESTS";
}

interface RequestKeyRequestActionPayload {
  id: string;
}
interface RequestKeyRequestAction extends RequestKeyRequestActionPayload {
  type: "REQUEST_GUESTKEYREQUEST";
}

interface GetInviteByLockPayload {
  id: string;
  accessToken: string;
}
interface GetInviteByLockAction extends GetInviteByLockPayload {
  type: "REQUEST_KEYREQUEST_BY_LOCK";
}

interface SetFetchParams extends FetchParams {
  type: "SETFETCHPARAMS_GUESTKEYREQUESTS";
}

interface NextPage {
  type: "NEXTPAGE_GUESTKEYREQUESTS";
}

interface PrevPage {
  type: "PREVPAGE_GUESTKEYREQUESTS";
}

interface ReceiveKeyRequestsAction {
  type: "RECEIVE_GUESTKEYREQUESTS";
  keyRequests: GuestInviteResponse[];
}

interface ReceiveKeyRequestAction {
  type: "RECEIVE_GUESTKEYREQUEST";
  keyRequest: GuestInviteResponse;
}

interface GuestOpenDoorPayload {
  credentialType: KeyRequestCredentialType;
  keyRequestId: string;
  lockIds: string[];
  accessToken: string;
}
interface GuestOpenDoor extends GuestOpenDoorPayload {
  type: "GUEST_OPEN_DOOR";
}

type KnownAction =
  | RequestKeyRequestsAction
  | RequestKeyRequestAction
  | GetInviteByLockAction
  | SetFetchParams
  | NextPage
  | PrevPage
  | GuestOpenDoor
  | ReceiveKeyRequestsAction
  | ReceiveKeyRequestAction;

export const actionCreators = {
  setFetchParamsGuestKeyRequests:
    ({
      page,
      pageSize,
      searchTerm,
    }: FetchParams): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const state = getState();
      if (state.keyRequests.isLoading) return;

      dispatch({
        type: "SETFETCHPARAMS_GUESTKEYREQUESTS",
        page,
        pageSize,
        searchTerm,
      });

      await dispatch(actionCreators.requestGuestKeyRequests() as any);
    },
  nextGuestKeyRequestPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.keyRequests.isLoading) return;

      dispatch({
        type: "NEXTPAGE_GUESTKEYREQUESTS",
      });

      await dispatch(actionCreators.requestGuestKeyRequests() as any);
    },
  prevGuestKeyRequestPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.keyRequests.isLoading) return;

      dispatch({
        type: "PREVPAGE_GUESTKEYREQUESTS",
      });

      await dispatch(actionCreators.requestGuestKeyRequests() as any);
    },

  requestGuestKeyRequests:
    (): AppThunkAction<KnownAction> => (dispatch, getState) => {
      const {
        guestKeyRequests: { page, pageSize, guestKeyRequests: curKeyRequests },
      } = getState();

      const fetchTask = apiClientFactory()
        .getMyInvites(page, pageSize)
        .then(
          (data) => {
            dispatch({
              type: "RECEIVE_GUESTKEYREQUESTS",
              keyRequests: data,
            });
          },
          (_error) => {
            dispatch({
              type: "RECEIVE_GUESTKEYREQUESTS",
              keyRequests: curKeyRequests,
            });
          }
        );

      dispatch({
        type: "REQUEST_GUESTKEYREQUESTS",
      });
      return fetchTask;
    },

  fetchGuestKeyRequestByLock:
    (info: GetInviteByLockPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({
        type: "REQUEST_KEYREQUEST_BY_LOCK",
        id: info.id as string,
        accessToken: info.accessToken,
      });

      const json = await apiClientFactory().getInviteByLock({
        lockId: info.id,
        accessToken: info.accessToken,
      });
      return dispatch({
        type: "RECEIVE_GUESTKEYREQUEST",
        keyRequest: json,
      });
    },

  fetchGuestKeyRequest:
    (info: RequestKeyRequestActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({
        type: "REQUEST_GUESTKEYREQUEST",
        id: info.id as string,
      });
      const json = await apiClientFactory().getInvite(info.id);
      return dispatch({
        type: "RECEIVE_GUESTKEYREQUEST",
        keyRequest: json,
      });
    },

  guestOpenDoor:
    (info: GuestOpenDoorPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const json = await apiClientFactory().openDoorFromInvite({
        credentialType: info.credentialType,
        keyRequestId: info.keyRequestId,
        lockIds: info.lockIds,
        shouldNotCheckGuestId: false,
      });

      return json;
    },
};

const unloadedState: GuestKeyRequestsState = {
  guestKeyRequests: [],
  isLoading: false,
  page: 0,
  pageSize: defaultPageSize,
  searchTerm: "",
};

export const reducer: Reducer<GuestKeyRequestsState> = (
  state: GuestKeyRequestsState = unloadedState,
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "REQUEST_GUESTKEYREQUESTS":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVE_GUESTKEYREQUESTS":
      return {
        ...state,
        guestKeyRequests: action.keyRequests,
        isLoading: false,
      };

    case "SETFETCHPARAMS_GUESTKEYREQUESTS":
      return reduceSetFetchParams(state.isLoading, action, state);
    case "NEXTPAGE_GUESTKEYREQUESTS":
      return reduceNextPage(state.guestKeyRequests, state.isLoading, state);
    case "PREVPAGE_GUESTKEYREQUESTS":
      return reducePrevPage(state.isLoading, state);

    case "REQUEST_GUESTKEYREQUEST":
    case "REQUEST_KEYREQUEST_BY_LOCK":
    case "RECEIVE_GUESTKEYREQUEST":
    case "GUEST_OPEN_DOOR":
    default:
      return state;
  }
};
