import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { BASE_API_URL } from "store/Me";
import { BaseLivlyApiResponse } from "./useGetSystemTypes";
import {
  accountCredentialInterface,
  accountInterface,
} from "./useGetAccountById";

export default function usePatchAccount(id: string) {
  const patchAccount = async (data: accountCredentialInterface) => {
    const result = await axios.patch<BaseLivlyApiResponse<accountInterface>>(
      `${BASE_API_URL}/api/Accounts/${id}/credentials`,
      data
    );

    return result.data;
  };

  return useMutation((file: accountCredentialInterface) => patchAccount(file));
}
