import { useEffect, useState } from "react";
import * as React from "react";
import { useParams } from "react-router-dom";
import { apiClientFactory } from "../helpers";

import {
  AccessScheduleRequest,
  AccessScheduleResponse,
} from "../api/ApiClient";

interface AccessScheduleResponse2 extends AccessScheduleResponse {
  dirty: boolean;
  startTimeString: string;
  endTimeString: string;
}

function AccessSchedules() {
  const [loading, setLoading] = useState(false);
  const [accessSchedules, setAccessSchedules] = useState(
    [] as AccessScheduleResponse2[]
  );
  const [newAccessSchedule, setNewAccessSchedule] = useState(
    {} as AccessScheduleRequest
  );
  const { siteId } = useParams();

  const [newStartTimeString, setNewStartTimeString] = useState("" as string);
  const [newEndTimeString, setNewEndTimeString] = useState("" as string);

  const timeNumberToTimeString = function (time: number | undefined) {
    return `${Math.floor((time ? time : 0) / 60)
      .toString()
      .padStart(2, "0")}:${((time ? time : 0) % 60)
      .toString()
      .padStart(2, "0")}`;
  };

  const timeStringToTimeNumber = function (time: string | undefined) {
    const [hourString, minuteString] = time ? time.split(":") : ["0", "0"];
    const hour = Number.parseInt(hourString);
    const minute = Number.parseInt(minuteString);
    return hour * 60 + minute;
  };

  const fetchAccessSchedules = React.useCallback(
    async function () {
      setLoading(true);
      const response = await apiClientFactory().getAccessSchedules(siteId);

      const mappedSchedules = response.map((e) => {
        return {
          ...e,
          dirty: false,
          startTimeString: timeNumberToTimeString(e.time),
          endTimeString: timeNumberToTimeString(
            (e.time ? e.time : 0) + (e.length ? e.length : 0)
          ),
        };
      });
      setAccessSchedules(mappedSchedules);
      setLoading(false);
    },
    [siteId]
  );

  const addNewAccessSchedule = async function (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.preventDefault();
    setLoading(true);
    try {
      await apiClientFactory().addAccessSchedule(siteId, newAccessSchedule);
      setNewAccessSchedule({});
      setNewStartTimeString("");
      setNewEndTimeString("");
    } catch (exception) {
      console.error(exception);
    }
    setLoading(false);
    fetchAccessSchedules();
  };

  const deleteAccessSchedule = async function (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string | undefined
  ) {
    e.preventDefault();

    setLoading(true);
    if (id) {
      try {
        await apiClientFactory().deleteAccessSchedule(siteId, id);
      } catch (exception) {
        console.error(exception);
      }
    }
    setLoading(false);
    fetchAccessSchedules();
  };

  const updateAccessSchedules = async function (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string | undefined = undefined
  ) {
    e.preventDefault();
    setLoading(true);
    const localAccessSchedules = [...accessSchedules];
    for (let i = 0; i < localAccessSchedules.length; i++) {
      if (
        localAccessSchedules[i].dirty &&
        localAccessSchedules[i].id &&
        (id === undefined || localAccessSchedules[i].id === id)
      ) {
        try {
          await apiClientFactory().updateAccessSchedule(
            siteId,
            localAccessSchedules[i].id as string,
            localAccessSchedules[i]
          );
          localAccessSchedules[i].dirty = false;
        } catch (exception) {
          console.error(exception);
        }
      }
    }
    setAccessSchedules(localAccessSchedules);
    setLoading(false);
    fetchAccessSchedules();
  };

  const handleTimeChange = async function (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    const localAccessSchedules = [...accessSchedules];
    const startTimeNumber = timeStringToTimeNumber(e.target.value);

    let calculatedLength = 0;
    if (localAccessSchedules[index].length !== undefined) {
      calculatedLength = localAccessSchedules[index].length as number;
    }
    const endTimeNumber = startTimeNumber + calculatedLength;
    const endTimeString = timeNumberToTimeString(endTimeNumber);
    localAccessSchedules[index] = {
      ...localAccessSchedules[index],
      dirty: true,
      time: startTimeNumber,
      startTimeString: e.target.value,
      endTimeString: endTimeString,
      length: calculatedLength,
    };
    setAccessSchedules(localAccessSchedules);
  };

  const handleLengthChange = async function (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    const localAccessSchedules = [...accessSchedules];
    const endTimeNumber = timeStringToTimeNumber(e.target.value);
    let startTimeNumber = 0;
    if (localAccessSchedules[index].time !== undefined) {
      startTimeNumber = localAccessSchedules[index].time as number;
    }
    const calculatedLength = endTimeNumber - startTimeNumber;
    localAccessSchedules[index] = {
      ...localAccessSchedules[index],
      dirty: true,
      length: calculatedLength,
      endTimeString: e.target.value,
    };
    setAccessSchedules(localAccessSchedules);
  };

  const handleSundayChange = async function (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    const localAccessSchedules = [...accessSchedules];
    localAccessSchedules[index] = {
      ...localAccessSchedules[index],
      dirty: true,
      sunday: e.target.checked,
    };
    setAccessSchedules(localAccessSchedules);
  };

  const handleMondayChange = async function (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    const localAccessSchedules = [...accessSchedules];
    localAccessSchedules[index] = {
      ...localAccessSchedules[index],
      dirty: true,
      monday: e.target.checked,
    };
    setAccessSchedules(localAccessSchedules);
  };

  const handleTuesdayChange = async function (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    const localAccessSchedules = [...accessSchedules];
    localAccessSchedules[index] = {
      ...localAccessSchedules[index],
      dirty: true,
      tuesday: e.target.checked,
    };
    setAccessSchedules(localAccessSchedules);
  };

  const handleWednesdayChange = async function (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    const localAccessSchedules = [...accessSchedules];
    localAccessSchedules[index] = {
      ...localAccessSchedules[index],
      dirty: true,
      wednesday: e.target.checked,
    };
    setAccessSchedules(localAccessSchedules);
  };

  const handleThursdayChange = async function (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    const localAccessSchedules = [...accessSchedules];
    localAccessSchedules[index] = {
      ...localAccessSchedules[index],
      dirty: true,
      thursday: e.target.checked,
    };
    setAccessSchedules(localAccessSchedules);
  };

  const handleFridayChange = async function (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    const localAccessSchedules = [...accessSchedules];
    localAccessSchedules[index] = {
      ...localAccessSchedules[index],
      dirty: true,
      friday: e.target.checked,
    };
    setAccessSchedules(localAccessSchedules);
  };

  const handleSaturdayChange = async function (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    const localAccessSchedules = [...accessSchedules];
    localAccessSchedules[index] = {
      ...localAccessSchedules[index],
      dirty: true,
      saturday: e.target.checked,
    };
    setAccessSchedules(localAccessSchedules);
  };

  const progressStyle: React.CSSProperties = {
    width: "100%",
  };

  useEffect(() => {
    (async () => {
      fetchAccessSchedules();
    })();
  }, [fetchAccessSchedules]);

  return (
    <main className="container">
      <h1>Access Schedules</h1>
      <div className="row">
        <div className="col-xs-12">
          {loading ? (
            <div>
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped active"
                  role="progressbar"
                  aria-valuenow={100}
                  style={progressStyle}
                >
                  Loading
                </div>
              </div>
            </div>
          ) : (
            <div>
              <button
                className="btn btn-default"
                onClick={(event) => updateAccessSchedules(event)}
                disabled={accessSchedules.filter((e) => e.dirty).length === 0}
              >
                Update Schedules:{" "}
                {accessSchedules.filter((e) => e.dirty).length} pending
              </button>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>
                      <span className="visible-xs">Start</span>
                      <span className="hidden-xs">Start Time</span>
                    </th>
                    <th>
                      <span className="visible-xs">End</span>
                      <span className="hidden-xs">End Time</span>
                    </th>
                    <th>
                      <span className="visible-xs">S</span>
                      <span className="hidden-xs">Sunday</span>
                    </th>
                    <th>
                      <span className="visible-xs">M</span>
                      <span className="hidden-xs">Monday</span>
                    </th>
                    <th>
                      <span className="visible-xs">T</span>
                      <span className="hidden-xs">Tuesday</span>
                    </th>
                    <th>
                      <span className="visible-xs">W</span>
                      <span className="hidden-xs">Wednesday</span>
                    </th>
                    <th>
                      <span className="visible-xs">T</span>
                      <span className="hidden-xs">Thursday</span>
                    </th>
                    <th>
                      <span className="visible-xs">F</span>
                      <span className="hidden-xs">Friday</span>
                    </th>
                    <th>
                      <span className="visible-xs">S</span>
                      <span className="hidden-xs">Saturday</span>
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {accessSchedules.map((e, i) => (
                    <tr key={e.id}>
                      <td>
                        <input
                          type="time"
                          className="form-control"
                          value={e.startTimeString}
                          onChange={(event) => handleTimeChange(event, i)}
                        />
                      </td>
                      <td>
                        <input
                          type="time"
                          className="form-control"
                          value={e.endTimeString}
                          onChange={(event) => handleLengthChange(event, i)}
                        />
                      </td>
                      <td>
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              checked={e.sunday}
                              onChange={(event) => handleSundayChange(event, i)}
                            />
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              checked={e.monday}
                              onChange={(event) => handleMondayChange(event, i)}
                            />
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              checked={e.tuesday}
                              onChange={(event) =>
                                handleTuesdayChange(event, i)
                              }
                            />
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              checked={e.wednesday}
                              onChange={(event) =>
                                handleWednesdayChange(event, i)
                              }
                            />
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              checked={e.thursday}
                              onChange={(event) =>
                                handleThursdayChange(event, i)
                              }
                            />
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              checked={e.friday}
                              onChange={(event) => handleFridayChange(event, i)}
                            />
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              checked={e.saturday}
                              onChange={(event) =>
                                handleSaturdayChange(event, i)
                              }
                            />
                          </label>
                        </div>
                      </td>
                      <td>
                        <button
                          className="btn btn-secondary"
                          disabled={!e.dirty}
                          onClick={(event) =>
                            updateAccessSchedules(event, e.id)
                          }
                        >
                          Update
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={(event) => deleteAccessSchedule(event, e.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                  <tr className="success">
                    <td>
                      <input
                        type="time"
                        className="form-control"
                        value={newStartTimeString}
                        onChange={(e) => {
                          setNewStartTimeString(e.target.value);

                          const startTimeNumber = timeStringToTimeNumber(
                            e.target.value
                          );

                          let calculatedLength = 0;
                          if (newAccessSchedule.length !== undefined) {
                            calculatedLength =
                              newAccessSchedule.length as number;
                          }
                          const endTimeNumber =
                            startTimeNumber + calculatedLength;
                          const endTimeString =
                            timeNumberToTimeString(endTimeNumber);
                          setNewEndTimeString(endTimeString);
                          setNewAccessSchedule({
                            ...newAccessSchedule,
                            time: timeStringToTimeNumber(e.target.value),
                            length: calculatedLength,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="time"
                        className="form-control"
                        value={newEndTimeString}
                        onChange={(e) => {
                          const endTimeNumber = timeStringToTimeNumber(
                            e.target.value
                          );
                          let startTimeNumber = 0;
                          if (newAccessSchedule.time !== undefined) {
                            startTimeNumber = newAccessSchedule.time as number;
                          }
                          const calculatedLength =
                            endTimeNumber - startTimeNumber;
                          setNewEndTimeString(e.target.value);
                          setNewAccessSchedule({
                            ...newAccessSchedule,
                            length: calculatedLength,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <div className="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            checked={newAccessSchedule.sunday}
                            onChange={(e) =>
                              setNewAccessSchedule({
                                ...newAccessSchedule,
                                sunday: e.target.checked,
                              })
                            }
                          />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            checked={newAccessSchedule.monday}
                            onChange={(e) =>
                              setNewAccessSchedule({
                                ...newAccessSchedule,
                                monday: e.target.checked,
                              })
                            }
                          />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            checked={newAccessSchedule.tuesday}
                            onChange={(e) =>
                              setNewAccessSchedule({
                                ...newAccessSchedule,
                                tuesday: e.target.checked,
                              })
                            }
                          />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            checked={newAccessSchedule.wednesday}
                            onChange={(e) =>
                              setNewAccessSchedule({
                                ...newAccessSchedule,
                                wednesday: e.target.checked,
                              })
                            }
                          />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            checked={newAccessSchedule.thursday}
                            onChange={(e) =>
                              setNewAccessSchedule({
                                ...newAccessSchedule,
                                thursday: e.target.checked,
                              })
                            }
                          />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            checked={newAccessSchedule.friday}
                            onChange={(e) =>
                              setNewAccessSchedule({
                                ...newAccessSchedule,
                                friday: e.target.checked,
                              })
                            }
                          />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            checked={newAccessSchedule.saturday}
                            onChange={(e) =>
                              setNewAccessSchedule({
                                ...newAccessSchedule,
                                saturday: e.target.checked,
                              })
                            }
                          />
                        </label>
                      </div>
                    </td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={(e) => addNewAccessSchedule(e)}
                      >
                        add
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </main>
  );
}
export default AccessSchedules;
