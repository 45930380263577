import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { SiteStatusEnum } from "components/Home";
import { BASE_API_URL } from "store/Me";

export interface sitesStatus {
  id: string;
  name: string;
  siteId: string;
  siteName: string;
  deviceType: string;
  status: SiteStatusEnum;
  lastSeenTimestamp: Date | string;
  lastSyncTimestamp: Date | null;
  children: sitesStatus[];
  errors: errorType[];
}

export type errorType = {
  message: string;
  timestamp: string | Date;
};

export default function useGetSitesStatus() {
  const getSitesStatus = async () => {
    const result = await axios.get<sitesStatus[]>(
      `${BASE_API_URL}/api/Admin/status`
    );

    return result.data;
  };

  return useQuery(["sites-status"], getSitesStatus, {
    enabled: false,
  });
}
