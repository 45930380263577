import * as React from "react";
import * as Auth0State from "../../store/Auth0";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as MeState from "../../store/Me";
import { Spinner } from "./Spinner";

interface Injected {
  auth0: Auth0State.Auth0State;
  me: MeState.MeState;
}

type OwnProps = {};

type Props = Injected & OwnProps;

class LoginFormComponent extends React.Component<Props> {
  signInWithPhone = async (e: any) => {
    e.preventDefault();
    const { service: auth0Service } = this.props.auth0;
    auth0Service && (await auth0Service.signInWithPhone());
  };

  signInWithEmail = (e: any) => {
    (e as any).preventDefault && (e as any).preventDefault();
    this.props.auth0.service && this.props.auth0.service.signInWithEmail();
  };

  public render() {
    const {
      me: { appBaseUrl = "" },
      auth0: {
        service: auth0,
        session: { isLoading: auth0Loading, accessToken },
      },
    } = this.props;

    if (auth0Loading) {
      return <Spinner />;
    }

    if (!accessToken) {
      return (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              <button
                className="btn btn-sm btn-default"
                onClick={this.signInWithEmail}
              >
                Sign In With Email
              </button>
            </div>
            &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            <div>
              <button
                type={"button"}
                className="btn btn-sm btn-default"
                onClick={this.signInWithPhone}
              >
                Sign in With Phone
              </button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div style={{ paddingTop: 20, paddingLeft: 9 }}>
          <button
            className="btn btn-default"
            onClick={auth0 ? () => auth0.logout(appBaseUrl) : () => {}}
          >
            Logout
          </button>
        </div>
      </div>
    );
  }
}

export const LoginForm: any = connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    auth0: state.auth0,
    me: state.me,
  })
)(LoginFormComponent);
