import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as KeyRequestsState from "../../store/KeyRequests";
import { PageControl } from "../shared/PageControl";
import { confirmPrompt, formatLocalTime } from "../../helpers";
import { KeyRequestStatus } from "../../api/ApiClient";
import { Spinner } from "../shared/Spinner";

type OwnProps = RouteComponentProps<{ startDateIndex: string }>;

type Props = KeyRequestsState.KeyRequestsState &
  typeof KeyRequestsState.actionCreators &
  OwnProps;

const statusEnumToStr = (status?: KeyRequestStatus) => {
  switch (status) {
    case KeyRequestStatus.Initial:
      return "Initial";
    case KeyRequestStatus.Approved:
      return "Approved, credential not yet processed";
    case KeyRequestStatus.KeyIssueRequested:
      return "Processing";
    case KeyRequestStatus.KeyIssueSucceeded:
      return "Credential Issued/Door Opened";
    case KeyRequestStatus.KeyIssueFailed:
      return "Credential Issue Failed/Door Open Failed";
    default:
      return "Unknown status";
  }
};

const statusEnumToColor = (status?: KeyRequestStatus) => {
  switch (status) {
    case KeyRequestStatus.Approved:
      return "orange";
    case KeyRequestStatus.KeyIssueRequested:
      return "darkred";
    case KeyRequestStatus.KeyIssueSucceeded:
      return "green";
    case KeyRequestStatus.KeyIssueFailed:
      return "red";
    default:
      return "darkgrey";
  }
};

class KeyRequests extends React.Component<Props> {
  componentDidMount() {
    console.log("KeyRequests: Requesting key requests...");
    this.refresh();
  }

  refresh = async () => {
    try {
      await this.props.requestKeyRequests();
    } catch (e) {
      console.error("Error requesting key requests", e);
      alert("Error requesting key requests: " + JSON.stringify(e));
    }
  };

  deleteKeyRequest = (id: string) => {
    confirmPrompt({
      promptMessage: "Are you sure you want to delete this key request?",
      onConfirm: async () => {
        try {
          await this.props.deleteKeyRequest({
            id,
          });
          //alert("Successfully deleted keyRequest: " + JSON.stringify(json));
        } catch (e) {
          alert("Error deleting keyRequest: " + JSON.stringify(e));
        }
      },
    });
  };

  public render() {
    return (
      <div>
        <h3>All Key Requests</h3>
        <p>All key requests registered in the system.</p>
        <button disabled={this.props.isLoading} onClick={this.refresh}>
          {this.props.isLoading ? "Loading..." : "Refresh"}
        </button>
        {this.props.isLoading && <Spinner />}
        {this.renderKeyRequestsTable()}
      </div>
    );
  }

  private renderKeyRequestsTable() {
    const { isLoading } = this.props;
    const pageControl = (
      <PageControl
        onNext={this.props.nextKeyRequestPage}
        onPrev={this.props.prevKeyRequestPage}
        isLoading={isLoading}
        currentPage={this.props.page}
        disablePrev={this.props.page === 0}
        disableNext={this.props.keyRequests.length < this.props.pageSize}
      />
    );

    return (
      <div>
        {this.props.keyRequests.length > 10 && (
          <div>
            <p />
            {pageControl}
          </div>
        )}
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Id</th>
              <th>Guest</th>
              <th>Created</th>
              <th>Updated</th>
              <th>Status</th>
              <th>Error</th>
              <th>SystemType</th>
              <th>CredentialType</th>
              <th>CredentialId</th>
              <th>Note</th>
              <th>Valid From</th>
              <th>Valid To</th>
              <th>Details</th>
              <th>Site</th>
              <th>SystemSiteId</th>
              <th>Locks</th>
              <th>SystemLockIds</th>
              <th>User</th>
              <th>SystemUserId</th>
            </tr>
          </thead>
          <tbody>
            {this.props.keyRequests.map(
              (keyRequest, i) =>
                keyRequest.site &&
                keyRequest.site.account && (
                  <tr key={"f_" + i}>
                    <td>
                      <span>{keyRequest.id} </span>
                      <button
                        onClick={() =>
                          this.deleteKeyRequest(keyRequest.id || "0")
                        }
                      >
                        Delete
                      </button>
                    </td>

                    <td>
                      {keyRequest.guestInvite
                        ? keyRequest.guestInvite.guestId
                        : ""}
                    </td>
                    <td>
                      {keyRequest.createdTimestamp
                        ? new Date(
                            keyRequest.createdTimestamp
                          ).toLocaleDateString() +
                          " " +
                          new Date(
                            keyRequest.createdTimestamp
                          ).toLocaleTimeString()
                        : ""}
                    </td>
                    <td>
                      {keyRequest.updatedTimestamp
                        ? new Date(
                            keyRequest.updatedTimestamp
                          ).toLocaleDateString() +
                          " " +
                          new Date(
                            keyRequest.updatedTimestamp
                          ).toLocaleTimeString()
                        : ""}
                    </td>
                    <td
                      style={{
                        color: statusEnumToColor(keyRequest.status),
                      }}
                    >
                      {statusEnumToStr(keyRequest.status)}
                    </td>
                    <td>{keyRequest.error}</td>
                    <td>{keyRequest.site.account.systemType}</td>
                    <td>{keyRequest.credentialType}</td>
                    <td>{keyRequest.credentialId}</td>
                    <td>{keyRequest.note}</td>
                    <td>{formatLocalTime(keyRequest.validFrom as any)}</td>
                    <td>{formatLocalTime(keyRequest.validTo as any)}</td>
                    <td>{keyRequest.details}</td>
                    <td>{keyRequest.site.name}</td>
                    <td>{keyRequest.site.systemSiteId}</td>
                    <td>
                      {JSON.stringify(
                        (keyRequest.lockIds || []).map((s) =>
                          s.lock ? s.lock.name : ""
                        )
                      )}
                    </td>
                    <td>
                      {JSON.stringify(
                        (keyRequest.lockIds || []).map((s) => s.systemLockId)
                      )}
                    </td>
                    <td>
                      {keyRequest.connectUser
                        ? keyRequest.connectUser.name
                        : ""}
                    </td>
                    <td>
                      {keyRequest.siteUser
                        ? keyRequest.siteUser.systemUserId
                        : ""}
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
        {pageControl}
      </div>
    );
  }
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => state.keyRequests,
  KeyRequestsState.actionCreators
)(KeyRequests);
