import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { apiClientFactory } from "../helpers";
import { ConnectUserResponse } from "../api/ApiClient";
import { apiFetchParams } from "../helpers/api-fetch-params";

export interface MeState {
  isLoading: boolean;
  appBaseUrl?: string;
  apiBaseUrl?: string;
  auth0Domain?: string;
  auth0ClientId?: string;
  auth0Audience?: string;
  user: ConnectUserResponse | undefined;
}

interface RequestMe {
  type: "REQUEST_ME";
}

interface ReceiveMe {
  type: "RECEIVE_ME";
  apiBaseUrl?: string;
  appBaseUrl?: string;
  auth0Domain?: string;
  auth0ClientId?: string;
  auth0Audience?: string;
}

interface RequestUserMe {
  type: "REQUEST_USER_ME";
}

interface ReceiveUserMe {
  type: "RECEIVE_USER_ME";
  user: ConnectUserResponse;
}

export const BASE_API_URL = process.env.REACT_APP_API_BASE_URL as string;

export const actionCreators = {
  requestMe: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    if (process.env.REACT_APP_API_BASE_URL) {
      apiFetchParams.baseApiUrl = process.env.REACT_APP_API_BASE_URL;
    }
    dispatch({
      type: "RECEIVE_ME",
      appBaseUrl: process.env.REACT_APP_APP_BASE_URL,
      apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
      auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
      auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
      auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
  },

  requestUserMe:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({
        type: "REQUEST_USER_ME",
      });
      const user = await apiClientFactory().getMe();
      return dispatch({
        type: "RECEIVE_USER_ME",
        user,
      });
    },

  switchTenant:
    ({
      userId,
      tenantId,
    }: {
      userId: string;
      tenantId: string;
    }): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const user = await apiClientFactory().switchToTenant(userId, tenantId);
      return dispatch({
        type: "RECEIVE_USER_ME",
        user,
      });
    },
};

type KnownAction = RequestMe | ReceiveMe | RequestUserMe | ReceiveUserMe;

const unloadedState: MeState = {
  isLoading: true,
  appBaseUrl: "",
  user: undefined,
};

export const reducer: Reducer<MeState> = (
  state: MeState = unloadedState,
  incomingAction: Action
): MeState => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "REQUEST_ME":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVE_ME":
      return {
        ...state,
        isLoading: false,
        ...action,
      };
    case "RECEIVE_USER_ME":
      return {
        ...state,
        user: action.user,
      };
    case "REQUEST_USER_ME":
    default:
      return state;
  }
};
