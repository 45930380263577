import * as React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as ScreenInvitesState from "../../store/ScreenInvites";
import * as Auth0State from "../../store/Auth0";
import { parse } from "query-string";
import {
  formatLocalTime,
  inviteClassName,
  inviteStatus,
  screenInviteWithSortedScreens,
  isAdmin,
} from "../../helpers";
import {
  ScreenInviteResponse as ScreenInviteType,
  ScreenResponse,
} from "../../api/ApiClient";
import { Spinner } from "../shared/Spinner";
import Markdown from "react-markdown";
import MyAlerts from "../shared/Alert";

import Modal from "react-bootstrap-modal";

import "../shared/customModal.css";
import { RegisterOrEditScreen } from "../Admin/AdminScreens";
import SelectScreen from "../shared/SelectScreen";
import { MeState } from "../../store/Me";

interface Injected {
  auth0: Auth0State.Auth0State;
  me: MeState;
}

type RouteProps = RouteComponentProps<{}>;

type OwnProps = RouteProps & {
  header?: string;
};

const validTimeFormat = "MMM DD h:mm A, YYYY";

type Props = Injected & typeof ScreenInvitesState.actionCreators & OwnProps;

interface State {
  fetchingInvite: boolean;
  id: string;
  invite: ScreenInviteType | null;
}

class ScreenInvite extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const {
      location: { search },
    } = this.props;
    const { id } = parse(search || "");

    this.state = {
      fetchingInvite: false,
      id: (id || "") as string,
      invite: null,
    };
  }

  myAlerts: MyAlerts | null = null;
  setMyAlerts = (myAlerts: MyAlerts) => {
    this.myAlerts = myAlerts;
  };

  componentDidMount() {
    this.fetchInvite();
  }

  fetchInvite = () => {
    const { id } = this.state;
    if (!id) {
      alert("Please select an invite");
      return;
    }

    this.setState({ fetchingInvite: true }, async () => {
      try {
        const actionResult = await this.props.fetchScreenInvite({
          id,
        });
        this.setState({
          invite: screenInviteWithSortedScreens(
            (actionResult as any).screenInvite as ScreenInviteType
          ),
        });
      } catch (e) {
        alert("Error fetching invite: " + JSON.stringify(e));
      } finally {
        this.setState({
          fetchingInvite: false,
        });
      }
    });
  };

  onUpdatedScreen = () => {
    this.fetchInvite();
  };

  public render() {
    const { header } = this.props;

    const { invite, fetchingInvite } = this.state;

    if (fetchingInvite) {
      return <Spinner />;
    }

    const now = new Date();

    const { connectUser = null, validFrom = "", validTo = "" } = invite || {};

    const statusStr = invite !== null ? inviteStatus(now, invite) : "Scheduled";

    return (
      <div>
        <h3>{header}</h3>
        <hr />

        <MyAlerts ref={this.setMyAlerts} />

        {invite && (
          <div>
            <p />
            <div>
              <b>From</b>
            </div>
            <div>
              {connectUser
                ? connectUser.name || connectUser.externalId || "unknown"
                : "unknown"}
            </div>
            <p />

            {invite.details && (
              <div className="panel panel-info">
                <div className="panel-heading">Details from inviter:</div>
                <div className="panel-body">
                  <Markdown source={invite.details} />
                </div>
                <p />
              </div>
            )}

            {(validFrom || validTo) && (
              <div>
                <div className={`text-${inviteClassName(now, invite)}`}>
                  <b>{statusStr}</b>
                </div>
                <div>
                  {formatLocalTime(validFrom as any, validTimeFormat) ||
                    "any time"}{" "}
                  -{" "}
                  {formatLocalTime(validTo as any, validTimeFormat) ||
                    "any time"}
                </div>
                <p />
              </div>
            )}
          </div>
        )}

        {invite && (
          <ScreenInviteComp
            screenInvite={invite}
            onUpdatedScreen={this.onUpdatedScreen}
          />
        )}

        <div>
          {isAdmin(this.props.me.user) && (
            <React.Fragment>
              {" "}
              | <Link to="/allScreenInvites">View all screen invites</Link>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    auth0: state.auth0,
    me: state.me,
  }),
  {
    ...ScreenInvitesState.actionCreators,
  }
)(ScreenInvite);

//// ScreenInviteComp

type ScreenInviteProps = {
  screenInvite: ScreenInviteType;
  onUpdatedScreen: () => any;
};

interface ScreenInviteState {
  editingScreen: ScreenResponse | null;
}

class ScreenInviteComp extends React.Component<
  ScreenInviteProps,
  ScreenInviteState
> {
  state: ScreenInviteState = {
    editingScreen: null,
  };

  showEditScreenModal = (screen: ScreenResponse) => {
    this.setState({ editingScreen: screen });
  };

  closeModal = () => {
    this.setState({ editingScreen: null });
  };

  onUpdatedScreen = async () => {
    this.closeModal();
    await this.props.onUpdatedScreen();
  };

  public render() {
    const { editingScreen } = this.state;

    return (
      <div>
        <br />
        <div>{this.renderScreensTable()}</div>

        <Modal
          show={!!editingScreen}
          onHide={this.closeModal}
          aria-labelledby="ModalHeader"
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="ModalHeader">Edit Screen Tile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <RegisterOrEditScreen
              //@ts-ignore
              existingScreen={editingScreen}
              screenInviteId={this.props.screenInvite.id}
              accountId={""}
              onRegistered={() => this.onUpdatedScreen()}
            />
          </Modal.Body>
          <Modal.Footer>
            <Modal.Dismiss className="btn btn-default">Close</Modal.Dismiss>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private renderScreenButton = (screen: ScreenResponse) => {
    return (
      <div>
        <button
          type="button"
          className={`btn btn-secondary`}
          onClick={() => this.showEditScreenModal(screen)}
        >
          Edit
        </button>
      </div>
    );
  };

  private renderScreensTable() {
    const screens = (this.props.screenInvite.screenTiles || []).map(
      (s) => s.screen
    );

    return (
      <div>
        &nbsp;
        <p />
        <SelectScreen
          pageSize={5}
          //@ts-ignore
          screensProp={screens}
          renderButton={this.renderScreenButton}
          unselectedLabel="Delete"
          unselectedBtnClass="btn-danger"
        />
        <hr />
      </div>
    );
  }
}
