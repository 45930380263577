import * as React from "react";
import { NavMenu } from "./NavMenu";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import * as MeState from "../store/Me";
import { RouteComponentProps } from "react-router";

type RouteProps = RouteComponentProps<{}>;
type OwnProps = RouteProps;

type Props = { me: MeState.MeState } & OwnProps;

class LayoutComponent extends React.Component<Props, {}> {
  public render() {
    const { match, history, location } = this.props;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-3">
            {
              //@ts-ignore
              <NavMenu match={match} history={history} location={location} />
            }
          </div>

          <div className="col-sm-9">
            {this.props.children}
            <p />
            &nbsp;
            <p />
          </div>
        </div>
      </div>
    );
  }
}

export const Layout = connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    me: state.me,
    routing: (state as any).routing,
  })
)(LayoutComponent);
