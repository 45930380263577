import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as TenantsState from "../../store/Tenants";

import {
  ConnectUserResponse,
  TenantResponse,
  AccessControlGroupResponse,
  SiteSystemType,
} from "../../api/ApiClient";

import SelectTenant from "../shared/SelectTenant";

import Modal from "react-bootstrap-modal";
import SelectClientUser from "../shared/SelectClientUser";

import "./adminSites.css";
import { RegisterAccount } from "./AdminAccounts";
import SelectAccessControlGroup from "../shared/SelectAccessControlGroup";

type OwnProps = RouteComponentProps<{ startDateIndex: string }>;

interface Injected {
  systemTypes: SiteSystemType[];
  isLoading: boolean;
}
type Props = Injected & typeof TenantsState.actionCreators & OwnProps;

type ModalType =
  | "tenant"
  | "user"
  | "backendUser"
  | "accessControlGroup"
  | "account"
  | null;

interface State {
  inProgress: boolean;

  selectedTenant: TenantResponse | null;
  showingModal: ModalType;

  selectedUser: ConnectUserResponse | null;
  selectingUser: boolean;
  role: string;

  selectedAccessControlGroup: AccessControlGroupResponse | null;
  selectingAccessControlGroup: boolean;
}

class AdminTenants extends React.Component<Props, State> {
  state: State = {
    inProgress: false,

    selectedTenant: null,
    showingModal: null,
    selectedUser: null,
    selectingUser: true,
    role: "",

    selectedAccessControlGroup: null,
    selectingAccessControlGroup: true,
  };

  addUserForTenant = (tenant: TenantResponse) => {
    this.setState(
      {
        selectedTenant: tenant,
      },
      () => {
        this.showAddUserModal();
      }
    );
  };

  addBackendUserForTenant = (tenant: TenantResponse) => {
    this.setState(
      {
        selectedTenant: tenant,
      },
      () => {
        this.showAddBackendUserModal();
      }
    );
  };

  setAddAccessControlGroupToTenant = (tenant: TenantResponse) => {
    this.setState({
      showingModal: "accessControlGroup",
      selectedTenant: tenant,
    });
  };

  addAccountForTenant = (tenant: TenantResponse) => {
    this.setState(
      {
        selectedTenant: tenant,
      },
      () => {
        this.showAddAccountModal();
      }
    );
  };

  inviteUserToTenant = (e: any, { isBackend } = { isBackend: false }) => {
    e.preventDefault();

    const { selectedUser, role, selectedTenant } = this.state;
    this.setState(
      {
        inProgress: true,
      },
      async () => {
        try {
          const json = isBackend
            ? await this.props.addBackendUserToTenant({
                tenantExternalId:
                  selectedTenant && selectedTenant.externalId
                    ? selectedTenant.externalId
                    : "",
                backendNameClaimId:
                  selectedUser && selectedUser.externalId
                    ? selectedUser.externalId
                    : "",
              })
            : await this.props.addUserToTenant({
                tenantId: selectedTenant ? selectedTenant.id || "" : "",
                role,
                userId: selectedUser ? selectedUser.id || "" : "",
              });
          await this.props.requestTenants();
          this.setState({
            //clear inputs
            role: "",
          });
          alert("Successfully added user to tenant" + JSON.stringify(json));
        } catch (e) {
          alert("Error adding user to tenant " + JSON.stringify(e));
        } finally {
          this.setState({
            inProgress: false,
          });
        }
      }
    );
  };

  addAccessControlGroupToTenant = (e: any) => {
    e.preventDefault();

    const { selectedAccessControlGroup, selectedTenant } = this.state;
    this.setState(
      {
        inProgress: true,
      },
      async () => {
        try {
          const json = await this.props.addAccessControlGroupToTenant({
            tenantId: selectedTenant ? selectedTenant.id || "" : "",
            accessControlGroupId: selectedAccessControlGroup
              ? selectedAccessControlGroup.id || ""
              : "",
          });
          await this.props.requestTenants();
          this.setState({
            //clear inputs
            role: "",
          });
          alert("Successfully added group to tenant" + JSON.stringify(json));
        } catch (e) {
          alert("Error adding group to tenant " + JSON.stringify(e));
        } finally {
          this.setState({
            inProgress: false,
          });
        }
      }
    );
  };

  showAddTenantModal = () => {
    this.setState({ showingModal: "tenant" });
  };

  showAddUserModal = () => {
    this.setState({ showingModal: "user" });
  };

  showAddBackendUserModal = () => {
    this.setState({ showingModal: "backendUser" });
  };

  showAddAccountModal = () => {
    this.setState({ showingModal: "account" });
  };

  closeModal = () => {
    this.setState({ showingModal: null });
  };

  selectTenant = (tenant: TenantResponse) => {
    const { selectedTenant } = this.state;
    const wasSelected = selectedTenant && selectedTenant.id === tenant.id;
    this.setState({
      selectedTenant: wasSelected ? null : tenant,
    });
  };

  onRegisteredAccount = async () => {
    this.closeModal();
  };

  onRegisteredTenant = async () => {
    this.closeModal();
    this.props.requestTenants();
  };

  renderRegisterTenant = () => {
    //@ts-ignore
    return <RegisterTenant onRegistered={this.onRegisteredTenant} />;
  };

  renderRegisterAccount = () => {
    const { selectedTenant } = this.state;
    return (
      <RegisterAccount
        //@ts-ignore
        ownerClientId={selectedTenant ? selectedTenant.externalId : ""}
        onRegistered={this.onRegisteredAccount}
        systemTypes={this.props.systemTypes}
      />
    );
  };

  public render() {
    const { showingModal, selectedTenant } = this.state;

    const button = (
      <button
        type="button"
        className="btn btn-primary"
        onClick={this.showAddTenantModal}
      >
        Add Tenant
      </button>
    );

    return (
      <div>
        <br />
        {button}
        <div>{this.renderTenantsTable()}</div>

        <Modal
          show={!!showingModal}
          onHide={this.closeModal}
          aria-labelledby="ModalHeader"
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="ModalHeader">
              {showingModal === "tenant"
                ? "Add Tenant"
                : showingModal === "user"
                ? "Add User to Tenant"
                : showingModal === "backendUser"
                ? "Add API User to Tenant"
                : showingModal === "accessControlGroup"
                ? "Add Access Control Group to Tenant"
                : showingModal === "account"
                ? "Add Account to Tenant"
                : "?"}
              {selectedTenant && <div>{selectedTenant.name}</div>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showingModal === "tenant"
              ? this.renderRegisterTenant()
              : showingModal === "user"
              ? this.renderRegisterUserWrapper()
              : showingModal === "backendUser"
              ? this.renderRegisterUserWrapper({ isBackend: true })
              : showingModal === "accessControlGroup"
              ? this.renderAddAccessControlGroupWrapper()
              : this.renderRegisterAccount()}
          </Modal.Body>
          <Modal.Footer>
            <Modal.Dismiss className="btn btn-default">Close</Modal.Dismiss>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private renderTenantButton = (tenant: TenantResponse) => {
    return (
      <div>
        <div className="d-flex justify-between">
          <button
            type="button"
            className={`btn btn-sm btn-default`}
            onClick={() => this.addUserForTenant(tenant)}
          >
            Add User
          </button>
        </div>
        <p />
        <div className="d-flex justify-between">
          <button
            type="button"
            className={`btn btn-sm btn-default`}
            onClick={() => this.addBackendUserForTenant(tenant)}
          >
            Add Backend User
          </button>
        </div>
        <p />
        <div className="d-flex justify-between">
          <button
            type="button"
            className={`btn btn-sm btn-default`}
            onClick={() => this.setAddAccessControlGroupToTenant(tenant)}
          >
            Add Access Control Group
          </button>
        </div>
        <p />
        <div className="d-flex justify-between">
          <button
            type="button"
            className={`btn btn-sm btn-default`}
            onClick={() => this.addAccountForTenant(tenant)}
          >
            Add Account
          </button>
        </div>
      </div>
    );
  };

  private renderTenantsTable() {
    return (
      <div>
        <p />
        &nbsp;
        <p />
        {
          //@ts-ignore
          <SelectTenant pageSize={5} renderButton={this.renderTenantButton} />
        }
        <hr />
      </div>
    );
  }

  private renderSelectUserButton = (clientUser: ConnectUserResponse) => {
    return (
      <div>
        <button
          type="button"
          className={`btn btn-primary`}
          onClick={() =>
            this.setState({ selectingUser: false, selectedUser: clientUser })
          }
        >
          Select
        </button>
        <p />
      </div>
    );
  };

  private renderSelectAccessControlGroupButton = (
    accessControlGroup: AccessControlGroupResponse
  ) => {
    return (
      <div>
        <button
          type="button"
          className={`btn btn-primary`}
          onClick={() =>
            this.setState({
              selectingAccessControlGroup: false,
              selectedAccessControlGroup: accessControlGroup,
            })
          }
        >
          Select
        </button>
        <p />
      </div>
    );
  };

  renderRegisterUserWrapper = ({ isBackend } = { isBackend: false }) => {
    const { selectedUser, selectingUser } = this.state;
    return (
      <div>
        {(!selectedUser || selectingUser) && (
          <>
            <h3>Please Select a User</h3>
            {
              //@ts-ignore
              <SelectClientUser
                pageSize={5}
                renderButton={this.renderSelectUserButton}
              />
            }
          </>
        )}
        {!selectingUser && (
          <>
            <button
              type="button"
              className="btn btn-default"
              onClick={() =>
                this.setState({ selectingUser: true, selectedUser: null })
              }
            >
              Change User
            </button>
            {this.renderRegisterUser({ isBackend })}
          </>
        )}
      </div>
    );
  };

  renderAddAccessControlGroupWrapper = () => {
    const { selectedAccessControlGroup, selectingAccessControlGroup } =
      this.state;
    return (
      <div>
        {(!selectedAccessControlGroup || selectingAccessControlGroup) && (
          <>
            <h3>Please Select a Group</h3>
            {
              //@ts-ignore
              <SelectAccessControlGroup
                pageSize={5}
                renderButton={this.renderSelectAccessControlGroupButton}
              />
            }
          </>
        )}
        {!selectingAccessControlGroup && (
          <>
            <button
              type="button"
              className="btn btn-default"
              onClick={() =>
                this.setState({
                  selectingAccessControlGroup: true,
                  selectedAccessControlGroup: null,
                })
              }
            >
              Change Group
            </button>
            {this.renderAddAccessControlGroup()}
          </>
        )}
      </div>
    );
  };

  renderRegisterUser = ({ isBackend } = { isBackend: false }) => {
    const { selectedTenant } = this.state;
    if (!selectedTenant) return null;
    return this.renderAddUserToTenant({ isBackend });
  };

  renderAddAccessControlGroup = () => {
    const { selectedAccessControlGroup, selectedTenant, inProgress } =
      this.state;
    if (!selectedTenant) return null;
    return (
      <div>
        <form>
          <div className="form-group">
            <label htmlFor="acgId">Access Control Group</label>
            <input
              className="form-control"
              name="acgId"
              placeholder="acg id"
              value={
                selectedAccessControlGroup
                  ? selectedAccessControlGroup.name
                  : "please select a group"
              }
              disabled
              style={{ fontSize: 23 }}
              onChange={(e) => {}}
            />
          </div>

          <div className="form-group">
            <label htmlFor="tennatId">Tenant Id</label>
            <input
              className="form-control"
              name="tenant id"
              placeholder="tenantId"
              disabled
              value={selectedTenant ? selectedTenant.id : ""}
              onChange={(e) => {}}
            />
          </div>

          <div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={inProgress}
              onClick={this.addAccessControlGroupToTenant}
            >
              {"Add Access Control Group To Tenant"}
            </button>
          </div>
        </form>
      </div>
    );
  };

  renderAddUserToTenant = ({ isBackend } = { isBackend: false }) => {
    const { selectedUser, role, selectedTenant, inProgress } = this.state;

    return (
      <div>
        <form>
          <div className="form-group">
            <label htmlFor="clientUserId">User</label>
            <input
              className="form-control"
              name="clientUserId"
              placeholder="user id"
              value={selectedUser ? selectedUser.name : "please select a user"}
              disabled
              style={{ fontSize: 23 }}
              onChange={(e) => {}}
            />
          </div>

          {!isBackend && (
            <div className="form-group">
              <label htmlFor="role">Role</label>
              <input
                className="form-control"
                name="role"
                placeholder="role"
                value={role}
                onChange={(e) => this.setState({ role: e.target.value })}
              />
              <p className="help-block">user or admin</p>
            </div>
          )}

          <div className="form-group">
            <label htmlFor="tennatId">Tenant Id</label>
            <input
              className="form-control"
              name="tenant id"
              placeholder="tenantId"
              disabled
              value={selectedTenant ? selectedTenant.id : ""}
              onChange={(e) => {}}
            />
          </div>

          <div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={inProgress}
              onClick={(e) => this.inviteUserToTenant(e, { isBackend })}
            >
              {isBackend ? "Add API User" : "Add User"}
            </button>
          </div>
        </form>
      </div>
    );
  };
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    sites: state.sites,
  }),
  {
    ...TenantsState.actionCreators,
  }
)(AdminTenants);

type RegisterOwnProps = { ownerClientId: string; onRegistered: () => any };

interface RegisterInjected {}
type RegisterProps = RegisterInjected &
  typeof TenantsState.actionCreators &
  RegisterOwnProps;

interface RegisterState {
  inProgress: boolean;

  //create tenant
  ownerClientId: string; //aka ownerClientId/clientId
}

class RegisterComp extends React.Component<RegisterProps, RegisterState> {
  state: RegisterState = {
    inProgress: false,

    //create tenant
    ownerClientId: "",
  };

  registerTenant = (e: any) => {
    e.preventDefault();

    this.setState(
      {
        inProgress: true,
      },
      async () => {
        const state = this.state;
        try {
          const json = (await this.props.registerTenant({
            ownerClientId: state.ownerClientId,
          })) as any;
          alert("Successfully registered tenant: " + JSON.stringify(json));
          this.props.onRegistered();
          this.setState({
            //clear inputs
            ownerClientId: "",
          });
        } catch (e) {
          alert("Error registering tenant: " + JSON.stringify(e));
        } finally {
          this.setState({
            inProgress: false,
          });
        }
      }
    );
  };

  render() {
    const { inProgress, ownerClientId } = this.state;

    return (
      <form>
        <div className="form-group">
          <label htmlFor="ownerClientId">Tenant Unique Name *</label>
          <input
            className="form-control"
            name="ownerClientId"
            placeholder="Tenant Unique Name"
            value={ownerClientId}
            onChange={(e) => {
              this.setState({ ownerClientId: e.target.value });
            }}
          />
          <p className="help-block">
            Enter any unique id you want, e.g. "Flats", "Property X"
            <br />
            For internal Livly accounts, tenant can be <b>Livly</b>
          </p>
        </div>

        <div>
          <button
            type="submit"
            disabled={!!inProgress}
            className="btn btn-primary"
            onClick={this.registerTenant}
          >
            Register Tenant
          </button>
        </div>
      </form>
    );
  }
}

const RegisterTenant = connect<any, any, RegisterOwnProps, ApplicationState>(
  (state) => ({}),
  {
    ...TenantsState.actionCreators,
  }
)(RegisterComp);
