import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import {
  FetchParams,
  reduceSetFetchParams,
  defaultPageSize,
  reduceNextPage,
  reducePrevPage,
  apiClientFactory,
  getExternalTenantId,
} from "../helpers";

import {
  SharedAccountCredentialsResponse,
  SiteSystemType,
} from "../api/ApiClient";

export interface SharedAccountCredentialsState extends FetchParams {
  isLoading: boolean;
  sharedCredentials: SharedAccountCredentialsResponse[];
}

interface RequestAction {
  type: "REQUEST_SHARED_CREDENTIALS";
}

interface SetFetchParams extends FetchParams {
  type: "SETFETCHPARAMS_SHARED_CREDENTIALS";
}

interface NextPage {
  type: "NEXTPAGE_SHARED_CREDENTIALS";
}

interface PrevPage {
  type: "PREVPAGE_SHARED_CREDENTIALS";
}

interface ReceiveAction extends FetchParams {
  type: "RECEIVE_SHARED_CREDENTIALS";
  sharedCredentials: SharedAccountCredentialsResponse[];
}

interface RegisterActionPayload {
  systemType: SiteSystemType;
  name: string;
  description: string;

  //credentials
  clientId: string;
  clientSecret: string;
  username: string;
  password: string;
  accessToken: string;
  refreshToken: string;
  apiUrl: string;
  tokenUrl: string;
}
interface RegisterAction extends RegisterActionPayload {
  type: "REGISTER_SHARED_CREDENTIALS";
}

interface DeleteActionPayload {
  id: string;
}
interface DeleteAction extends DeleteActionPayload {
  type: "DELETE_SHARED_CREDENTIALS";
}

type KnownAction =
  | RequestAction
  | SetFetchParams
  | NextPage
  | PrevPage
  | ReceiveAction
  | RegisterAction
  | DeleteAction;

export const actionCreators = {
  setFetchParamSharedCredentials:
    ({
      page,
      pageSize,
      searchTerm,
    }: FetchParams): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const state = getState();
      if (state.accounts.isLoading) return;

      dispatch({
        type: "SETFETCHPARAMS_SHARED_CREDENTIALS",
        page,
        pageSize,
        searchTerm,
      });

      await dispatch(actionCreators.requestSharedCredentials() as any);
    },
  nextSharedCredentialsPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.accounts.isLoading) return;

      dispatch({
        type: "NEXTPAGE_SHARED_CREDENTIALS",
      });

      await dispatch(actionCreators.requestSharedCredentials() as any);
    },
  prevSharedCredentialsPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.accounts.isLoading) return;

      dispatch({
        type: "PREVPAGE_SHARED_CREDENTIALS",
      });

      await dispatch(actionCreators.requestSharedCredentials() as any);
    },

  requestSharedCredentials:
    (): AppThunkAction<KnownAction> => (dispatch, getState) => {
      const {
        sharedAccountCredentials: {
          page,
          pageSize,
          searchTerm,
          sharedCredentials: cur,
        },
      } = getState();

      const fetchTask = apiClientFactory()
        .getSharedAccountCredentials(
          page,
          pageSize,
          searchTerm !== "" ? searchTerm : undefined,
          undefined
        )
        .then(
          (data) => {
            dispatch({
              type: "RECEIVE_SHARED_CREDENTIALS",
              sharedCredentials: data,
              page,
              pageSize,
              searchTerm,
            });
          },
          (_error) => {
            dispatch({
              type: "RECEIVE_SHARED_CREDENTIALS",
              sharedCredentials: cur,
              page,
              pageSize,
              searchTerm,
            });
          }
        );

      dispatch({
        type: "REQUEST_SHARED_CREDENTIALS",
      });
      return fetchTask;
    },

  registerSharedCredentials:
    (info: RegisterActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const {
        me: { user },
      } = getState();
      const json = await apiClientFactory().postSharedAccountCredentials({
        tenantExternalId: getExternalTenantId(user),
        systemType: info.systemType,
        name: info.name,
        description: info.description,
        clientId: info.clientId,
        clientSecret: info.clientSecret,
        username: info.username,
        password: info.password,
        accessToken: info.accessToken,
        refreshToken: info.refreshToken,
        apiUrl: info.apiUrl,
        tokenUrl: info.tokenUrl,
      });

      return json;
    },

  deleteSharedCredentials:
    (info: DeleteActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      await apiClientFactory().deleteSharedAccountCredentials(info.id);

      await dispatch(actionCreators.requestSharedCredentials() as any);
    },
};

const unloadedState: SharedAccountCredentialsState = {
  sharedCredentials: [],
  isLoading: false,
  page: 0,
  pageSize: defaultPageSize,
  searchTerm: "",
};

export const reducer: Reducer<SharedAccountCredentialsState> = (
  state: SharedAccountCredentialsState = unloadedState,
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "REQUEST_SHARED_CREDENTIALS":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVE_SHARED_CREDENTIALS":
      return {
        ...state,
        sharedCredentials: action.sharedCredentials,
        isLoading: false,
        page: action.page,
        pageSize: action.pageSize,
        searchTerm: action.searchTerm,
      };

    case "SETFETCHPARAMS_SHARED_CREDENTIALS":
      return reduceSetFetchParams(state.isLoading, action, state);
    case "NEXTPAGE_SHARED_CREDENTIALS":
      return reduceNextPage(state.sharedCredentials, state.isLoading, state);
    case "PREVPAGE_SHARED_CREDENTIALS":
      return reducePrevPage(state.isLoading, state);

    case "REGISTER_SHARED_CREDENTIALS":
    case "DELETE_SHARED_CREDENTIALS":
    default:
      return state;
  }
};
