import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as LocksState from "../../store/Locks";
import * as SitesState from "../../store/Sites";
import * as ClientUsersState from "../../store/ClientUsers";
import * as SiteUsersState from "../../store/SiteUsers";
import { confirmPrompt } from "../../helpers";
import { ConnectUserResponse } from "../../api/ApiClient";
import SelectClientUser from "../shared/SelectClientUser";

import Modal from "react-bootstrap-modal";

type OwnProps = RouteComponentProps<{ startDateIndex: string }>;

interface Injected {}
type Props = Injected &
  typeof ClientUsersState.actionCreators &
  typeof SitesState.actionCreators &
  OwnProps;

type ModalType = "clientUser" | "backendUser" | null;
type Role = "user" | "admin";

interface State {
  inProgress: Object | null;

  selectedClientUser: ConnectUserResponse | null;
  showingModal: ModalType;
  //create clientUser
  name: string;
  email: string;
  role: Role;
  backendNameClaimId: string;
}

class AdminClientUsers extends React.Component<Props, State> {
  state: State = {
    inProgress: null,

    selectedClientUser: null,
    showingModal: null,
    name: "",
    email: "",
    role: "user",
    backendNameClaimId: "",
  };

  registerClientUser = (e: any, { isBackend } = { isBackend: false }) => {
    e.preventDefault();

    this.setState(
      {
        inProgress: {
          ...(this.state.inProgress || {}),
          registerClientUser: true,
        },
      },
      async () => {
        const state = this.state;
        try {
          const json = isBackend
            ? await this.props.registerBackendUser({
                backendNameClaimId: state.backendNameClaimId,
                name: state.name,
                email: state.email,
              })
            : ((await this.props.registerClientUser({
                name: state.name,
                email: state.email,
                role: state.role,
              })) as any);
          await this.props.requestClientUsers();
          alert("Successfully registered user: " + JSON.stringify(json));
          this.closeModal();
          this.setState({
            //clear inputs
            name: "",
            email: "",
            role: "user",
            backendNameClaimId: "",
          });
        } catch (e) {
          alert("Error registering user: " + JSON.stringify(e));
        } finally {
          this.setState({
            inProgress: {
              ...(this.state.inProgress || {}),
              registerClientUser: false,
            },
          });
        }
      }
    );
  };

  deleteClientUser = (clientUser: ConnectUserResponse) => {
    confirmPrompt({
      promptMessage: "Are you sure you want to delete this clientUser?",
      onConfirm: async () => {
        try {
          await this.props.deleteClientUser({
            id: clientUser.id || "",
          });
          await this.props.requestSites();
        } catch (e) {
          alert("Error deleting clientUser: " + JSON.stringify(e));
        }
      },
    });
  };

  showAddClientUserModal = () => {
    this.setState({ showingModal: "clientUser" });
  };

  closeModal = () => {
    this.setState({ showingModal: null });
  };

  selectClientUser = (clientUser: ConnectUserResponse) => {
    const { selectedClientUser } = this.state;
    const wasSelected =
      selectedClientUser && selectedClientUser.id === clientUser.id;
    this.setState({
      selectedClientUser: wasSelected ? null : clientUser,
    });
  };

  public render() {
    const { showingModal } = this.state;

    return (
      <div>
        <br />
        <div>{this.renderClientUsersTable()}</div>

        <Modal
          show={!!showingModal}
          onHide={this.closeModal}
          aria-labelledby="ModalHeader"
        >
          <Modal.Header closeButton>
            <Modal.Title id="ModalHeader">
              {showingModal === "clientUser"
                ? "Add User"
                : showingModal === "backendUser"
                ? "Add API User"
                : "error"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showingModal === "clientUser"
              ? this.renderRegisterClientUser()
              : showingModal === "backendUser"
              ? this.renderRegisterClientUser({ isBackend: true })
              : `error - unknown modal ${showingModal}`}
          </Modal.Body>
          <Modal.Footer>
            <Modal.Dismiss className="btn btn-default">Close</Modal.Dismiss>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private renderClientUserButton = (clientUser: ConnectUserResponse) => {
    return (
      <div>
        <button
          type="button"
          className={`btn btn-danger`}
          onClick={() => this.deleteClientUser(clientUser)}
        >
          Delete
        </button>
        <p />
      </div>
    );
  };

  private renderClientUsersTable() {
    const button = (
      <button
        type="button"
        className="btn btn-primary"
        onClick={this.showAddClientUserModal}
      >
        Set Up User
      </button>
    );

    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {button}
          &nbsp;
          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => this.setState({ showingModal: "backendUser" })}
          >
            Set Up Backend User
          </button>
        </div>
        {
          //@ts-ignore
          <SelectClientUser
            pageSize={5}
            renderButton={this.renderClientUserButton}
            unselectedLabel="Delete"
            unselectedBtnClass="btn-danger"
          />
        }
        <hr />
      </div>
    );
  }

  renderRegisterClientUser = ({ isBackend } = { isBackend: false }) => {
    const { inProgress, email, name, role, backendNameClaimId } = this.state;

    const { registerClientUser: registerClientUserInProgress = false } =
      (inProgress || {}) as any;

    return (
      <form>
        <div className="form-group">
          <label htmlFor="name">Name *</label>
          <input
            className="form-control"
            name="name"
            placeholder="User Name"
            value={name}
            onChange={(e) => this.setState({ name: e.target.value })}
          />
        </div>
        {!isBackend && (
          <React.Fragment>
            <div className="form-group">
              <label htmlFor="email">Email *</label>
              <input
                className="form-control"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="role">Role (optional)</label>
              <select
                className="form-control"
                name="role"
                placeholder="Role"
                value={role}
                onChange={(e) =>
                  this.setState({ role: e.target.value as Role })
                }
              >
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </select>
              <p className="help-block">
                "user" can open locks, "admin" can create accounts, sites, locks
                and invite guests
              </p>
            </div>
          </React.Fragment>
        )}

        {isBackend && (
          <div className="form-group">
            <label htmlFor="backendNameClaimId">Backend Sub Claim</label>
            <input
              className="form-control"
              name="backendNameClaimId"
              placeholder="Backend Sub Claim"
              value={backendNameClaimId}
              onChange={(e) =>
                this.setState({ backendNameClaimId: e.target.value })
              }
            />
            <p className="help-block">
              The Auth0 "sub" claim for the API user, e.g
              MCv8D9MdWa71ToUoxO6A2MJkWvFqE5j0@clients
            </p>
          </div>
        )}

        <div>
          <button
            type="submit"
            disabled={!!registerClientUserInProgress}
            className="btn btn-primary"
            onClick={(e) => this.registerClientUser(e, { isBackend })}
          >
            {isBackend ? "Register API User" : "Register User"}
          </button>
        </div>
      </form>
    );
  };
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    clientUsers: state.clientUsers,
  }),
  {
    ...ClientUsersState.actionCreators,
    ...LocksState.actionCreators,
    ...SitesState.actionCreators,
    ...SiteUsersState.actionCreators,
  }
)(AdminClientUsers);
