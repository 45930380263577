import React from "react";
import { useState } from "react";
import usePostCsvLock from "./hooks/usePostCsvLock";
import { useSnackbar } from "notistack";
type Props = {
  isDisabled: boolean;
  siteId: string;
  onHide: () => void;
};
export const CSVLockUploaderModal = ({ isDisabled, siteId, onHide }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  let findError: any = "";
  let errorMessage: any = "";
  const [selectedFile, setSelectedFile] = useState();
  const { mutate, isLoading, isError, error } = usePostCsvLock(siteId);
  const getImage = (e: any) => {
    e.preventDefault();
    setSelectedFile(e.target.files[0]);
  };

  const registerLock = (e: any) => {
    e.preventDefault();

    if (selectedFile) {
      //@ts-ignore
      mutate(selectedFile, {
        onSuccess() {
          enqueueSnackbar(
            <p style={{ fontSize: "12px", padding: 0, margin: 0 }}>
              File uploaded successfully
            </p>,
            {
              variant: "success",
            }
          );
          onHide();
        },
      });
    }
  };

  if (isError) {
    findError = error;
    errorMessage = findError.response.data.message || "Something went wrong.";
  }

  return (
    <form style={{ margin: "20px" }}>
      {isError && errorMessage && (
        <p style={{ padding: "5px", backgroundColor: "#F67280" }}>
          {errorMessage}
        </p>
      )}
      <div className="form-group">
        <label htmlFor="lockName">Upload CSV *</label>
        <input
          className="form-control"
          name="file"
          type="file"
          accept=".csv"
          onChange={getImage}
        />
      </div>
      <div>
        <p>
          <b>Note:</b>
        </p>
        <p>
          CSV need to contain the following fields:{" "}
          <b>SystemLockId, Lock Name, Unit Number, Door Type</b>
        </p>
      </div>

      <div>
        <button
          type="submit"
          disabled={isDisabled || selectedFile === undefined || isLoading}
          className="btn btn-primary"
          onClick={registerLock}
        >
          {isLoading ? "Please wait..." : "Upload"}
        </button>
      </div>
    </form>
  );
};
