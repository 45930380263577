import { useEffect, useState } from "react";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { apiClientFactory } from "../helpers";

import { LockEventModel } from "../api/ApiClient";
import { stringify } from "query-string";

function LockEvents() {
  // Declare a new state variable, which we'll call "count"
  const [lockEventModels, setLockEventModels] = useState(
    [] as LockEventModel[]
  );
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const take = (function () {
    let t1 = query.get("take");
    t1 = t1 ? t1 : "";
    const t = Number.parseInt(t1);
    return t ? t : 10;
  })();

  const skip = (function () {
    let s1 = query.get("skip");
    s1 = s1 ? s1 : "";
    const s = Number.parseInt(s1);
    return s ? s : 0;
  })();

  const { lockId } = useParams();

  const fetchlockEventModels = async function (skip: number, take: number) {
    setLoading(true);
    const response = await apiClientFactory().getLockEvents(lockId, skip, take);
    setLockEventModels(response);
    setLoading(false);
  };

  const setTake = function (e: React.ChangeEvent<HTMLSelectElement>) {
    const query = {} as any;
    if (e.target.value !== "10") {
      query.take = e.target.value;
    }
    if (skip > 0) {
      query.skip = skip;
    }
    const searchString = stringify(query);
    history.push({
      pathname: location.pathname,
      search: searchString,
    });
  };

  const setSkipAndFetch = function (offset: number) {
    const query = {} as any;
    if (take !== 10) {
      query.take = take;
    }
    if (offset > 0) {
      query.skip = offset;
    }
    const searchString = stringify(query);
    history.push({
      pathname: location.pathname,
      search: searchString,
    });
  };

  const progressStyle: React.CSSProperties = {
    width: "100%",
  };

  useEffect(() => {
    (async () => fetchlockEventModels(skip, take))();
    // eslint-disable-next-line
  }, [location]);
  return loading ? (
    <main className="jumbotron">
      <div className="progress">
        <div
          className="progress-bar progress-bar-striped active"
          role="progressbar"
          aria-valuenow={100}
          style={progressStyle}
        >
          Loading
        </div>
      </div>
    </main>
  ) : (
    <main>
      <div className="panel">
        <form className="form-inline" onSubmit={(e) => e.preventDefault()}>
          <div className="form-group">
            <label htmlFor="takeSelect">Records Per page</label>
            <select
              id="takeSelect"
              className="form-control"
              value={take}
              onChange={(e) => setTake(e)}
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div className="form-group">
            {skip > 0 ? (
              <button
                className="btn btn-default"
                type="submit"
                onClick={() => setSkipAndFetch(skip - take)}
              >
                Previous
              </button>
            ) : (
              <React.Fragment />
            )}
          </div>
          <div className="form-group">
            {lockEventModels && lockEventModels.length === take ? (
              <button
                className="btn btn-default"
                type="submit"
                onClick={() => setSkipAndFetch(skip + take)}
              >
                Next
              </button>
            ) : (
              <React.Fragment />
            )}
          </div>
        </form>
      </div>
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Time</th>
              <th>Code</th>
              <th>Description</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody>
            {lockEventModels.map((e, i) => (
              <tr key={i}>
                <td>{e.time}</td>
                <td>{e.event}</td>
                <td>{e.description}</td>
                <td>{e.data}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {lockEventModels.length === 0 ? (
        <div className="alert alert-warning">No Lock Events Found</div>
      ) : (
        <React.Fragment />
      )}
    </main>
  );
}
export default LockEvents;
