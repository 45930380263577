import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as TenantsState from "../../store/Tenants";
import { PageControl } from "./PageControl";
import { debounce } from "lodash";
import { defaultPageSize } from "../../helpers";
import { TenantResponse } from "../../api/ApiClient";
import { Spinner } from "./Spinner";

interface Injected {
  tenants: TenantsState.TenantsState;
}

type OwnProps = {
  onSelected?: (tenant: TenantResponse) => any;
  pageSize?: number;
  showFirst?: number | null | undefined;
  selectedTenants?: Array<TenantResponse>;
  renderButton?: (tenant: TenantResponse) => any;
  selectedLabel?: string;
  selectedBtnClass?: string;
  unselectedLabel?: string;
  unselectedBtnClass?: string;
  btnStyle?: Object;
};

type Props = Injected & typeof TenantsState.actionCreators & OwnProps;

interface State {
  typedTenant: string;
}

class SelectTenant extends React.Component<Props, State> {
  state: State = {
    typedTenant: "",
  };

  componentDidMount() {
    console.log("SelectTenant: Requesting tenants...");
    this.refresh();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.pageSize !== prevProps.pageSize) {
      this.refresh();
    }
  }

  refresh = async () => {
    try {
      const { pageSize } = this.props;
      const { typedTenant: searchTerm } = this.state;
      await this.props.setFetchParamsTenants({
        page: 0,
        pageSize: pageSize || defaultPageSize,
        searchTerm,
      });
    } catch (e) {
      console.error("Error requesting tenants", e);
      alert("Error requesting tenants: " + JSON.stringify(e));
    }
  };

  handleTypedTenant = (value: string) => {
    this.setState({ typedTenant: value }, this.searchTenant);
  };

  searchTenant = debounce(this.refresh, 1000);

  public render() {
    const { tenants, searchTerm, isLoading } = this.props.tenants;

    const { typedTenant } = this.state;

    return (
      <div>
        <div className="form-group">
          <label htmlFor="typedTenant">
            <span aria-label="search" role="img">
              🔎
            </span>{" "}
            Search For Tenant
          </label>
          <input
            className="form-control"
            name="typedTenant"
            placeholder="type here"
            value={typedTenant}
            onChange={(e) => this.handleTypedTenant(e.target.value)}
          />
        </div>
        <h4>
          {searchTerm ? `Tenants Matching '${searchTerm}'` : "Tenants"}
          {isLoading && <Spinner />}
        </h4>
        {this.renderTenantsTable(tenants)}
      </div>
    );
  }

  private defaultRenderButton = (tenant: TenantResponse) => {
    const {
      selectedTenants,
      onSelected,
      btnStyle,
      selectedBtnClass,
      selectedLabel,
      unselectedBtnClass,
      unselectedLabel,
    } = this.props;
    const isSelected =
      selectedTenants && selectedTenants.find((s) => s.id === tenant.id);
    const className = isSelected
      ? selectedBtnClass || "btn-primary"
      : unselectedBtnClass || "btn-default";
    return (
      <button
        type="button"
        className={`btn ${className}`}
        style={btnStyle}
        onClick={() => onSelected && onSelected(tenant)}
      >
        {!isSelected
          ? unselectedLabel || "Select"
          : selectedLabel || "Unselect"}
      </button>
    );
  };

  private renderTenantsTable(tenants: TenantResponse[]) {
    const { renderButton, showFirst } = this.props;
    const { isLoading } = this.props.tenants;
    tenants = !showFirst ? tenants : (tenants || []).slice(0, showFirst);

    return (
      <div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th />
              <th>Id</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {tenants.map((tenant, i) => (
              <tr key={"f_" + i}>
                <td style={{ width: 100 }}>
                  {renderButton
                    ? renderButton(tenant)
                    : this.defaultRenderButton(tenant)}
                </td>
                <td>{tenant.id}</td>
                <td>{tenant.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {!showFirst && (
          <PageControl
            onNext={this.props.nextTenantsPage}
            onPrev={this.props.prevTenantsPage}
            currentPage={this.props.tenants.page}
            isLoading={isLoading}
            disablePrev={this.props.tenants.page === 0}
            disableNext={
              this.props.tenants.tenants.length < this.props.tenants.pageSize
            }
          />
        )}
      </div>
    );
  }
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    tenants: state.tenants,
  }),
  { ...TenantsState.actionCreators }
)(SelectTenant);
