import { Box, Typography } from "@material-ui/core";
import React from "react";
import { SiteStatusEnum } from "./Home";
import { Glyphicon } from "react-bootstrap";
import moment from "moment";
import { sitesStatus } from "./hooks/useGetSitesStatus";

type Props = {
  siteStatusData: sitesStatus;
  classes: any;
};

export const SiteStatus = ({ siteStatusData, classes }: Props) => {
  const getStatusIcon = (siteStatus: SiteStatusEnum) => {
    switch (siteStatus) {
      case SiteStatusEnum.Offline:
        return (
          <Glyphicon
            glyph={"minus-sign"}
            style={{ marginRight: "10px", color: "#CC2553" }}
          />
        );
      case SiteStatusEnum.Online:
        return (
          <Glyphicon
            glyph="ok-sign"
            style={{ marginRight: "10px", color: "#2AAC76" }}
          />
        );
      case SiteStatusEnum.Unknown:
        return (
          <Glyphicon
            glyph="warning-sign"
            style={{ marginRight: "10px", color: "#E2AC37" }}
          />
        );
      default:
        return (
          <Glyphicon
            glyph="warning-sign"
            style={{ marginRight: "10px", color: "#E2AC37" }}
          />
        );
    }
  };

  const getLastSeen = (date: Date | string) => {
    return moment.utc(date).to(moment(), true);
  };

  return (
    <Box className={classes.statusChildren}>
      <div>
        {siteStatusData.lastSeenTimestamp && (
          <Typography variant="subtitle1">
            Last Seen: {getLastSeen(siteStatusData.lastSeenTimestamp)}
          </Typography>
        )}
        <p style={{ fontSize: "16px" }}>
          <b>Name: {siteStatusData.name}</b>
        </p>
        <p>
          <b>Site Name: {siteStatusData.siteName ?? "--"}</b>
        </p>

        {siteStatusData.errors &&
          siteStatusData.errors.map((error) => (
            <Typography variant="subtitle1" color="error">
              <b>Error Message:</b> {error.message}
            </Typography>
          ))}
      </div>
      <div style={{ fontSize: "1.5rem" }}>
        {getStatusIcon(siteStatusData.status as SiteStatusEnum)}
      </div>
    </Box>
  );
};
