import * as React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as GuestKeyRequestsState from "../../store/GuestKeyRequests";
import * as Auth0State from "../../store/Auth0";
import { parse } from "query-string";
import {
  confirmPrompt,
  formatLocalTime,
  inviteClassName,
  inviteStatus,
  guestInviteWithSortedLocks,
  isAdmin,
} from "../../helpers";
import {
  GuestInviteResponse as GuestInviteType,
  LockResponse,
  SiteSystemType,
  GuestInviteLockIdResponse,
  KeyRequestCredentialType,
  GuestKeyRequestResponse,
} from "../../api/ApiClient";
import { Spinner } from "../shared/Spinner";
import Markdown from "react-markdown";
import MyAlerts from "../shared/Alert";
import { MeState } from "../../store/Me";

interface Injected {
  auth0: Auth0State.Auth0State;
  me: MeState;
}

type RouteProps = RouteComponentProps<{}>;

type OwnProps = RouteProps & {
  header?: string;
};

const validTimeFormat = "MMM DD h:mm A, YYYY";

type Props = Injected & typeof GuestKeyRequestsState.actionCreators & OwnProps;

interface State {
  fetchingKeyRequest: boolean;
  locks: LockResponse[]; //selected to open
  inProgress: Object;
  keyRequest: GuestInviteType | null;
}

function buttonLabel(
  credentialType: KeyRequestCredentialType,
  inProgress: boolean
) {
  switch (credentialType) {
    case KeyRequestCredentialType.RemoteOpen:
      return inProgress ? "Opening..." : "Open";
    case KeyRequestCredentialType.MobileKey:
      return inProgress ? "Sending..." : "Send Key";
    case KeyRequestCredentialType.GetPin:
      return inProgress ? "Getting Info..." : "Get Info";
  }
}

class GuestInvite extends React.Component<Props, State> {
  state = {
    fetchingKeyRequest: false,
    locks: [],
    inProgress: {},
    keyRequest: null,
  } as State;

  myAlerts: MyAlerts | null = null;
  setMyAlerts = (myAlerts: MyAlerts) => {
    this.myAlerts = myAlerts;
  };

  componentDidMount() {
    const {
      location: { search },
    } = this.props;
    const { id: inviteId } = parse(search || "");

    if (!inviteId) {
      this.fetchInviteByLock();
    } else if (inviteId) {
      this.fetchInvite();
    }
  }

  fetchInvite = () => {
    const {
      location: { search },
    } = this.props;
    const { id: inviteId } = parse(search || "");

    if (!inviteId) {
      alert("Please select an invite");
      return;
    }

    const keyRequestId = inviteId as string;

    this.setState({ fetchingKeyRequest: true }, async () => {
      try {
        const actionResult = await this.props.fetchGuestKeyRequest({
          id: keyRequestId,
        });
        this.setState({
          keyRequest: guestInviteWithSortedLocks(
            (actionResult as any).keyRequest as GuestInviteType
          ),
        });
      } catch (e) {
        alert("Error fetching invite: " + JSON.stringify(e));
      } finally {
        this.setState({
          fetchingKeyRequest: false,
        });
      }
    });
  };

  doGuestOpenDoor = (locks: Array<GuestInviteLockIdResponse>) => {
    if (!locks || locks.length === 0) return;

    const { session: auth0Session } = this.props.auth0;
    const { keyRequest } = this.state;

    const credentialType = locks[0].credentialType;
    if (locks.some((l) => l.credentialType !== credentialType)) {
      alert("Only 1 credentialType is allowed in a request");
      return;
    }

    confirmPrompt({
      promptMessage: `Are you sure you want to open/get credential door ${locks
        .map((l) => (l.lock ? l.lock.name : ""))
        .join(", ")}?`,
      onConfirm: async () => {
        const inProgress = locks.reduce(
          (acc, l) => ({ ...acc, [l.lock ? l.lock.id || "" : ""]: true }),
          {}
        );
        this.setState(
          {
            inProgress: {
              ...this.state.inProgress,
              ...inProgress,
            },
          },
          async () => {
            try {
              const json = await this.props.guestOpenDoor({
                credentialType: credentialType
                  ? credentialType
                  : KeyRequestCredentialType.MobileKey,
                keyRequestId: keyRequest ? keyRequest.id || "" : "",
                lockIds: locks.map((l) => (l.lock ? l.lock.id || "" : "")),
                accessToken: auth0Session ? auth0Session.accessToken || "" : "",
              });
              const info = (json as any as GuestKeyRequestResponse)
                .tokenOrSecretInstruction;
              this.myAlerts &&
                this.myAlerts.addApiSuccessAlert(
                  info ? info : "Successfully opened door or issued key",
                  { dismissAfterMs: info ? undefined : 5000 }
                );
            } catch (e) {
              this.myAlerts && this.myAlerts.addApiErrorAlert(e);
            } finally {
              const inProgress = locks.reduce(
                (acc, l) => ({
                  ...acc,
                  [l.lock ? l.lock.id || "" : ""]: false,
                }),
                {}
              );
              this.setState({
                inProgress: {
                  ...this.state.inProgress,
                  ...inProgress,
                },
              });
            }
          }
        );
      },
    });
  };

  fetchInviteByLock = () => {
    const {
      location: { search },
      auth0: {
        session: { accessToken },
      },
    } = this.props;
    const { lockId, doOpen } = parse(search || "");

    if (!accessToken || !lockId) {
      return;
    }

    this.setState({ fetchingKeyRequest: true }, async () => {
      try {
        const actionResult = await this.props.fetchGuestKeyRequestByLock({
          id: lockId as string,
          accessToken,
        });
        const invite = (actionResult as any).keyRequest as GuestInviteType;
        this.setState(
          {
            keyRequest: guestInviteWithSortedLocks(invite),
          },
          () => {
            if (doOpen && invite && invite.lockIds) {
              const foundLock = invite.lockIds.find((l) =>
                l.lock ? l.lock.id === lockId : false
              );
              if (foundLock && foundLock.lock) {
                this.doGuestOpenDoor([foundLock]);
              }
            }
          }
        );
      } catch (e) {
        alert("Error fetching invite: " + JSON.stringify(e));
      } finally {
        this.setState({
          fetchingKeyRequest: false,
        });
      }
    });
  };

  public render() {
    const { header } = this.props;

    const { keyRequest, fetchingKeyRequest } = this.state;

    if (fetchingKeyRequest) {
      return <Spinner />;
    }

    const now = new Date();

    const {
      connectUser = null,
      validFrom = "",
      validTo = "",
    } = keyRequest || {};

    const statusStr =
      keyRequest !== null ? inviteStatus(now, keyRequest) : "Scheduled";

    return (
      <div>
        <h3>{header}</h3>
        <hr />

        <MyAlerts ref={this.setMyAlerts} />

        {keyRequest && (
          <div>
            <p />
            <div>
              <b>From</b>
            </div>
            <div>
              {connectUser
                ? connectUser.name || connectUser.externalId || "unknown"
                : "unknown"}
            </div>
            <p />

            {keyRequest.details && (
              <div className="panel panel-info">
                <div className="panel-heading">Details from host:</div>
                <div className="panel-body">
                  <Markdown source={keyRequest.details} />
                </div>
                <p />
              </div>
            )}

            {(validFrom || validTo) && (
              <div>
                <div className={`text-${inviteClassName(now, keyRequest)}`}>
                  <b>{statusStr}</b>
                </div>
                <div>
                  {formatLocalTime(validFrom as any, validTimeFormat) ||
                    "any time"}{" "}
                  -{" "}
                  {formatLocalTime(validTo as any, validTimeFormat) ||
                    "any time"}
                </div>
                <p />
              </div>
            )}
          </div>
        )}
        {this.renderKeyRequest()}
        <div>
          {isAdmin(this.props.me.user) && (
            <React.Fragment>
              {" "}
              | <Link to="/allInvites">View all invites</Link>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  private renderKeyRequest() {
    const { keyRequest, inProgress } = this.state;
    if (!keyRequest || !keyRequest.lockIds) return null;

    const isInProgress = (lock?: LockResponse) =>
      !!(inProgress as any)[lock ? lock.id || "" : ""];

    return (
      <div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th />
              <th>Site</th>
              <th>Unit</th>
            </tr>
          </thead>
          <tbody>
            {keyRequest.lockIds.map((kl, i: any) => (
              <tr key={"f_" + i}>
                <td>
                  <button
                    className="btn btn-primary btn-lg btn-block"
                    type="button"
                    style={{ width: 130 }}
                    disabled={isInProgress(kl.lock)}
                    onClick={(_e: any) =>
                      keyRequest &&
                      kl.lock &&
                      this.doGuestOpenDoor(
                        isAssaMobileLock(kl)
                          ? getAssaMobileLocks(keyRequest, kl)
                          : [kl]
                      )
                    }
                  >
                    {buttonLabel(
                      kl.credentialType
                        ? kl.credentialType
                        : KeyRequestCredentialType.MobileKey,
                      isInProgress(kl.lock)
                    )}
                  </button>
                </td>
                <td style={{ paddingTop: 20 }}>
                  {kl.lock && kl.lock.site ? kl.lock.site.name : "Unknown Site"}
                </td>
                <td style={{ paddingTop: 20 }}>
                  {kl.lock ? kl.lock.name : ""}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

function getAssaMobileLocks(
  keyRequest: GuestInviteType,
  lock: GuestInviteLockIdResponse
): GuestInviteLockIdResponse[] {
  if (!keyRequest || !keyRequest.lockIds) return [];

  // group all locks *for the same site* into the same Assa mobile key

  return keyRequest.lockIds.filter(
    (kl) =>
      !!kl.lock &&
      isAssaMobileLock(kl) &&
      lock.lock &&
      lock.lock.site &&
      kl.lock.site &&
      kl.lock.site.id === lock.lock.site.id
  );
}

function isAssaMobileLock(lock: GuestInviteLockIdResponse) {
  return (
    lock &&
    lock.lock &&
    lock.lock.site &&
    lock.lock.site.account &&
    lock.lock.site.account.systemType === SiteSystemType.Assa &&
    lock.credentialType === KeyRequestCredentialType.MobileKey
  );
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    auth0: state.auth0,
    me: state.me,
  }),
  {
    ...GuestKeyRequestsState.actionCreators,
  }
)(GuestInvite);
