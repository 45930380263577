import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";

import {
  FetchParams,
  reduceSetFetchParams,
  defaultPageSize,
  reduceNextPage,
  reducePrevPage,
  apiClientFactory,
} from "../helpers";
import {
  AccessControlDoorType,
  LockResponse,
  RegisterLockResponse,
} from "../api/ApiClient";

export interface LocksState extends FetchParams {
  isLoading: boolean;
  locks: LockResponse[];
  actionResultSuccess: boolean | null;
  actionResultMessage: string | null;
  syncLockInProgress: boolean;
  syncLockActionResultSuccess: boolean | null;
  syncLockActionResultMessage: string | null;
  flags?: number | undefined;
}

interface RequestLocksAction {
  type: "REQUEST_LOCKS";
}

interface SetFetchParams extends FetchParams {
  type: "SETFETCHPARAMS_LOCKS";
}

interface NextPage {
  type: "NEXTPAGE_LOCKS";
}

interface PrevPage {
  type: "PREVPAGE_LOCKS";
}

interface ReceiveLocksAction extends FetchParams {
  type: "RECEIVE_LOCKS";
  locks: LockResponse[];
}

interface RegisterLockActionPayload {
  name: string;
  siteId: string;
  systemLockId: string;
  doorType: AccessControlDoorType;
}
interface RegisterLockAction extends RegisterLockActionPayload {
  type: "REGISTER_LOCK";
}

interface DeleteLockActionPayload {
  id: string;
}
interface DeleteLockAction extends DeleteLockActionPayload {
  type: "DELETE_LOCK";
}

interface LocksResultAction {
  type: "RESULT_LOCKS";
  message: string;
  success: boolean;
}

interface LockSyncStart {
  type: "LOCK_SYNC_START";
}

interface LockSyncEnd {
  type: "LOCK_SYNC_END";
  message: string;
  success: boolean;
}

interface LockSyncFlagsEditAction {
  type: "LOCK_SYNC_FLAGS_EDIT";
  flags: number | undefined;
}

type KnownAction =
  | RequestLocksAction
  | SetFetchParams
  | NextPage
  | PrevPage
  | ReceiveLocksAction
  | RegisterLockAction
  | RegisterLockAction
  | DeleteLockAction
  | LocksResultAction
  | LockSyncStart
  | LockSyncEnd
  | LockSyncFlagsEditAction;

export const actionCreators = {
  setFetchParamsLocks:
    ({
      page,
      pageSize,
      searchTerm,
    }: FetchParams): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const state = getState();
      if (state.locks.isLoading) {
        console.log("still loading, not setting locks fetch params");
        return;
      }

      dispatch({
        type: "SETFETCHPARAMS_LOCKS",
        page,
        pageSize,
        searchTerm,
      });

      await dispatch(actionCreators.requestLocks() as any);
    },
  nextLockPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.locks.isLoading) {
        console.log("still loading, not going to next locks page");
        return;
      }

      dispatch({
        type: "NEXTPAGE_LOCKS",
      });

      await dispatch(actionCreators.requestLocks() as any);
    },
  prevLockPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.locks.isLoading) {
        console.log("still loading, not going to prev locks page");
        return;
      }

      dispatch({
        type: "PREVPAGE_LOCKS",
      });

      await dispatch(actionCreators.requestLocks() as any);
    },

  requestLocks:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const {
        locks: { page, pageSize, searchTerm },
      } = getState();
      dispatch({
        type: "REQUEST_LOCKS",
      });
      try {
        const data = await apiClientFactory().getLocks(
          page,
          pageSize,
          searchTerm,
          undefined
        );
        dispatch({
          type: "RECEIVE_LOCKS",
          locks: data,
          page,
          pageSize,
          searchTerm,
        });
        return data;
      } catch (_error) {
        dispatch({
          type: "RESULT_LOCKS",
          message: String(_error),
          success: false,
        });
      }
    },

  registerLock:
    (info: RegisterLockActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState): Promise<RegisterLockResponse> => {
      const json = await apiClientFactory().postLock({
        name: info.name,
        siteId: info.siteId,
        systemLockId: info.systemLockId,
        doorType: info.doorType,
      });
      return json;
    },

  deleteLock:
    (info: DeleteLockActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      await apiClientFactory().deleteLock(info.id);
      await dispatch(actionCreators.requestLocks() as any);
    },

  updateLock:
    (info: LockResponse): AppThunkAction<KnownAction> =>
    async (dispatch, getState): Promise<void> => {
      try {
        await apiClientFactory().putLock(info.id as string, {
          doorType: info.doorType,
          name: info.name,
          noTourEnabled: info.noTourEnabled ? true : false,
        });
        dispatch({ type: "RESULT_LOCKS", message: "Updated", success: true });
        await dispatch(actionCreators.requestLocks() as any);
      } catch (_error) {
        dispatch({
          type: "RESULT_LOCKS",
          message: String(_error),
          success: false,
        });
      }
    },

  syncLock:
    (id: string, flags: number | undefined): AppThunkAction<KnownAction> =>
    async (dispatch, getState): Promise<void> => {
      dispatch({ type: "LOCK_SYNC_START" });
      try {
        const result = await apiClientFactory().syncLock(id, flags);
        dispatch({ type: "LOCK_SYNC_END", message: result, success: true });
      } catch (_error) {
        dispatch({
          type: "LOCK_SYNC_END",
          message: String(_error),
          success: false,
        });
      }
    },

  editSyncFlags:
    (flags: number | undefined): AppThunkAction<KnownAction> =>
    async (dispatch): Promise<void> => {
      dispatch({ type: "LOCK_SYNC_FLAGS_EDIT", flags: flags });
    },
};

const unloadedState: LocksState = {
  locks: [],
  isLoading: false,
  page: 0,
  pageSize: defaultPageSize,
  actionResultMessage: null,
  actionResultSuccess: false,
  syncLockInProgress: false,
  syncLockActionResultMessage: null,
  syncLockActionResultSuccess: false,
  searchTerm: "",
  flags: 7,
};

export const reducer: Reducer<LocksState> = (
  state: LocksState = unloadedState,
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "REQUEST_LOCKS":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVE_LOCKS":
      return {
        ...state,
        locks: action.locks,
        isLoading: false,
        page: action.page,
        pageSize: action.pageSize,
        searchTerm: action.searchTerm,
      };

    case "SETFETCHPARAMS_LOCKS":
      return reduceSetFetchParams(state.isLoading, action, state);
    case "NEXTPAGE_LOCKS":
      return reduceNextPage(state.locks, state.isLoading, state);
    case "PREVPAGE_LOCKS":
      return reducePrevPage(state.isLoading, state);
    case "RESULT_LOCKS":
      return {
        ...state,
        actionResultMessage: action.message,
        actionResultSuccess: action.success,
      };
    case "LOCK_SYNC_START":
      return {
        ...state,
        syncLockInProgress: true,
      };
    case "LOCK_SYNC_END":
      return {
        ...state,
        syncLockInProgress: false,
        syncLockActionResultMessage: action.message,
        syncLockActionResultSuccess: action.success,
      };
    case "LOCK_SYNC_FLAGS_EDIT":
      return {
        ...state,
        flags: action.flags,
      };
    case "REGISTER_LOCK":
    case "DELETE_LOCK":
    default:
      return state;
  }
};
