import React from "react";
import { Switch, Route } from "react-router-dom";

import AdminLocks from "../Admin/AdminLocks";
import LockEvents from "../LockEvents";
import LockAutomation from "../LockAutomation";
import LockAutomationEdit from "../LockAutomationEdit";
import LockHolidayEdit from "../LockHolidayEdit";
import LockEdit from "./LockEdit";

export default function Locks() {
  return (
    <Switch>
      <Route exact path="/admin/locks" component={AdminLocks} />
      <Route exact path="/admin/locks/:lockId/edit" component={LockEdit} />
      <Route exact path="/admin/locks/:lockId/events" component={LockEvents} />
      <Route
        exact
        path="/admin/locks/:lockId/automation"
        component={LockAutomation}
      />
      <Route
        exact
        path="/admin/locks/:lockId/automation/edit"
        component={LockAutomationEdit}
      />
      <Route
        exact
        path="/admin/locks/:lockId/holiday/edit"
        component={LockHolidayEdit}
      />
    </Switch>
  );
}
