import moment from "moment";

import {
  GuestInviteResponse,
  ScreenInviteResponse,
  ScreenChildType,
  ConnectUserResponse,
  TenantResponse,
} from "../api/ApiClient";
import { apiFetchParams } from "./api-fetch-params";
import { ApiClient } from "../api/ApiClient";

export const apiClientFactory = () => {
  return new ApiClient(
    { getAccessToken: () => apiFetchParams.accessToken },
    apiFetchParams.baseApiUrl
  );
};
export interface LockIdentifier {
  propertyName: string;
  unitName: string;
}

export function confirmPrompt({
  promptMessage,
  onConfirm,
  onCancel,
}: {
  promptMessage: string;
  onConfirm: () => any;
  onCancel?: () => any;
}) {
  const confirmed = window.confirm(promptMessage);
  if (confirmed) {
    onConfirm && onConfirm();
  } else {
    onCancel && onCancel();
  }
}

async function throwResponseErrorIfAny(res: Response) {
  if (res.status >= 300) {
    let json = null;
    try {
      json = await res.clone().json();
    } catch (e) {}

    if (json) {
      throw new Error(json.message ? json.message : json);
    } else {
      throw new Error(res.statusText || "status " + res.status);
    }
  }
}

interface ApiError {
  statusText: string;
  status: string;
  message: string;
}

export function apiErrorStr(e: ApiError | Error) {
  return e.message && typeof e.message === "string"
    ? e.message
    : JSON.stringify(e);
}

export async function myFetch(url: string, init?: any): Promise<Response> {
  const accessToken = apiFetchParams.accessToken;
  const Authorization = accessToken;
  const options = {
    ...init,
    headers: { ...(init ? init.headers : {}), Authorization },
  };

  const res = await fetch(url, { ...options });
  await throwResponseErrorIfAny(res);
  return res;
}

export interface PageInfo {
  page: number;
  pageSize: number;
}

export interface FetchParams extends PageInfo {
  searchTerm: string;
}

export const defaultPageSize = 30;

export function reduceSetFetchParams<State>(
  isLoading: boolean,
  { page, pageSize, searchTerm }: FetchParams,
  state: State
) {
  return Object.assign({}, state, {
    page: searchTerm !== (state as any).searchTerm ? 0 : page, //reset page to 0 on changing search term
    pageSize,
    searchTerm,
  });
}

export function reduceNextPage<State extends PageInfo>(
  list: Array<any>,
  isLoading: boolean,
  state: State
) {
  if (list.length >= state.pageSize) {
    return Object.assign({}, state, {
      page: state.page + 1,
    });
  }
  return state;
}

export function reducePrevPage<State extends PageInfo>(
  isLoading: boolean,
  state: State
) {
  if (state.page > 0) {
    return Object.assign({}, state, {
      page: state.page - 1,
    });
  }
  return state;
}

function getCurrentTenant(
  user: ConnectUserResponse | undefined
): TenantResponse | undefined {
  if (user !== undefined && user.tenants !== undefined) {
    const tenant = user.tenants.find((e) => e.tenantId === user.activeTenantId);
    if (tenant !== undefined && tenant.tenant !== undefined) {
      return tenant.tenant;
    }
  }
  return undefined;
}

export function getExternalTenantId(
  user: ConnectUserResponse | undefined
): string {
  const tenant = getCurrentTenant(user);
  if (tenant !== undefined && tenant.externalId !== undefined) {
    return tenant.externalId;
  }
  return "";
}

export function getCurrentTenantName(
  user: ConnectUserResponse | undefined
): string {
  const tenant = getCurrentTenant(user);
  if (tenant !== undefined && tenant.name !== undefined) {
    return tenant.name;
  }
  return "";
}
export function isAdmin(user: ConnectUserResponse | undefined): boolean {
  if (user !== undefined && user.tenants !== undefined) {
    const tenant = user.tenants.find((e) => e.tenantId === user.activeTenantId);
    if (tenant !== undefined) {
      return tenant.role === "admin";
    }
  }
  return false;
}

export function localTimeToDate(date: string) {
  return moment(date).toDate();
}

export function formatDateAsLocalTime(date: Date) {
  if (!date) return "";
  return moment(date).format("YYYY-MM-DDTHH:mm");
}

export function formatLocalTime(
  date: string,
  timeFormat = "YYYY, MMM Do, h:mm a"
) {
  if (!date) return "";
  // string is in "2019-03-13T18:00:00.fffZ" format - represents GMT
  return moment(date).format(timeFormat);
}

export function toUTCTime(date: string) {
  if (!date) return "";
  // string is in "2019-03-13T18:00" format, represents local time
  return moment(date).utc().format("YYYY-MM-DDTHH:mm");
}

export function toUTCDate(date: string) {
  if (!date) return undefined;
  // string is in "2019-03-13T18:00" format, represents local time
  return moment(date).toDate();
}

export function addMinutes(date: Date, minutes: number) {
  return new Date(date.getTime() + minutes * 60 * 1000);
}

export function formatLockInfos(lockIdentifiers: string) {
  const infos = lockIdentifiers
    ? lockIdentifiers.split(",").map((l) => l.trim())
    : [];
  return infos
    .map((info: string) => {
      const toks = info ? info.split(":") : [];

      if (toks.length < 1) return null;

      if (toks.length === 2) {
        return {
          propertyName: toks[0],
          unitName: toks[1],
        };
      }
      return {
        propertyName: "",
        unitName: toks[0],
      } as LockIdentifier;
    })
    .filter((info) => !!info) as LockIdentifier[];
}

export type InviteStatus = "Scheduled" | "Active" | "Expired";
export type InviteClassName = "warning" | "success" | "danger" | null;

function backendStrToMoment(date: string) {
  // string is in "2019-03-13T18:00:00.fffZ" format - represents GMT
  return moment(date);
}

function backendStrToDate(date: string) {
  return backendStrToMoment(date).toDate();
}

export function inviteStatus(
  now: Date,
  { validFrom, validTo }: GuestInviteResponse | ScreenInviteResponse
): InviteStatus {
  const nowDt = now.getTime();
  if (nowDt < backendStrToDate(validFrom as any).getTime()) {
    return "Scheduled";
  }
  if (nowDt > backendStrToDate(validTo as any).getTime()) {
    return "Expired";
  }
  return "Active";
}

function inviteStatusToClassName(status: InviteStatus): InviteClassName {
  switch (status) {
    case "Scheduled":
      return "warning";
    case "Active":
      return "success";
    case "Expired":
      return "danger";
    default:
      return null;
  }
}

export function inviteClassName(
  now: Date,
  keyRequest: GuestInviteResponse | ScreenInviteResponse
) {
  return inviteStatusToClassName(inviteStatus(now, keyRequest));
}

export function guestInviteWithSortedLocks(invite: GuestInviteResponse) {
  const haveSortKey = (invite.lockIds || []).every(
    (l) => l.sortKey !== undefined
  );
  if (!haveSortKey) return invite;
  const lockIds = [...(invite.lockIds || [])];
  lockIds.sort((a, b) => (a.sortKey || 0) - (b.sortKey || 0));
  return { ...invite, lockIds };
}

export function screenInviteWithSortedScreens(invite: ScreenInviteResponse) {
  const haveSortKey = (invite.screenTiles || []).every(
    (l) => l.sortKey !== undefined
  );
  if (!haveSortKey) return invite;
  const screenTiles = [...(invite.screenTiles || [])];
  screenTiles.sort((a, b) => (a.sortKey || 0) - (b.sortKey || 0));
  return { ...invite, screenTiles };
}

export function screenDeployButtonLabel(childType: ScreenChildType) {
  switch (childType) {
    case ScreenChildType.Proposal:
      return "Accept Proposal";
    case ScreenChildType.Version:
      return "Deploy";
    case ScreenChildType.Scheduled:
      return "Activate";
    default:
      return "";
  }
}

export const tzList = {
  "Etc/GMT+12": "(GMT-12:00) International Date Line West",
  "Pacific/Midway": "(GMT-11:00) Midway Island, Samoa",
  "Pacific/Honolulu": "(GMT-10:00) Hawaii",
  "US/Alaska": "(GMT-09:00) Alaska",
  "America/Los_Angeles": "(GMT-08:00) Pacific Time (US & Canada)",
  "US/Arizona": "(GMT-07:00) Arizona",
  "America/Managua": "(GMT-06:00) Central America",
  "US/Central": "(GMT-06:00) Central Time (US & Canada)",
  "America/Bogota": "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
  "US/Eastern": "(GMT-05:00) Eastern Time (US & Canada)",
  "Canada/Atlantic": "(GMT-04:00) Atlantic Time (Canada)",
  "America/Argentina/Buenos_Aires": "(GMT-03:00) Buenos Aires, Georgetown",
  "America/Noronha": "(GMT-02:00) Mid-Atlantic",
  "Atlantic/Azores": "(GMT-01:00) Azores",
  "Etc/Greenwich":
    "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
  "Europe/Amsterdam":
    "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
  "Europe/Helsinki":
    "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
  "Europe/Moscow": "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
  "Asia/Tehran": "(GMT+03:30) Tehran",
  "Asia/Yerevan": "(GMT+04:00) Yerevan",
  "Asia/Kabul": "(GMT+04:30) Kabul",
  "Asia/Yekaterinburg": "(GMT+05:00) Yekaterinburg",
  "Asia/Karachi": "(GMT+05:00) Islamabad, Karachi, Tashkent",
  "Asia/Calcutta": "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
  "Asia/Katmandu": "(GMT+05:45) Kathmandu",
  "Asia/Dhaka": "(GMT+06:00) Astana, Dhaka",
  "Asia/Rangoon": "(GMT+06:30) Yangon (Rangoon)",
  "Asia/Bangkok": "(GMT+07:00) Bangkok, Hanoi, Jakarta",
  "Asia/Hong_Kong": "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
  "Asia/Seoul": "(GMT+09:00) Seoul",
  "Australia/Adelaide": "(GMT+09:30) Adelaide",
  "Australia/Canberra": "(GMT+10:00) Canberra, Melbourne, Sydney",
  "Asia/Magadan": "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
  "Pacific/Auckland": "(GMT+12:00) Auckland, Wellington",
  "Pacific/Tongatapu": "(GMT+13:00) Nuku'alofa",
};
