import { useEffect, useState } from "react";
import * as React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { apiClientFactory } from "../helpers";

import { LockAutomationResponse, LockHolidayResponse } from "../api/ApiClient";

function LockAutomation() {
  // Declare a new state variable, which we'll call "count"
  const [lockAutomations, setLockAutomations] = useState(
    [] as LockAutomationResponse[]
  );
  const [lockAutomationsLoading, setLockAutomationsLoading] = useState(false);
  const [lockHolidays, setLockHolidays] = useState([] as LockHolidayResponse[]);
  const [lockHolidaysLoading, setLockHolidaysLoading] = useState(false);
  const location = useLocation();
  const { lockId } = useParams();

  const fetchLockAutomations = function () {
    setLockAutomationsLoading(true);
    apiClientFactory()
      .getLockAutomations(lockId)
      .then((r) => setLockAutomations(r))
      .catch((r) => console.error(r))
      .finally(() => setLockAutomationsLoading(false));
  };

  const fetchLockHolidays = function () {
    setLockHolidaysLoading(true);
    apiClientFactory()
      .getLockHolidays(lockId)
      .then((r) => setLockHolidays(r))
      .catch((r) => console.error(r))
      .finally(() => setLockHolidaysLoading(false));
  };

  const progressStyle: React.CSSProperties = {
    width: "100%",
  };

  useEffect(() => {
    (async () => {
      fetchLockAutomations();
      fetchLockHolidays();
    })();
    // eslint-disable-next-line
  }, [location]);

  const deleteAutomation = async function (
    e: React.MouseEvent,
    id: string | undefined
  ) {
    e.preventDefault();
    if (id) {
      await apiClientFactory().deleteLockAutomation(lockId, id);
      fetchLockAutomations();
    }
  };

  const deleteHoliday = async function (
    e: React.MouseEvent,
    id: string | undefined
  ) {
    e.preventDefault();
    if (id) {
      await apiClientFactory().deleteLockHoliday(lockId, id);
      fetchLockHolidays();
    }
  };

  return (
    <React.Fragment>
      {lockAutomationsLoading ? (
        <div className="jumbotron">
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped active"
              role="progressbar"
              aria-valuenow={100}
              style={progressStyle}
            >
              Loading
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h3>Automations</h3>
          <div className="well">
            <p>
              Use automation scheduling to automatically lock and unlock doors
              each week at a specified date and time
            </p>
            <p>
              <em>WARNING!</em> Automations and holidays will not take effect on
              the lock until it is updated. You can force an update using the
              Engage Mobile application, or using the Sync function in Connect
              if the lock is in online operating mode.
            </p>
          </div>
          <Link
            to={`/admin/locks/${lockId}/automation/edit`}
            className="btn btn-primary"
          >
            Create Automation
          </Link>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th>Action</th>
                  <th>Time</th>
                  <th>Days</th>
                </tr>
              </thead>
              <tbody>
                {lockAutomations.map((e) => {
                  const days: string[] = [];
                  if (e.sunday) days.push("Sunday");
                  if (e.monday) days.push("Monday");
                  if (e.tuesday) days.push("Tuesday");
                  if (e.wednesday) days.push("Wednesday");
                  if (e.thursday) days.push("Thursday");
                  if (e.friday) days.push("Friday");
                  if (e.saturday) days.push("Sat");
                  return (
                    <tr key={e.id}>
                      <td>
                        <Link
                          to={`/admin/locks/${lockId}/automation/edit?id=${e.id}`}
                          className="btn btn-primary"
                        >
                          Edit
                        </Link>
                        <button
                          onClick={(event) => deleteAutomation(event, e.id)}
                          className="btn btn-danger"
                        >
                          Delete
                        </button>
                      </td>
                      <td>
                        {e.action === "pass"
                          ? "Pass"
                          : e.action === "sec"
                          ? "Secure"
                          : e.action}
                      </td>
                      <td>
                        {e.time
                          ? `${(
                              Math.floor(e.time / 60) - (e.time >= 780 ? 12 : 0)
                            )
                              .toString()
                              .padStart(2, "0")}:${(e.time % 60)
                              .toString()
                              .padStart(2, "0")} ${e.time >= 720 ? "PM" : "AM"}`
                          : "Not Set"}
                      </td>
                      <td>{days.join(",")}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {lockAutomations.length === 0 ? (
            <div className="alert alert-warning">No Automations Found</div>
          ) : (
            <React.Fragment />
          )}
        </div>
      )}
      {lockHolidaysLoading ? (
        <div className="jumbotron">
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped active"
              role="progressbar"
              aria-valuenow={100}
              style={progressStyle}
            >
              Loading
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h3>Holidays</h3>
          <div className="well">
            <p>
              Use holiday scheduling to override automation for specific dates,
              or to require elevated access to a door for a specified period of
              time.
            </p>
          </div>
          <Link
            to={`/admin/locks/${lockId}/holiday/edit/`}
            className="btn btn-primary"
          >
            Create Holiday
          </Link>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th>Action</th>
                  <th>Start</th>
                  <th>End</th>
                </tr>
              </thead>
              <tbody>
                {lockHolidays.map((e) => (
                  <tr key={e.id}>
                    <td>
                      <Link
                        to={`/admin/locks/${lockId}/holiday/edit?id=${e.id}`}
                        className="btn btn-primary"
                      >
                        Edit
                      </Link>
                      <button
                        onClick={(event) => deleteHoliday(event, e.id)}
                        className="btn btn-danger"
                      >
                        Delete
                      </button>
                    </td>
                    <td>
                      {e.action === "pass"
                        ? "Pass"
                        : e.action === "sec"
                        ? "Secure"
                        : e.action === "rstrctSec"
                        ? "Resricted Secure"
                        : e.action}
                    </td>
                    <td>
                      {new Date(`${e.startDateTime}+0000`).toLocaleString()}
                    </td>
                    <td>
                      {new Date(`${e.endDateTime}+0000`).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {lockHolidays.length === 0 ? (
            <div className="alert alert-warning">No Holidays Found</div>
          ) : (
            <React.Fragment />
          )}
        </div>
      )}
    </React.Fragment>
  );
}
export default LockAutomation;
