import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import {
  FetchParams,
  reduceSetFetchParams,
  defaultPageSize,
  reduceNextPage,
  reducePrevPage,
  apiClientFactory,
} from "../helpers";

import {
  ScreenResponse,
  ScreenLayoutType,
  TileFragmentInfo,
} from "../api/ApiClient";

interface ScreensFetchParams extends FetchParams {
  showChildren: boolean;
}

export interface ScreensState extends ScreensFetchParams {
  isLoading: boolean;
  screens: ScreenResponse[];
}

interface RequestScreensAction {
  type: "REQUEST_SCREENS";
}

interface SetFetchParams extends ScreensFetchParams {
  type: "SETFETCHPARAMS_SCREENS";
}

interface NextPage {
  type: "NEXTPAGE_SCREENS";
}

interface PrevPage {
  type: "PREVPAGE_SCREENS";
}

interface ReceiveScreensAction extends FetchParams {
  type: "RECEIVE_SCREENS";
  screens: ScreenResponse[];
}

interface RegisterScreenActionPayload {
  layoutType: ScreenLayoutType;
  accountId: string;
  name: string;
  appId: string;
  description: string;

  playerIds: string[];
  tileFragments: TileFragmentInfo[];
}
interface RegisterScreenAction extends RegisterScreenActionPayload {
  type: "REGISTER_SCREEN";
}

interface UpdateScreenActionPayload extends RegisterScreenActionPayload {
  id: string;
  acceptAndDeploy: boolean;
}
interface UpdateScreenAction extends UpdateScreenActionPayload {
  type: "UPDATE_SCREEN";
}

interface GuestUpdateScreenActionPayload {
  screenInviteId: string;
  accessToken: string;
  updateRequest: UpdateScreenActionPayload;
}
interface GuestUpdateScreenAction extends GuestUpdateScreenActionPayload {
  type: "GUEST_UPDATE_SCREEN";
}

interface DeleteScreenActionPayload {
  id: string;
}
interface DeleteScreenAction extends DeleteScreenActionPayload {
  type: "DELETE_SCREEN";
}

interface GetScreenActionPayload {
  id: string;
}

interface GetScreenProposalsActionPayload {
  id: string;
  page: number;
}

type KnownAction =
  | RequestScreensAction
  | SetFetchParams
  | NextPage
  | PrevPage
  | ReceiveScreensAction
  | RegisterScreenAction
  | UpdateScreenAction
  | GuestUpdateScreenAction
  | DeleteScreenAction;

export const actionCreators = {
  setFetchParamScreens:
    ({
      page,
      pageSize,
      searchTerm,
      showChildren,
    }: ScreensFetchParams): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const state = getState();
      if (state.screens.isLoading) return;

      dispatch({
        type: "SETFETCHPARAMS_SCREENS",
        page,
        pageSize,
        searchTerm,
        showChildren,
      });

      await dispatch(actionCreators.requestScreens() as any);
    },
  nextScreenPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.screens.isLoading) return;

      dispatch({
        type: "NEXTPAGE_SCREENS",
      });

      await dispatch(actionCreators.requestScreens() as any);
    },
  prevScreenPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.screens.isLoading) return;

      dispatch({
        type: "PREVPAGE_SCREENS",
      });

      await dispatch(actionCreators.requestScreens() as any);
    },

  requestScreens: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const {
      screens: {
        page,
        pageSize,
        showChildren,
        searchTerm,
        screens: curScreens,
      },
    } = getState();

    const fetchTask = apiClientFactory()
      .getScreens(page, pageSize, searchTerm, undefined, showChildren)
      .then(
        (data) => {
          dispatch({
            type: "RECEIVE_SCREENS",
            screens: data,
            page,
            pageSize,
            searchTerm,
          });
        },
        (_error) => {
          dispatch({
            type: "RECEIVE_SCREENS",
            screens: curScreens,
            page,
            pageSize,
            searchTerm,
          });
        }
      );

    dispatch({
      type: "REQUEST_SCREENS",
    });
    return fetchTask;
  },

  registerScreen:
    (info: RegisterScreenActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const json = await apiClientFactory().postScreen({
        accountId: info.accountId,
        screenLayoutType: info.layoutType,
        name: info.name,
        appId: info.appId,
        description: info.description,
        playerIds: info.playerIds,
        tileFragments: info.tileFragments,
      });

      return json;
    },

  updateScreen:
    (info: UpdateScreenActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const json = await apiClientFactory().updateScreen(info.id, {
        accountId: info.accountId,
        acceptAndDeploy: info.acceptAndDeploy,
        screenLayoutType: info.layoutType,
        name: info.name,
        appId: info.appId,
        description: info.description,
        playerIds: info.playerIds,
        tileFragments: info.tileFragments,
      });

      return json;
    },

  guestUpdateScreen:
    (info: GuestUpdateScreenActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const json = await apiClientFactory().updateScreenFromInvite({
        screenId: info.updateRequest.id,
        screenInviteId: info.screenInviteId,
        accessToken: info.accessToken,
        updateRequest: {
          accountId: info.updateRequest.accountId,
          screenLayoutType: info.updateRequest.layoutType,
          name: info.updateRequest.name,
          appId: info.updateRequest.appId,
          description: info.updateRequest.description,
          playerIds: info.updateRequest.playerIds,
          tileFragments: info.updateRequest.tileFragments,
        },
        shouldNotCheckGuestId: false,
      });

      return json;
    },

  deleteScreen:
    (info: DeleteScreenActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      await apiClientFactory().deleteScreen(info.id);

      await dispatch(actionCreators.requestScreens() as any);
    },

  fetchScreen:
    (info: GetScreenActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const screen = await apiClientFactory().getScreen(info.id);
      return screen;
    },

  getScreenChildren:
    (info: GetScreenProposalsActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const children = await apiClientFactory().getScreenChildren(
        info.id,
        info.page,
        defaultPageSize
      );
      return children;
    },
};

const unloadedState: ScreensState = {
  screens: [],
  isLoading: false,
  page: 0,
  pageSize: defaultPageSize,
  showChildren: false,
  searchTerm: "",
};

export const reducer: Reducer<ScreensState> = (
  state: ScreensState = unloadedState,
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "REQUEST_SCREENS":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVE_SCREENS":
      return {
        ...state,
        screens: action.screens,
        isLoading: false,
        page: action.page,
        pageSize: action.pageSize,
        searchTerm: action.searchTerm,
      };

    case "SETFETCHPARAMS_SCREENS":
      return {
        ...reduceSetFetchParams(state.isLoading, action, state),
        showChildren: action.showChildren,
      };
    case "NEXTPAGE_SCREENS":
      return reduceNextPage(state.screens, state.isLoading, state);
    case "PREVPAGE_SCREENS":
      return reducePrevPage(state.isLoading, state);

    case "REGISTER_SCREEN":
    case "UPDATE_SCREEN":
    case "GUEST_UPDATE_SCREEN":
    case "DELETE_SCREEN":
    default:
      return state;
  }
};
