import { useEffect, useState } from "react";
import * as React from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { apiClientFactory } from "../helpers";

import {
  FilterOperation,
  GuestResponse,
  ListSortDirection,
  SiteGuestProperty,
} from "../api/ApiClient";
import { stringify } from "query-string";

function Guests() {
  // Declare a new state variable, which we'll call "count"
  const [siteGuests, setSiteGuests] = useState([] as GuestResponse[]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const take = (function (): number {
    let t1 = query.get("take");
    t1 = t1 ? t1 : "";
    const t = Number.parseInt(t1);
    return t ? t : 10;
  })();

  const skip = (function (): number {
    let s1 = query.get("skip");
    s1 = s1 ? s1 : "";
    const s = Number.parseInt(s1);
    return s ? s : 0;
  })();

  const sortDirection = (function (): ListSortDirection | undefined {
    const s2: string | null = query.get("sortDirection");
    if (s2) {
      return ListSortDirection[s2 as keyof typeof ListSortDirection];
    }
    return undefined;
  })();

  const sortProperty = (function (): SiteGuestProperty | undefined {
    const s2: string | null = query.get("sortProperty");
    if (s2) {
      return SiteGuestProperty[s2 as keyof typeof SiteGuestProperty];
    }
    return undefined;
  })();

  const filterOperation = (function (): FilterOperation | undefined {
    const s2: string | null = query.get("filterOperation");
    if (s2) {
      return FilterOperation[s2 as keyof typeof FilterOperation];
    }
    return undefined;
  })();

  const filterProperty = (function (): SiteGuestProperty | undefined {
    const s2: string | null = query.get("filterProperty");
    if (s2) {
      return SiteGuestProperty[s2 as keyof typeof SiteGuestProperty];
    }
    return undefined;
  })();

  const filterValue = (function (): string | undefined {
    if (query.get("filterValue") !== null) {
      return query.get("filterValue") as string;
    }
    return undefined;
  })();

  const [tempFilterProperty, setTempFilterProperty] = useState(
    filterProperty ? SiteGuestProperty[filterProperty] : ""
  );
  const [tempFilterOperation, setTempFilterOperation] = useState(
    filterOperation ? FilterOperation[filterOperation] : ""
  );
  const [tempFilterValue, setTempFilterValue] = useState(
    filterValue !== null ? filterValue : ""
  );

  const { siteId } = useParams();

  const fetchSiteGuests = async function () {
    setLoading(true);
    const response = await apiClientFactory().getSiteGuests(
      siteId,
      skip,
      take,
      sortDirection,
      sortProperty,
      filterOperation,
      filterProperty,
      filterValue
    );
    setSiteGuests(response);
    setLoading(false);
  };

  const setFilter = function (
    takeParam: number | null = null,
    offset: number | null = null,
    sortDirectionP: ListSortDirection | null = null,
    sortPropertyP: SiteGuestProperty | null = null,
    filterOperationP: FilterOperation | null | undefined = undefined,
    filterPropertyP: SiteGuestProperty | null | undefined = undefined,
    filterValueP: string | null | undefined = undefined
  ) {
    const query = {} as any;
    if (sortDirectionP !== null) {
      query.sortDirection = ListSortDirection[sortDirectionP];
    } else if (sortDirection !== undefined) {
      query.sortDirection = ListSortDirection[sortDirection];
    }
    if (sortPropertyP !== null) {
      query.sortProperty = SiteGuestProperty[sortPropertyP];
    } else if (sortProperty !== undefined) {
      query.sortProperty = SiteGuestProperty[sortProperty];
    }

    if (filterOperationP !== undefined) {
      if (filterOperationP !== null) {
        query.filterOperation = FilterOperation[filterOperationP];
      }
    } else if (filterOperation !== undefined) {
      query.filterOperation = FilterOperation[filterOperation];
    }

    if (filterPropertyP !== undefined) {
      if (filterPropertyP !== null) {
        query.filterProperty = SiteGuestProperty[filterPropertyP];
      }
    } else if (filterProperty !== undefined) {
      query.filterProperty = SiteGuestProperty[filterProperty];
    }

    if (filterValueP !== undefined) {
      if (filterValueP !== null) {
        query.filterValue = filterValueP;
      }
    } else if (filterProperty !== null) {
      query.filterValue = filterValue;
    }

    if (takeParam !== null && (takeParam as number) > 0) {
      query.take = takeParam;
    } else {
      query.take = take;
    }
    if (offset !== null && (offset as number) > 0) {
      query.skip = offset;
    } else {
      query.skip = skip;
    }
    const searchString = stringify(query);
    history.push({
      pathname: location.pathname,
      search: searchString,
    });
  };

  const progressStyle: React.CSSProperties = {
    width: "100%",
  };

  useEffect(() => {
    (async () => fetchSiteGuests())();
    // eslint-disable-next-line
  }, [location]);
  return loading ? (
    <main className="jumbotron">
      <div className="progress">
        <div
          className="progress-bar progress-bar-striped active"
          role="progressbar"
          aria-valuenow={100}
          style={progressStyle}
        >
          Loading
        </div>
      </div>
    </main>
  ) : (
    <main>
      <div className="row">
        <div className="col-xs-12 col-sm-4">
          <div className="panel panel-default">
            <div className="panel-heading">Paging</div>
            <div className="panel-body">
              <form
                className="form-inline"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="form-group">
                  <label htmlFor="takeSelect">Records Per page</label>
                  <select
                    id="takeSelect"
                    className="form-control"
                    value={take}
                    onChange={(e) => setFilter(Number.parseInt(e.target.value))}
                  >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="form-group">
                  {skip > 0 ? (
                    <button
                      className="btn btn-default"
                      type="submit"
                      onClick={() => setFilter(null, skip - take)}
                    >
                      Previous
                    </button>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
                <div className="form-group">
                  {siteGuests && siteGuests.length === take ? (
                    <button
                      className="btn btn-default"
                      type="submit"
                      onClick={() => setFilter(null, skip + take)}
                    >
                      Next
                    </button>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-4">
          <div className="panel panel-default">
            <div className="panel-heading">Filtering</div>
            <div className="panel-body">
              <form
                className="form-inline"
                onSubmit={(e) => {
                  e.preventDefault();
                  setFilter(
                    null,
                    null,
                    null,
                    null,
                    tempFilterOperation !== ""
                      ? FilterOperation[
                          tempFilterOperation as keyof typeof FilterOperation
                        ]
                      : null,
                    tempFilterProperty !== ""
                      ? SiteGuestProperty[
                          tempFilterProperty as keyof typeof SiteGuestProperty
                        ]
                      : null,
                    tempFilterValue
                  );
                }}
              >
                <div className="form-group">
                  <select
                    id="filterPropertySelect"
                    className="form-control"
                    value={tempFilterProperty}
                    onChange={(e) =>
                      setTempFilterProperty(
                        e.target.value ? e.target.value : ""
                      )
                    }
                  >
                    <option />
                    {Object.keys(SiteGuestProperty)
                      .filter((k) => isNaN(Number(k)))
                      .map((k) => (
                        <option key={k} value={k}>
                          {k}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                  <select
                    id="filterOperationSelect"
                    className="form-control"
                    value={tempFilterOperation}
                    onChange={(e) =>
                      setTempFilterOperation(
                        e.target.value ? e.target.value : ""
                      )
                    }
                  >
                    <option />
                    {Object.keys(FilterOperation)
                      .filter((k) => isNaN(Number(k)))
                      .map((k) => (
                        <option key={k} value={k}>
                          {k}
                        </option>
                      ))}
                  </select>
                </div>
                {tempFilterProperty !== "" ? (
                  <div className="form-group">
                    <input
                      type={
                        tempFilterProperty ===
                          SiteGuestProperty[SiteGuestProperty.ValidFrom] ||
                        tempFilterProperty ===
                          SiteGuestProperty[SiteGuestProperty.ValidTo]
                          ? "date"
                          : "text"
                      }
                      value={tempFilterValue}
                      id="filterValueText"
                      className="form-control"
                      onChange={(e) => setTempFilterValue(e.target.value)}
                    />
                  </div>
                ) : (
                  <React.Fragment />
                )}
                <input
                  type="submit"
                  className="btn btn-default"
                  value="Filter"
                />
              </form>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-4">
          <div className="panel panel-default">
            <div className="panel-heading">Actions</div>
            <div className="panel-body">
              <Link
                to={`/admin/sites/${siteId}/onboardguest/`}
                className="btn btn-primary"
              >
                Onboard New Guest
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>
                First Name{" "}
                <button
                  className={
                    sortProperty === SiteGuestProperty.FirstName
                      ? "btn btn-success"
                      : "btn btn-default"
                  }
                  onClick={() =>
                    setFilter(
                      null,
                      null,
                      sortDirection === ListSortDirection.Descending
                        ? ListSortDirection.Ascending
                        : ListSortDirection.Descending,
                      SiteGuestProperty.FirstName
                    )
                  }
                >
                  {sortDirection === ListSortDirection.Ascending ? "▼" : "▲"}
                </button>
              </th>
              <th>
                Last Name{" "}
                <button
                  className={
                    sortProperty === SiteGuestProperty.LastName
                      ? "btn btn-success"
                      : "btn btn-default"
                  }
                  onClick={() =>
                    setFilter(
                      null,
                      null,
                      sortDirection === ListSortDirection.Descending
                        ? ListSortDirection.Ascending
                        : ListSortDirection.Descending,
                      SiteGuestProperty.LastName
                    )
                  }
                >
                  {sortDirection === ListSortDirection.Ascending ? "▼" : "▲"}
                </button>
              </th>
              <th>
                Valid From{" "}
                <button
                  className={
                    sortProperty === SiteGuestProperty.ValidFrom
                      ? "btn btn-success"
                      : "btn btn-default"
                  }
                  onClick={() =>
                    setFilter(
                      null,
                      null,
                      sortDirection === ListSortDirection.Descending
                        ? ListSortDirection.Ascending
                        : ListSortDirection.Descending,
                      SiteGuestProperty.ValidFrom
                    )
                  }
                >
                  {sortDirection === ListSortDirection.Ascending ? "▼" : "▲"}
                </button>
              </th>
              <th>
                Valid To{" "}
                <button
                  className={
                    sortProperty === SiteGuestProperty.ValidTo
                      ? "btn btn-success"
                      : "btn btn-default"
                  }
                  onClick={() =>
                    setFilter(
                      null,
                      null,
                      sortDirection === ListSortDirection.Descending
                        ? ListSortDirection.Ascending
                        : ListSortDirection.Descending,
                      SiteGuestProperty.ValidTo
                    )
                  }
                >
                  {sortDirection === ListSortDirection.Ascending ? "▼" : "▲"}
                </button>
              </th>
              <th>Badges</th>
              <th>Units</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {siteGuests.map((e) => (
              <tr key={e.id}>
                <td>{e.firstName}</td>
                <td>{e.lastName}</td>
                <td>
                  {e.validFrom ? new Date(e.validFrom).toLocaleString() : ""}
                </td>
                <td>{e.validTo ? new Date(e.validTo).toLocaleString() : ""}</td>
                <td>{e.badgeIds ? e.badgeIds.join(",") : ""}</td>
                <td>
                  {e.unitNumbers
                    ? e.unitNumbers.filter((e) => e !== "").join(",")
                    : ""}
                </td>
                <td>
                  <Link
                    to={`/admin/sites/${siteId}/guests/${encodeURIComponent(
                      e.id ? e.id : ""
                    )}`}
                    className="btn btn-block btn-default"
                  >
                    Details
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {siteGuests.length === 0 ? (
        <div className="alert alert-warning">No Guests Found</div>
      ) : (
        <React.Fragment />
      )}
    </main>
  );
}
export default Guests;
