import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { BASE_API_URL } from "store/Me";
import { BaseLivlyApiResponse } from "./useGetSystemTypes";

export default function usePostCsvLock(siteId: string) {
  const postCsv = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    const result = await axios.post<BaseLivlyApiResponse<any>>(
      `${BASE_API_URL}/api/sites/${siteId}/locks/batch`,
      formData
    );

    return result.data;
  };

  return useMutation((file: File) => postCsv(file));
}
