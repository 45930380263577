import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import {
  FetchParams,
  reduceSetFetchParams,
  defaultPageSize,
  reduceNextPage,
  reducePrevPage,
  apiClientFactory,
  getExternalTenantId,
} from "../helpers";

import { ConnectUserResponse } from "../api/ApiClient";

export interface ClientUsersState extends FetchParams {
  isLoading: boolean;
  clientUsers: ConnectUserResponse[];
}

interface RequestClientUsersAction {
  type: "REQUEST_CLIENT_USERS";
}

interface SetFetchParams extends FetchParams {
  type: "SETFETCHPARAMS_CLIENT_USERS";
}

interface NextPage {
  type: "NEXTPAGE_CLIENT_USERS";
}

interface PrevPage {
  type: "PREVPAGE_CLIENT_USERS";
}

interface ReceiveClientUsersAction extends FetchParams {
  type: "RECEIVE_CLIENT_USERS";
  clientUsers: ConnectUserResponse[];
}

interface RegisterClientUserActionPayload {
  name: string;
  email: string;
  role: string;
}
interface RegisterClientUserAction extends RegisterClientUserActionPayload {
  type: "REGISTER_CLIENT_USER";
}

interface RegisterBackendUserActionPayload {
  name: string;
  email: string;
  backendNameClaimId: string;
}

interface DeleteClientUserActionPayload {
  id: string;
}
interface DeleteClientUserAction extends DeleteClientUserActionPayload {
  type: "DELETE_CLIENT_USER";
}

type KnownAction =
  | RequestClientUsersAction
  | SetFetchParams
  | NextPage
  | PrevPage
  | ReceiveClientUsersAction
  | RegisterClientUserAction
  | DeleteClientUserAction;

export const actionCreators = {
  setFetchParamClientUsers:
    ({
      page,
      pageSize,
      searchTerm,
    }: FetchParams): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const state = getState();
      if (state.clientUsers.isLoading) return;

      dispatch({
        type: "SETFETCHPARAMS_CLIENT_USERS",
        page,
        pageSize,
        searchTerm,
      });

      await dispatch(actionCreators.requestClientUsers() as any);
    },
  nextClientUserPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.clientUsers.isLoading) return;

      dispatch({
        type: "NEXTPAGE_CLIENT_USERS",
      });

      await dispatch(actionCreators.requestClientUsers() as any);
    },
  prevClientUserPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.clientUsers.isLoading) return;

      dispatch({
        type: "PREVPAGE_CLIENT_USERS",
      });

      await dispatch(actionCreators.requestClientUsers() as any);
    },

  requestClientUsers:
    (): AppThunkAction<KnownAction> => (dispatch, getState) => {
      const {
        clientUsers: {
          page,
          pageSize,
          searchTerm,
          clientUsers: curClientUsers,
        },
      } = getState();

      const fetchTask = apiClientFactory()
        .getConnectUsers(page, pageSize, searchTerm, undefined)
        .then(
          (data) => {
            dispatch({
              type: "RECEIVE_CLIENT_USERS",
              clientUsers: data,
              page,
              pageSize,
              searchTerm,
            });
          },
          (_error) => {
            dispatch({
              type: "RECEIVE_CLIENT_USERS",
              clientUsers: curClientUsers,
              page,
              pageSize,
              searchTerm,
            });
          }
        );

      dispatch({
        type: "REQUEST_CLIENT_USERS",
      });
      return fetchTask;
    },

  registerClientUser:
    (info: RegisterClientUserActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const {
        me: { user },
      } = getState();
      const json = await apiClientFactory().postAuth0User({
        tenantExternalId: getExternalTenantId(user),
        name: info.name,
        email: info.email,
        role: info.role,
      });

      return json;
    },

  registerBackendUser:
    (info: RegisterBackendUserActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const {
        me: { user },
      } = getState();
      const request = {
        ...info,
        tenantExternalId: getExternalTenantId(user),
      };
      const json = await apiClientFactory().postBackendUser(request);
      return json;
    },

  deleteClientUser:
    (info: DeleteClientUserActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      await apiClientFactory().deleteConnectUser(info.id);

      await dispatch(actionCreators.requestClientUsers() as any);
    },
};

const unloadedState: ClientUsersState = {
  clientUsers: [],
  isLoading: false,
  page: 0,
  pageSize: defaultPageSize,
  searchTerm: "",
};

export const reducer: Reducer<ClientUsersState> = (
  state: ClientUsersState = unloadedState,
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "REQUEST_CLIENT_USERS":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVE_CLIENT_USERS":
      return {
        ...state,
        clientUsers: action.clientUsers,
        isLoading: false,
        page: action.page,
        pageSize: action.pageSize,
        searchTerm: action.searchTerm,
      };

    case "SETFETCHPARAMS_CLIENT_USERS":
      return reduceSetFetchParams(state.isLoading, action, state);
    case "NEXTPAGE_CLIENT_USERS":
      return reduceNextPage(state.clientUsers, state.isLoading, state);
    case "PREVPAGE_CLIENT_USERS":
      return reducePrevPage(state.isLoading, state);

    case "REGISTER_CLIENT_USER":
    case "DELETE_CLIENT_USER":
    default:
      return state;
  }
};
