import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as SiteUsers from "../../store/SiteUsers";
import { confirmPrompt } from "../../helpers";
import { SiteUserResponse } from "../../api/ApiClient";
import SelectSiteUser from "../shared/SelectSiteUser";

type OwnProps = RouteComponentProps<{ startDateIndex: string }>;

interface Injected {}
type Props = Injected & typeof SiteUsers.actionCreators & OwnProps;

interface State {
  inProgress: Object | null;
}

class AdminSiteUsers extends React.Component<Props, State> {
  state: State = {
    inProgress: null,
  };

  deleteSiteUser = (siteUser: SiteUserResponse) => {
    confirmPrompt({
      promptMessage: "Are you sure you want to delete this user?",
      onConfirm: async () => {
        try {
          await this.props.deleteSiteUser({
            id: siteUser.id || "",
          });
          await this.props.requestSiteUsers();
        } catch (e) {
          alert("Error deleting site user: " + JSON.stringify(e));
        }
      },
    });
  };

  render() {
    return (
      <div>
        <p />
        {
          //@ts-ignore
          <SelectSiteUser
            pageSize={5}
            onSelected={this.deleteSiteUser}
            unselectedLabel="Delete"
            unselectedBtnClass="btn-danger"
          />
        }
      </div>
    );
  }
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    locks: state.locks,
    sites: state.sites,
    siteUsers: state.siteUsers,
  }),
  {
    ...SiteUsers.actionCreators,
  }
)(AdminSiteUsers);
