import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as RequestMeState from "../../store/Me";
import * as Auth0State from "../../store/Auth0";
import { UserTenantResponse } from "../../api/ApiClient";
import { Spinner } from "../shared/Spinner";
import Modal from "../shared/Modal";
import { getCurrentTenantName } from "../../helpers";

interface Injected {
  auth0: Auth0State.Auth0State;
  me: RequestMeState.MeState;
}

type Props = RouteComponentProps &
  Injected &
  typeof RequestMeState.actionCreators;

interface State {
  inProgress: boolean;
  switchInProgress: boolean;
  showModal: boolean;
}

class TenantPicker extends React.Component<Props, State> {
  state: State = {
    inProgress: false,
    showModal: false,
    switchInProgress: false,
  };

  componentDidMount() {
    this.fetchMeUser();
  }

  componentDidUpdate(prevProps: Props) {
    const {
      auth0: {
        session: { accessToken: token },
      },
    } = this.props;
    const {
      auth0: {
        session: { accessToken: prevToken },
      },
    } = prevProps;

    if (token && token !== prevToken) {
      this.fetchMeUser();
    }
  }

  fetchMeUser = () => {
    if (
      !this.props.auth0 ||
      !this.props.auth0.session ||
      !this.props.auth0.session.accessToken
    ) {
      return;
    }

    this.setState({ inProgress: true }, async () => {
      try {
        await this.props.requestUserMe();
      } catch (e) {
        alert("Error fetching user info: " + JSON.stringify(e));
      } finally {
        this.setState({
          inProgress: false,
        });
      }
    });
  };

  switchTenant = async (tenantId: string) => {
    if (!this.props.me.user || !this.props.me.user.id) return;
    try {
      this.setState({ switchInProgress: true });
      await this.props.switchTenant({
        userId: this.props.me.user.id,
        tenantId,
      });
      // refresh to re-load with changed Auth0 tenant claim
    } catch (e) {
      alert("Error switching to tenant: " + JSON.stringify(e));
    } finally {
      this.setState({ switchInProgress: false, showModal: false });
      this.props.history.push("/");
    }
  };

  public render() {
    if (this.state.inProgress || !this.props.me.user) {
      return <Spinner />;
    }
    return (
      <React.Fragment>
        <button
          className="btn btn-sm btn-primary"
          style={{ marginRight: 7 }}
          onClick={() => this.setState({ showModal: true })}
        >
          Switch
        </button>
        {getCurrentTenantName(this.props.me.user)}
        {this.renderModal(
          this.props.me.user.tenants || [],
          this.props.me.user.activeTenantId
        )}
      </React.Fragment>
    );
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  private renderModal(
    tenants: UserTenantResponse[],
    currentTenantClientId: string | undefined
  ) {
    const { showModal, switchInProgress } = this.state;
    return (
      <Modal
        show={showModal}
        onHide={this.closeModal}
        aria-labelledby="ModalHeader"
      >
        <Modal.Header closeButton>
          <Modal.Title id="ModalHeader">Select Tenant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {switchInProgress && <Spinner />}
          {this.renderTenantsTable(tenants, currentTenantClientId)}
        </Modal.Body>
        <Modal.Footer>
          <Modal.Dismiss className="btn btn-default">Close</Modal.Dismiss>
        </Modal.Footer>
      </Modal>
    );
  }

  private renderTenantsTable(
    tenants: UserTenantResponse[],
    currentTenantClientId: string | undefined
  ) {
    return (
      <div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th />
              <th>Id</th>
              <th>External Id</th>
              <th>Name</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {tenants.map((tenant, i) => (
              <tr key={"f_" + i}>
                <td style={{ width: 100 }}>
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={
                      tenant.tenant &&
                      tenant.tenant.id === currentTenantClientId
                    }
                    onClick={(e) => this.switchTenant(tenant.tenantId || "")}
                  >
                    Select
                  </button>
                </td>
                <td>{tenant.tenant ? tenant.tenant.id : ""}</td>
                <td>{tenant.tenant ? tenant.tenant.externalId : ""}</td>
                <td>{tenant.tenant ? tenant.tenant.name : ""}</td>
                <td>{tenant.role}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default connect<any, any, {}, ApplicationState>(
  (state) => ({
    auth0: state.auth0,
    me: state.me,
  }),
  {
    ...RequestMeState.actionCreators,
  }
)(TenantPicker);
