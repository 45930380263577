import { Action, Reducer } from "redux";
import { AppThunkAction } from "../store";
import {
  FetchParams,
  reduceSetFetchParams,
  defaultPageSize,
  reduceNextPage,
  reducePrevPage,
  apiClientFactory,
} from "../helpers";
import { GuestInviteResponse } from "../api/ApiClient";

export interface AllInvitesState extends FetchParams {
  isLoading: boolean;
  allInvites: GuestInviteResponse[];
}

interface RequestKeyRequestsAction {
  type: "REQUEST_ALL_INVITES";
}

interface SetFetchParams extends FetchParams {
  type: "SETFETCHPARAMS_ALL_INVITES";
}

interface NextPage {
  type: "NEXTPAGE_ALL_INVITES";
}

interface PrevPage {
  type: "PREVPAGE_ALL_INVITES";
}

interface ReceiveKeyRequestsAction {
  type: "RECEIVE_ALL_INVITES";
  keyRequests: GuestInviteResponse[];
}

interface DeleteInvitePayload {
  id: string;
}
interface DeleteInviteAction extends DeleteInvitePayload {
  type: "DELETE_INVITE";
}

type KnownAction =
  | RequestKeyRequestsAction
  | ReceiveKeyRequestsAction
  | DeleteInviteAction
  | SetFetchParams
  | NextPage
  | PrevPage;

function createActionCreators({ fromMeMode } = { fromMeMode: false }) {
  const actionCreators = {
    deleteInvite:
      (info: DeleteInvitePayload): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        await apiClientFactory().deleteInvite(info.id);

        await dispatch(actionCreators.requestAllInvites() as any);
      },

    setFetchParamsAllInvites:
      ({
        page,
        pageSize,
        searchTerm,
      }: FetchParams): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        const state = getState();
        if (state.keyRequests.isLoading) return;

        dispatch({
          type: "SETFETCHPARAMS_ALL_INVITES",
          page,
          pageSize,
          searchTerm,
        });

        await dispatch(actionCreators.requestAllInvites() as any);
      },
    nextAllInvitesPage:
      (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const state = getState();
        if (state.keyRequests.isLoading) return;

        dispatch({
          type: "NEXTPAGE_ALL_INVITES",
        });

        await dispatch(actionCreators.requestAllInvites() as any);
      },
    prevAllInvitesPage:
      (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const state = getState();
        if (state.keyRequests.isLoading) return;

        dispatch({
          type: "PREVPAGE_ALL_INVITES",
        });

        await dispatch(actionCreators.requestAllInvites() as any);
      },

    requestAllInvites:
      (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const {
          allInvites: {
            page,
            pageSize,
            searchTerm,
            allInvites: curKeyRequests,
          },
        } = getState();

        const fetchTask1 = fromMeMode
          ? apiClientFactory().getFromMeInvites(page, pageSize, searchTerm)
          : apiClientFactory().getAllInvites(page, pageSize, searchTerm, "");
        const fetchTask = fetchTask1.then(
          (data) => {
            dispatch({
              type: "RECEIVE_ALL_INVITES",
              keyRequests: data,
            });
          },
          (_error) => {
            dispatch({
              type: "RECEIVE_ALL_INVITES",
              keyRequests: curKeyRequests,
            });
          }
        );

        dispatch({
          type: "REQUEST_ALL_INVITES",
        });
        return fetchTask;
      },
  };
  return actionCreators;
}

export const actionCreators = createActionCreators();
export const fromMeActionCreators = createActionCreators({
  fromMeMode: true,
});

const unloadedState: AllInvitesState = {
  allInvites: [],
  isLoading: false,
  page: 0,
  pageSize: defaultPageSize,
  searchTerm: "",
};

export const reducer: Reducer<AllInvitesState> = (
  state: AllInvitesState = unloadedState,
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "REQUEST_ALL_INVITES":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVE_ALL_INVITES":
      return {
        ...state,
        allInvites: action.keyRequests,
        isLoading: false,
      };

    case "SETFETCHPARAMS_ALL_INVITES":
      return reduceSetFetchParams(state.isLoading, action, state);
    case "NEXTPAGE_ALL_INVITES":
      return reduceNextPage(state.allInvites, state.isLoading, state);
    case "PREVPAGE_ALL_INVITES":
      return reducePrevPage(state.isLoading, state);

    case "DELETE_INVITE":
    default:
      return state;
  }
};
