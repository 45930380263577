import { useState } from "react";
import * as React from "react";
import { ApplicationState } from "../../store/index";
import { SitesState, SiteSyncingState } from "../../store/Sites";
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";

import { apiClientFactory, tzList } from "../../helpers/index";
import { SiteSystemType } from "api/ApiClient";
type Props = RouteComponentProps<{}> & {
  sites: SitesState;
};
interface RouteParams {
  siteId: string;
}

function SiteEditComponent(props: Props) {
  // Declare a new state variable, which we'll call "count"
  const history = useHistory();
  const { siteId } = useParams<RouteParams>();
  const site = props.sites.sites.find((e) => e.id === siteId);

  const [localSite, setLocalSite] = useState<SiteSyncingState>(
    site ?? {
      id: "",
      propertyExternalId: "",
      name: "",
      timeZone: "",
      username: "",
      password: "",
      sharedKey: "",
      sharedKeyName: "",
    }
  );

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let payload: any = {
      timeZone: localSite.timeZone,
      name: localSite.name,
      propertyExternalId: localSite.propertyExternalId,
    };
    if (localSite?.accessCode) {
      payload.accessCode = localSite.accessCode;
    }
    if (localSite?.proxyVersion) {
      payload.proxyVersion = localSite.proxyVersion;
    }

    if (localSite.proxyVersion === 2) {
      payload.password = localSite.password || "";
      payload.username = localSite.username || "";
      payload.sharedKey = localSite.sharedKey || "";
      payload.sharedKeyName = localSite.sharedKeyName || "";
    }
    try {
      await apiClientFactory().updateSite(siteId, payload);
    } catch (e) {
      //@ts-ignore
      alert("Error updatind site: " + e?.message);
    }

    history.push({
      pathname: `/admin/sites/`,
    });
  };

  return (
    <main className="container">
      <div className="panel">
        <form
          className="form"
          onSubmit={(e) => submit(e)}
          style={{ marginTop: "20px" }}
        >
          <div className="form-group">
            <label htmlFor="propertyExternalId">Property External Id</label>
            <input
              type="text"
              id="propertyExternalId"
              className="form-control"
              required
              value={localSite?.propertyExternalId}
              onChange={(e) =>
                setLocalSite({
                  ...localSite,
                  propertyExternalId: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="siteName">Site Name</label>
            <input
              type="text"
              id="siteName"
              className="form-control"
              required
              value={localSite?.name}
              onChange={(e) =>
                setLocalSite({ ...localSite, name: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="timeZone">Time Zone</label>
            <select
              id="timeZone"
              className="form-control"
              onChange={(e) =>
                setLocalSite({ ...localSite, timeZone: e.target.value })
              }
              value={localSite?.timeZone}
            >
              <option value=""></option>
              {Object.entries(tzList).map((e) => (
                <option key={e[0]} value={e[0]}>
                  {e[1]}
                </option>
              ))}
            </select>
          </div>
          {localSite.account &&
            localSite.account.systemType === SiteSystemType.SchlageEngage && (
              <div className="form-group">
                <label htmlFor="accessCode">Access code </label>
                <input
                  className="form-control"
                  name="accessCode"
                  placeholder="Access code"
                  maxLength={12}
                  value={localSite.accessCode}
                  onChange={(e) =>
                    setLocalSite({ ...localSite, accessCode: e.target.value })
                  }
                />
              </div>
            )}
          {localSite.account &&
            [SiteSystemType.Assa, SiteSystemType.SaltoSpace].includes(
              localSite.account.systemType!
            ) && (
              <>
                <div className="form-group">
                  <label htmlFor="proxyVersion">Proxy Version </label>
                  <input
                    className="form-control"
                    name="proxyVersion"
                    placeholder=">Proxy Version"
                    type="number"
                    min="1"
                    max="2"
                    step="1"
                    value={localSite.proxyVersion}
                    onChange={(e) => {
                      const inputValue = parseFloat(e.target.value);
                      if (
                        !isNaN(inputValue) &&
                        inputValue >= 1 &&
                        inputValue <= 2
                      ) {
                        setLocalSite({
                          ...localSite,
                          proxyVersion: inputValue,
                        });
                      }
                    }}
                  />
                </div>
                {localSite.proxyVersion === 2 && (
                  <>
                    <div className="form-group">
                      <label htmlFor="password">Update Proxy Username</label>
                      <input
                        className="form-control"
                        name="username"
                        placeholder="Proxy Username"
                        value={localSite.username}
                        onChange={(e) => {
                          setLocalSite({
                            ...localSite,
                            username: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Update Proxy Password</label>
                      <input
                        className="form-control"
                        name="password"
                        placeholder="Proxy Password"
                        value={localSite.password}
                        onChange={(e) => {
                          setLocalSite({
                            ...localSite,
                            password: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="sharedKey">Shared Key</label>
                      <input
                        className="form-control"
                        name="sharedKey"
                        placeholder="Shared Key"
                        value={localSite.sharedKey}
                        onChange={(e) => {
                          setLocalSite({
                            ...localSite,
                            sharedKey: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="sharedKeyName">Shared Key Name</label>
                      <input
                        className="form-control"
                        name="sharedKeyName"
                        placeholder="Shared Key Name"
                        value={localSite.sharedKeyName}
                        onChange={(e) => {
                          setLocalSite({
                            ...localSite,
                            sharedKeyName: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            )}

          <div className="form-group">
            <input
              type="submit"
              className="btn btn-block btn-primary"
              disabled={
                !site ||
                !localSite.name ||
                !localSite.timeZone ||
                !localSite.propertyExternalId
              }
              value="Update"
            />
          </div>
        </form>
      </div>
    </main>
  );
}

export const SiteEdit = connect<
  any,
  any,
  RouteComponentProps<{}>,
  ApplicationState
>((state) => ({
  sites: state.sites,
}))(SiteEditComponent);

export default SiteEdit;
