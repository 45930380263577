import * as React from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as AccessControlGroupsState from "../../store/AccessControlGroups";
import { AccessControlGroupResponse } from "../../api/ApiClient";
import SelectAccessControlGroup from "../shared/SelectAccessControlGroup";
import {
  RegisterAccessControlGroup,
  RegisterAccessControlGroupParams,
} from "../AccessControlGroups/Register";

import Modal from "react-bootstrap-modal";

import "./adminSites.css";
import { confirmPrompt } from "../../helpers";

type OwnProps = RouteComponentProps<{ startDateIndex: string }>;

interface Injected {}
type Props = Injected &
  typeof AccessControlGroupsState.actionCreators &
  OwnProps;

type ModalType = "accessControlGroup" | null;

interface State {
  inProgress: boolean;
  selectedAccessControlGroup: AccessControlGroupResponse | null;
  registerParams: RegisterAccessControlGroupParams | null;

  showingModal: ModalType;
}

class AdminAccessControlGroups extends React.Component<Props, State> {
  state: State = {
    inProgress: false,
    selectedAccessControlGroup: null,
    showingModal: null,
    registerParams: null,
  };

  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const registerParams =
      searchParams && searchParams.get("registerParams")
        ? JSON.parse(searchParams.get("registerParams") as string)
        : null;
    if (registerParams) {
      this.setState({ registerParams }, () =>
        this.showAddAccessControlGroupModal()
      );
    }
  }

  showAddAccessControlGroupModal = () => {
    this.setState({ showingModal: "accessControlGroup" });
  };

  closeModal = () => {
    this.setState({ showingModal: null });
  };

  onRegistered = async () => {
    this.closeModal();
    this.setState({ registerParams: null });
    this.props.requestAccessControlGroups();
  };

  renderRegisterAccessControlGroup = () => {
    const { registerParams } = this.state;
    return (
      <RegisterAccessControlGroup
        onRegistered={this.onRegistered}
        //@ts-ignore
        initialValues={registerParams}
      />
    );
  };

  public render() {
    const { showingModal } = this.state;

    const button = (
      <button
        type="button"
        className="btn btn-primary"
        onClick={this.showAddAccessControlGroupModal}
      >
        Add AccessControlGroup
      </button>
    );

    return (
      <div>
        <br />
        {button}
        <div>{this.renderAccessControlGroupsTable()}</div>

        <Modal
          show={!!showingModal}
          onHide={this.closeModal}
          aria-labelledby="ModalHeader"
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="ModalHeader">
              {showingModal === "accessControlGroup"
                ? "Add AccessControlGroup"
                : "?"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showingModal === "accessControlGroup"
              ? this.renderRegisterAccessControlGroup()
              : null}
          </Modal.Body>
          <Modal.Footer>
            <Modal.Dismiss className="btn btn-default">Close</Modal.Dismiss>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  deleteAccessControlGroup = (
    accessControlGroup: AccessControlGroupResponse
  ) => {
    confirmPrompt({
      promptMessage:
        "Are you sure you want to delete this access control group?",
      onConfirm: async () => {
        try {
          await this.props.deleteAccessControlGroup({
            id: accessControlGroup.id || "",
          });
          await this.props.requestAccessControlGroups();
        } catch (e) {
          alert("Error deleting group: " + JSON.stringify(e));
        }
      },
    });
  };

  private renderAccessControlGroupButton = (
    accessControlGroup: AccessControlGroupResponse
  ) => {
    return (
      <div>
        <div className="d-flex justify-between">
          <Link
            className={`btn btn-sm btn-default`}
            to={`/admin/accessControlGroups/${accessControlGroup.id}`}
          >
            Details
          </Link>
        </div>
        <p />
        <div className="d-flex justify-between"></div>
        <p />
        <button
          type="button"
          className={`btn btn-danger`}
          onClick={() => this.deleteAccessControlGroup(accessControlGroup)}
        >
          Delete
        </button>
      </div>
    );
  };

  private renderAccessControlGroupsTable() {
    return (
      <div>
        <p />
        &nbsp;
        <p />
        {
          //@ts-ignore
          <SelectAccessControlGroup
            pageSize={5}
            renderButton={this.renderAccessControlGroupButton}
          />
        }
        <hr />
      </div>
    );
  }
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    sites: state.sites,
  }),
  {
    ...AccessControlGroupsState.actionCreators,
  }
)(AdminAccessControlGroups);
