import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as AccessControlGroupsState from "../../store/AccessControlGroups";
import { AccessControlGroupType } from "../../api/ApiClient";
import * as Auth0State from "../../store/Auth0";
import { getExternalTenantId } from "../../helpers";
import { MeState } from "../../store/Me";

type RegisterOwnProps = {
  onRegistered: () => any;
  initialValues?: RegisterAccessControlGroupParams;
};

interface RegisterInjected {
  auth0: Auth0State.Auth0State;
  me: MeState;
}
type RegisterProps = RegisterInjected &
  typeof AccessControlGroupsState.actionCreators &
  RegisterOwnProps;

export interface RegisterAccessControlGroupParams {
  //create accessControlGroup

  externalId: string;
  tenantExternalId: string;
  propertyExternalId: string;
  unitNumber: string;
  type: AccessControlGroupType;
  name: string;
  description: string;
}

interface RegisterState extends RegisterAccessControlGroupParams {
  inProgress: boolean;
}

class RegisterComp extends React.Component<RegisterProps, RegisterState> {
  state: RegisterState = {
    inProgress: false,

    ...(this.props.initialValues || {
      //create accessControlGroup
      externalId: "",
      tenantExternalId: "",
      propertyExternalId: "",
      unitNumber: "",
      type: AccessControlGroupType.Regular,
      name: "",
      description: "",
    }),
  };

  register = (e: any) => {
    e.preventDefault();

    this.setState(
      {
        inProgress: true,
      },
      async () => {
        try {
          const json = (await this.props.registerAccessControlGroup({
            externalId: this.state.externalId,
            tenantExternalId: getExternalTenantId(this.props.me.user),
            propertyExternalId: this.state.propertyExternalId,
            unitNumber: this.state.unitNumber,
            accessControlGroupType: this.state.type,
            name: this.state.name,
            description: this.state.description,
          })) as any;
          alert(
            "Successfully registered accessControlGroup: " +
              JSON.stringify(json)
          );
          this.props.onRegistered();
          this.setState({
            //clear inputs
            externalId: "",
            tenantExternalId: "",
            propertyExternalId: "",
            unitNumber: "",
            type: AccessControlGroupType.Regular,
            name: "",
            description: "",
          });
        } catch (e) {
          alert("Error registering accessControlGroup: " + JSON.stringify(e));
        } finally {
          this.setState({
            inProgress: false,
          });
        }
      }
    );
  };

  render() {
    const {
      inProgress,
      externalId,
      type,
      propertyExternalId,
      unitNumber,
      name,
    } = this.state;

    return (
      <form>
        <div className="form-group">
          <label htmlFor="externalId">Unique Name *</label>
          <input
            className="form-control"
            name="externalId"
            placeholder="Unique Name"
            value={externalId}
            onChange={(e) => {
              this.setState({ externalId: e.target.value });
            }}
          />
          <p className="help-block">
            Enter any unique id you want, e.g. "building1_common_doors_1"
          </p>
        </div>

        <div className="form-group">
          <label htmlFor="name">Name *</label>
          <input
            className="form-control"
            name="name"
            placeholder="Name"
            value={name}
            onChange={(e) => {
              this.setState({ name: e.target.value });
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="systemType">Group Type *</label>
          <select
            className="form-control"
            name="groupType"
            placeholder="Group Type"
            value={type}
            onChange={(e) => {
              this.setState({ type: e.target.value as any });
            }}
          >
            <option value="regular">Regular</option>
            <option value="defaultForProperty">DefaultForProperty</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="propertyExternalId">
            Property Unique Name (optional)
          </label>
          <input
            className="form-control"
            name="propertyExternalId"
            placeholder="Property Unique Name"
            value={propertyExternalId}
            onChange={(e) => {
              this.setState({ propertyExternalId: e.target.value });
            }}
          />
          <p className="help-block">
            Enter property id matching external inbound system e.g. Livly
            propertyCode
          </p>
        </div>

        <div className="form-group">
          <label htmlFor="unitNumber">Unit Number (optional)</label>
          <input
            className="form-control"
            name="unitNumber"
            placeholder="Unit Number"
            value={unitNumber}
            onChange={(e) => {
              this.setState({ unitNumber: e.target.value });
            }}
          />
          <p className="help-block">
            Unit Number matching external inbound system
          </p>
        </div>

        <div>
          <button
            type="submit"
            disabled={!!inProgress}
            className="btn btn-primary"
            onClick={this.register}
          >
            Register AccessControlGroup
          </button>
        </div>
      </form>
    );
  }
}

export const RegisterAccessControlGroup = connect<
  any,
  any,
  RegisterOwnProps,
  ApplicationState
>(
  (state) => ({
    auth0: state.auth0,
    me: state.me,
  }),
  {
    ...AccessControlGroupsState.actionCreators,
  }
)(RegisterComp);
