import { useEffect, useState } from "react";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { apiClientFactory } from "../helpers";

import { ApiException, LockAutomationRequest } from "../api/ApiClient";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function LockAutomationEdit() {
  // Declare a new state variable, which we'll call "count"
  const [action, setAction] = useState<string | undefined>("pass");
  const [time, setTime] = useState<string | undefined>(undefined);
  const [sunday, setSunday] = useState<boolean>(true);
  const [monday, setMonday] = useState<boolean>(true);
  const [tuesday, setTuesday] = useState<boolean>(true);
  const [wednesday, setWednesday] = useState<boolean>(true);
  const [thursday, setThursday] = useState<boolean>(true);
  const [friday, setFriday] = useState<boolean>(true);
  const [saturday, setSaturday] = useState<boolean>(true);
  const [lockAutomationLoading, setlockAutomationLoading] =
    useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const location = useLocation();
  const { lockId } = useParams();
  const history = useHistory();

  const query = useQuery();
  const id = (function (): string {
    let t1 = query.get("id");
    t1 = t1 ? t1 : "";
    return t1;
  })();

  const fetchLockAutomations = function () {
    if (id !== "") {
      setlockAutomationLoading(true);
      apiClientFactory()
        .getLockAutomations(lockId)
        .then((r) => {
          const selectedAutomation = r.find((e) => e.id === id);
          if (selectedAutomation) {
            if (selectedAutomation.action) {
              setAction(selectedAutomation.action);
            }
            if (selectedAutomation.time) {
              setTime(
                `${Math.floor(selectedAutomation.time / 60)
                  .toString()
                  .padStart(2, "0")}:${(selectedAutomation.time % 60)
                  .toString()
                  .padStart(2, "0")}`
              );
            }
            setSunday(selectedAutomation.sunday ? true : false);
            setMonday(selectedAutomation.monday ? true : false);
            setTuesday(selectedAutomation.tuesday ? true : false);
            setWednesday(selectedAutomation.wednesday ? true : false);
            setThursday(selectedAutomation.thursday ? true : false);
            setFriday(selectedAutomation.friday ? true : false);
            setSaturday(selectedAutomation.saturday ? true : false);
          }
        })
        .catch((r) => setError(r))
        .finally(() => setlockAutomationLoading(false));
    }
  };

  const submit = async function (event: React.FormEvent) {
    setError("");
    event.preventDefault();
    setlockAutomationLoading(true);
    try {
      const [hourString, minuteString] = time ? time.split(":") : ["0", "0"];
      const hour = Number.parseInt(hourString);
      const minute = Number.parseInt(minuteString);
      const postTime = hour * 60 + minute;
      const payload: LockAutomationRequest = {
        action: action ? action : "",
        sunday: sunday,
        monday: monday,
        tuesday: tuesday,
        wednesday: wednesday,
        thursday: thursday,
        friday: friday,
        saturday: saturday,
        time: postTime,
      };
      if (id !== "") {
        await apiClientFactory().updateLockAutomation(lockId, id, payload);
      } else {
        await apiClientFactory().addLockAutomation(lockId, payload);
      }
      history.push({
        pathname: `/admin/locks/${lockId}/automation`,
      });
    } catch (error) {
      const apiError = error as ApiException;
      if (apiError) {
        setError(apiError.message);
      }
    }
    setlockAutomationLoading(false);
  };

  const progressStyle: React.CSSProperties = {
    width: "100%",
  };

  useEffect(() => {
    (async () => {
      fetchLockAutomations();
    })();
    // eslint-disable-next-line
  }, [location]);
  return (
    <main className="container">
      {error !== "" ? (
        <div className="alert alert-danger">{error}</div>
      ) : (
        <React.Fragment />
      )}
      {lockAutomationLoading ? (
        <div className="panel-body">
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped active"
              role="progressbar"
              aria-valuenow={100}
              style={progressStyle}
            >
              Loading
            </div>
          </div>
        </div>
      ) : (
        <div className="panel">
          <form className="form" onSubmit={(e) => submit(e)}>
            <div className="form-group">
              <label htmlFor="action">Action</label>
              <select
                id="action"
                className="form-control"
                onChange={(e) => setAction(e.target.value)}
                value={action}
              >
                <option value="pass">Pass</option>
                <option value="sec">Secure</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="time">Time</label>
              <input
                type="time"
                id="time"
                className="form-control"
                required
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
            </div>
            <label>Days</label>
            <label className="checkbox">
              <input
                type="checkbox"
                id="sunday"
                checked={sunday}
                onChange={(e) => setSunday(e.target.checked)}
              />
              Sunday
            </label>
            <label className="checkbox">
              <input
                type="checkbox"
                id="monday"
                checked={monday}
                onChange={(e) => setMonday(e.target.checked)}
              />
              Monday
            </label>
            <label className="checkbox">
              <input
                type="checkbox"
                id="tuesday"
                checked={tuesday}
                onChange={(e) => setTuesday(e.target.checked)}
              />
              Tuesday
            </label>
            <label className="checkbox">
              <input
                type="checkbox"
                id="wednesday"
                checked={wednesday}
                onChange={(e) => setWednesday(e.target.checked)}
              />
              Wednesday
            </label>
            <label className="checkbox">
              <input
                type="checkbox"
                id="thursday"
                checked={thursday}
                onChange={(e) => setThursday(e.target.checked)}
              />
              Thursday
            </label>
            <label className="checkbox">
              <input
                type="checkbox"
                id="friday"
                checked={friday}
                onChange={(e) => setFriday(e.target.checked)}
              />
              Friday
            </label>
            <label className="checkbox">
              <input
                type="checkbox"
                id="saturday"
                checked={saturday}
                onChange={(e) => setSaturday(e.target.checked)}
              />
              Saturday
            </label>
            <div className="form-group">
              <input
                type="submit"
                className="btn btn-block btn-primary"
                disabled={!time || time.length === 0}
                value={id !== "" ? "Update" : "Create"}
              />
            </div>
          </form>
        </div>
      )}
    </main>
  );
}
export default LockAutomationEdit;
