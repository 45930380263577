import { useEffect, useState } from "react";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { apiClientFactory } from "../helpers";

import { ApiException, LockHolidayRequest } from "../api/ApiClient";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function swedish8601(date: Date) {
  return date
    .toLocaleString("sv-SE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
    .replace(" ", "T");
}

function LockHolidayEdit() {
  // Declare a new state variable, which we'll call "count"
  const [action, setAction] = useState<string | undefined>("pass");

  const [startDateTime, setStartDateTime] = useState<string | undefined>(
    undefined
  );
  const [endDateTime, setEndDateTime] = useState<string | undefined>(undefined);
  const [lockHolidayLoading, setLockHolidayLoading] = useState(false);
  const location = useLocation();
  const { lockId } = useParams();
  const [error, setError] = useState<string>("");
  const query = useQuery();
  const history = useHistory();
  const id = (function (): string {
    let t1 = query.get("id");
    t1 = t1 ? t1 : "";
    return t1;
  })();

  const fetchLockHolidays = function () {
    const now = new Date();
    setStartDateTime(`${now.toISOString().slice(0, 10)}T00:00`);
    now.setDate(now.getDate() + 1);
    setEndDateTime(`${now.toISOString().slice(0, 10)}T00:00`);
    if (id !== "") {
      setLockHolidayLoading(true);
      apiClientFactory()
        .getLockHolidays(lockId)
        .then((r) => {
          const selectedHoliday = r.find((e) => e.id === id);
          if (selectedHoliday) {
            if (selectedHoliday.action) {
              setAction(selectedHoliday.action);
            }
            if (selectedHoliday.startDateTime) {
              setStartDateTime(
                swedish8601(new Date(`${selectedHoliday.startDateTime}Z`))
              );
            }
            if (selectedHoliday.endDateTime) {
              setEndDateTime(
                swedish8601(new Date(`${selectedHoliday.endDateTime}Z`))
              );
            }
          }
        })
        .catch((r) => setError(r))
        .finally(() => setLockHolidayLoading(false));
    }
  };

  const submit = async function (event: React.FormEvent) {
    setError("");
    event.preventDefault();
    setLockHolidayLoading(true);
    try {
      const payload: LockHolidayRequest = {
        action: action ? action : "",
        startDateTime: startDateTime ? new Date(startDateTime) : undefined,
        endDateTime: endDateTime ? new Date(endDateTime) : undefined,
      };
      if (id !== "") {
        await apiClientFactory().updateLockHoliday(lockId, id, payload);
      } else {
        await apiClientFactory().addLockHoliday(lockId, payload);
      }
      history.push({
        pathname: `/admin/locks/${lockId}/automation`,
      });
    } catch (error) {
      const apiError = error as ApiException;
      if (apiError) {
        setError(apiError.message);
      }
    }
    setLockHolidayLoading(false);
  };

  const progressStyle: React.CSSProperties = {
    width: "100%",
  };

  useEffect(() => {
    (async () => {
      fetchLockHolidays();
    })();
    // eslint-disable-next-line
  }, [location]);
  return (
    <main className="container">
      {error !== "" ? (
        <div className="alert alert-danger">{error}</div>
      ) : (
        <React.Fragment />
      )}
      {lockHolidayLoading ? (
        <div className="panel-body">
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped active"
              role="progressbar"
              aria-valuenow={100}
              style={progressStyle}
            >
              Loading
            </div>
          </div>
        </div>
      ) : (
        <div className="panel">
          <form className="form" onSubmit={(e) => submit(e)}>
            <div className="form-group">
              <label htmlFor="action">Action</label>
              <select
                id="action"
                className="form-control"
                onChange={(e) => setAction(e.target.value)}
                value={action}
              >
                <option value="pass">Pass</option>
                <option value="sec">Secure</option>
                <option value="rstrctSec">Restricted Secure</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="startDateTime">Start Date & Time</label>
              <input
                type="datetime-local"
                id="startDateTime"
                className="form-control"
                required
                value={startDateTime}
                onChange={(e) => setStartDateTime(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="endDateTime">End Date & Time</label>
              <input
                type="datetime-local"
                id="endDateTime"
                className="form-control"
                required
                value={endDateTime}
                onChange={(e) => setEndDateTime(e.target.value)}
              />
            </div>

            <div className="form-group">
              <input
                type="submit"
                className="btn btn-block btn-primary"
                disabled={!startDateTime || !endDateTime || !action}
                value={id !== "" ? "Update" : "Create"}
              />
            </div>
          </form>
        </div>
      )}
    </main>
  );
}
export default LockHolidayEdit;
