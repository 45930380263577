import { useQuery } from "@tanstack/react-query";
import { SiteSystemType } from "api/ApiClient";
import axios from "axios";

import { BASE_API_URL } from "store/Me";

export interface BaseLivlyApiResponse<DataType> {
  Data: DataType;
  Errors: object;
  Message: string;
  Succeeded: boolean;
}

export interface accountInterface {
  accountCredentials: accountCredentialInterface;
  id: string;
  name: string;
  systemType: SiteSystemType;
  tenantId: string;
}

export interface accountCredentialInterface {
  accessToken: string;
  apiUrl: string;
  clientId: string;
  clientId2?: string;
  clientSecret: string;
  clientSecret2?: string;
  id: string;
  password: string;
  refreshToken: string;
  tokenUrl: string;
  username: string;
}

export default function useGetAccountById(id: string) {
  const getAccount = async () => {
    const result = await axios.get<BaseLivlyApiResponse<accountInterface>>(
      `${BASE_API_URL}/api/Accounts/${id}`
    );

    return result.data;
  };

  return useQuery(["account", id], getAccount);
}
