import { useQuery } from "@tanstack/react-query";
import { SiteSystemType } from "api/ApiClient";
import axios from "axios";

import { BASE_API_URL } from "store/Me";

export interface BaseLivlyApiResponse<DataType> {
  Data: DataType;
  Errors: object;
  Message: string;
  Succeeded: boolean;
}

export default function useGetSystemTypes() {
  const getSystemTypes = async () => {
    const result = await axios.get<BaseLivlyApiResponse<SiteSystemType[]>>(
      `${BASE_API_URL}/api/Admin/systemTypes`
    );

    return result.data;
  };

  return useQuery(["system-types"], getSystemTypes);
}
