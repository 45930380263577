import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as AccessControlGroupsState from "../../store/AccessControlGroups";
import { PageControl } from "./PageControl";
import { debounce } from "lodash";
import { defaultPageSize } from "../../helpers";
import { AccessControlGroupResponse } from "../../api/ApiClient";
import { Spinner } from "./Spinner";

interface Injected {
  accessControlGroups: AccessControlGroupsState.AccessControlGroupsState;
}

type OwnProps = {
  initialTyped?: string;
  onSelected: (site: AccessControlGroupResponse) => any;
  pageSize?: number;
  showFirst?: number | null | undefined;
  selectedAccessControlGroups?: Array<AccessControlGroupResponse>;
  renderButton?: (site: AccessControlGroupResponse) => any;
  selectedLabel?: string;
  selectedBtnClass?: string;
  unselectedLabel?: string;
  unselectedBtnClass?: string;
  btnStyle?: Object;
};

type Props = Injected &
  typeof AccessControlGroupsState.actionCreators &
  OwnProps;

interface State {
  typedAccount: string;
}

class SelectAccessControlGroup extends React.Component<Props, State> {
  state: State = {
    typedAccount: this.props.initialTyped || "",
  };

  componentDidMount() {
    console.log("SelectAccessControlGroup: Requesting accessControlGroups...");
    this.refresh();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.pageSize !== prevProps.pageSize) {
      this.refresh();
    }
  }

  refresh = async () => {
    try {
      const { pageSize } = this.props;
      const { typedAccount: searchTerm } = this.state;
      await this.props.setFetchParamAccessControlGroups({
        page: 0,
        pageSize: pageSize || defaultPageSize,
        searchTerm,
      });
    } catch (e) {
      console.error("Error requesting accessControlGroups", e);
      alert("Error requesting accessControlGroups: " + JSON.stringify(e));
    }
  };

  handleTypedAccount = (value: string) => {
    this.setState({ typedAccount: value }, this.searchAccount);
  };

  searchAccount = debounce(this.refresh, 1000);

  public render() {
    const { accessControlGroups, searchTerm, isLoading } =
      this.props.accessControlGroups;

    const { typedAccount } = this.state;

    return (
      <div>
        <div className="form-group">
          <label htmlFor="typedAccount">
            <span aria-label="search" role="img">
              🔎
            </span>{" "}
            Search For AccessControlGroup
          </label>
          <input
            className="form-control"
            name="typedAccount"
            placeholder="type here"
            value={typedAccount}
            onChange={(e) => this.handleTypedAccount(e.target.value)}
          />
        </div>
        <h4>
          {searchTerm
            ? `AccessControlGroups Matching '${searchTerm}'`
            : "AccessControlGroups"}
          {isLoading && <Spinner />}
        </h4>
        {this.renderAccessControlGroupsTable(accessControlGroups)}
      </div>
    );
  }

  private defaultRenderButton = (
    accessControlGroup: AccessControlGroupResponse
  ) => {
    const {
      selectedAccessControlGroups,
      onSelected,
      btnStyle,
      selectedBtnClass,
      selectedLabel,
      unselectedBtnClass,
      unselectedLabel,
    } = this.props;
    const isSelected =
      selectedAccessControlGroups &&
      selectedAccessControlGroups.find((a) => a.id === accessControlGroup.id);
    const className = isSelected
      ? selectedBtnClass || "btn-primary"
      : unselectedBtnClass || "btn-default";
    return (
      <button
        type="button"
        className={`btn ${className}`}
        style={btnStyle}
        onClick={() => onSelected(accessControlGroup)}
      >
        {!isSelected
          ? unselectedLabel || "Select"
          : selectedLabel || "Unselect"}
      </button>
    );
  };

  private renderAccessControlGroupsTable(
    accessControlGroups: AccessControlGroupResponse[]
  ) {
    const { renderButton, showFirst } = this.props;
    const { isLoading } = this.props.accessControlGroups;
    accessControlGroups = !showFirst
      ? accessControlGroups
      : (accessControlGroups || []).slice(0, showFirst);

    return (
      <div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th />
              <th>Name</th>
              <th>ExternalId</th>
              <th>propertyExternalId</th>
              <th>UnitNumber</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {accessControlGroups.map((accessControlGroup, i) => (
              <tr key={"f_" + i}>
                <td style={{ width: 100 }}>
                  {renderButton
                    ? renderButton(accessControlGroup)
                    : this.defaultRenderButton(accessControlGroup)}
                </td>
                <td>{accessControlGroup.name}</td>
                <td>{accessControlGroup.externalId}</td>
                <td>{accessControlGroup.propertyExternalId}</td>
                <td>{accessControlGroup.unitNumber}</td>
                <td>{accessControlGroup.type}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {!showFirst && (
          <PageControl
            onNext={this.props.nextAccessControlGroupPage}
            onPrev={this.props.prevAccessControlGroupPage}
            currentPage={this.props.accessControlGroups.page}
            isLoading={isLoading}
            disablePrev={this.props.accessControlGroups.page === 0}
            disableNext={
              this.props.accessControlGroups.accessControlGroups.length <
              this.props.accessControlGroups.pageSize
            }
          />
        )}
      </div>
    );
  }
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    accessControlGroups: state.accessControlGroups,
  }),
  { ...AccessControlGroupsState.actionCreators }
)(SelectAccessControlGroup);
