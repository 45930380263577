import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";
import {
  FetchParams,
  reduceSetFetchParams,
  defaultPageSize,
  reduceNextPage,
  reducePrevPage,
  apiClientFactory,
} from "../helpers";

import { SiteUserResponse } from "../api/ApiClient";

export interface SiteUsersState extends FetchParams {
  isLoading: boolean;
  siteUsers: SiteUserResponse[];
}

interface RequestSiteUsersAction {
  type: "REQUEST_SITEUSERS";
}

interface SetFetchParams extends FetchParams {
  type: "SETFETCHPARAMS_SITEUSERS";
}

interface NextPage {
  type: "NEXTPAGE_SITEUSERS";
}

interface PrevPage {
  type: "PREVPAGE_SITEUSERS";
}

interface ReceiveSiteUsersAction extends FetchParams {
  type: "RECEIVE_SITEUSERS";
  sites: SiteUserResponse[];
}

interface InviteAssaUserActionPayload {
  phone: string;
  siteId: string;
  clientUserId: string;
}

interface InviteAssaUserAction extends InviteAssaUserActionPayload {
  type: "INVITE_ASSA_USER";
}

interface RegisterOpenPathUserActionPayload {
  credentialId: string;
  systemUserId: string;
  siteId: string;
  clientUserId: string;
}
interface RegisterOpenPathUserAction extends RegisterOpenPathUserActionPayload {
  type: "REGISTER_OPENPATH_USER";
}

interface RegisterSiteUserPayload {
  credentialId: string;
  systemUserId: string;
  siteId: string;
  clientUserId: string;
}

interface RegisterButterflyUserActionPayload {
  systemUserId: string;
  siteId: string;
  clientUserId: string;
}
interface RegisterButterflyUserAction
  extends RegisterButterflyUserActionPayload {
  type: "REGISTER_BUTTERFLY_USER";
}

interface DeleteSiteUserActionPayload {
  id: string;
}
interface DeleteSiteUserAction extends DeleteSiteUserActionPayload {
  type: "DELETE_SITEUSER";
}

type KnownAction =
  | RequestSiteUsersAction
  | SetFetchParams
  | NextPage
  | PrevPage
  | ReceiveSiteUsersAction
  | InviteAssaUserAction
  | RegisterOpenPathUserAction
  | RegisterButterflyUserAction
  | DeleteSiteUserAction;

export const actionCreators = {
  setFetchParamsSiteUsers:
    ({
      page,
      pageSize,
      searchTerm,
    }: FetchParams): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const state = getState();
      if (state.siteUsers.isLoading) return;

      dispatch({
        type: "SETFETCHPARAMS_SITEUSERS",
        page,
        pageSize,
        searchTerm,
      });

      await dispatch(actionCreators.requestSiteUsers() as any);
    },
  nextSiteUserPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.siteUsers.isLoading) return;

      dispatch({
        type: "NEXTPAGE_SITEUSERS",
      });

      await dispatch(actionCreators.requestSiteUsers() as any);
    },
  prevSiteUserPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.siteUsers.isLoading) return;

      dispatch({
        type: "PREVPAGE_SITEUSERS",
      });

      await dispatch(actionCreators.requestSiteUsers() as any);
    },

  requestSiteUsers: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const {
      siteUsers: { page, pageSize, searchTerm, siteUsers: curSiteUsers },
    } = getState();

    const fetchTask = apiClientFactory()
      .getSiteUsers(page, pageSize, searchTerm, undefined)
      .then(
        (data) => {
          dispatch({
            type: "RECEIVE_SITEUSERS",
            sites: data,
            page,
            pageSize,
            searchTerm,
          });
        },
        (_error) => {
          dispatch({
            type: "RECEIVE_SITEUSERS",
            sites: curSiteUsers,
            page,
            pageSize,
            searchTerm,
          });
        }
      );

    dispatch({
      type: "REQUEST_SITEUSERS",
    });
    return fetchTask;
  },

  inviteAssaUser:
    (info: InviteAssaUserActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const json = await apiClientFactory().postSiteUserAssa({
        phone: info.phone,
        siteId: info.siteId,
        connectUserExternalId: info.clientUserId,
      });

      return json;
    },

  registerOpenPathUser:
    (info: RegisterOpenPathUserActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const json = await apiClientFactory().postSiteUserOpenPath({
        credentialId: info.credentialId,
        systemUserId: info.systemUserId,
        siteId: info.siteId,
        connectUserExternalId: info.clientUserId,
      });

      return json;
    },

  registerSiteUser:
    (info: RegisterSiteUserPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const json = await apiClientFactory().postSiteUser({
        credentialId: info.credentialId,
        systemUserId: info.systemUserId,
        siteId: info.siteId,
        connectUserExternalId: info.clientUserId,
      });

      return json;
    },

  registerButterflyUser:
    (info: RegisterButterflyUserActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const json = await apiClientFactory().postSiteUserButterfly({
        systemUserId: info.systemUserId,
        siteId: info.siteId,
        connectUserExternalId: info.clientUserId,
      });

      return json;
    },

  deleteSiteUser:
    (info: DeleteSiteUserActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      await apiClientFactory().deleteSiteUser(info.id);

      await dispatch(actionCreators.requestSiteUsers() as any);
    },
};

const unloadedState: SiteUsersState = {
  siteUsers: [],
  isLoading: false,
  page: 0,
  pageSize: defaultPageSize,
  searchTerm: "",
};

export const reducer: Reducer<SiteUsersState> = (
  state: SiteUsersState = unloadedState,
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "REQUEST_SITEUSERS":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVE_SITEUSERS":
      return {
        ...state,
        siteUsers: action.sites,
        isLoading: false,
        page: action.page,
        pageSize: action.pageSize,
        searchTerm: action.searchTerm,
      };

    case "SETFETCHPARAMS_SITEUSERS":
      return reduceSetFetchParams(state.isLoading, action, state);
    case "NEXTPAGE_SITEUSERS":
      return reduceNextPage(state.siteUsers, state.isLoading, state);
    case "PREVPAGE_SITEUSERS":
      return reducePrevPage(state.isLoading, state);

    case "INVITE_ASSA_USER":
    case "REGISTER_OPENPATH_USER":
    case "REGISTER_BUTTERFLY_USER":
    case "DELETE_SITEUSER":
    default:
      return state;
  }
};
