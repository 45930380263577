import * as Locks from "./Locks";
import * as Sites from "./Sites";
import * as Accounts from "./Accounts";
import * as AccessControlGroups from "./AccessControlGroups";
import * as SharedAccountCredentials from "./SharedAccountCredentials";
import * as Screens from "./Screens";
import * as ClientUsers from "./ClientUsers";
import * as SiteUsers from "./SiteUsers";
import * as KeyRequests from "./KeyRequests";
import * as GuestKeyRequests from "./GuestKeyRequests";
import * as ScreenInvites from "./ScreenInvites";
import * as AllInvites from "./AllInvites";
import * as AllScreenInvites from "./AllScreenInvites";
import * as Auth0 from "./Auth0";
import * as Me from "./Me";
import * as Tenants from "./Tenants";

// The top-level state object
export interface ApplicationState {
  locks: Locks.LocksState;
  sites: Sites.SitesState;
  accounts: Accounts.AccountsState;
  accessControlGroups: AccessControlGroups.AccessControlGroupsState;
  sharedAccountCredentials: SharedAccountCredentials.SharedAccountCredentialsState;
  screens: Screens.ScreensState;
  clientUsers: ClientUsers.ClientUsersState;
  siteUsers: SiteUsers.SiteUsersState;
  keyRequests: KeyRequests.KeyRequestsState;
  guestKeyRequests: GuestKeyRequests.GuestKeyRequestsState;
  screenInvites: ScreenInvites.ScreenInvitesState;
  allInvites: AllInvites.AllInvitesState;
  allScreenInvites: AllScreenInvites.AllScreenInvitesState;
  auth0: Auth0.Auth0State;
  me: Me.MeState;
  tenants: Tenants.TenantsState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  accounts: Accounts.reducer,

  accessControlGroups: AccessControlGroups.reducer,
  sharedAccountCredentials: SharedAccountCredentials.reducer,
  screens: Screens.reducer,
  clientUsers: ClientUsers.reducer,
  locks: Locks.reducer,
  sites: Sites.reducer,
  siteUsers: SiteUsers.reducer,
  keyRequests: KeyRequests.reducer,
  guestKeyRequests: GuestKeyRequests.reducer,
  screenInvites: ScreenInvites.reducer,
  allInvites: AllInvites.reducer,
  allScreenInvites: AllScreenInvites.reducer,
  auth0: Auth0.reducer,
  me: Me.reducer,
  tenants: Tenants.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
