import { WebAuth } from "auth0-js";
import axios from "axios";

export const AUTHORIZATION_HEADER_KEY = "Authorization";

export class Auth0 {
  auth0: WebAuth;

  constructor(config: {
    appBaseUrl: string;
    auth0Domain: string;
    auth0ClientId: string;
    auth0Audience: string;
  }) {
    this.auth0 = new WebAuth({
      domain: config.auth0Domain,
      clientID: config.auth0ClientId,
      redirectUri: `${window.location.origin}/callback`,
      audience: config.auth0Audience,
      responseType: "token id_token",
      scope:
        "openid create:keyrequests create:invites onboard:objects onboard:screens",
    });
  }

  checkSession = () => {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) {
          reject(err);
        } else {
          axios.defaults.headers.common[
            AUTHORIZATION_HEADER_KEY
          ] = `Bearer ${authResult.accessToken}`;
          resolve(authResult);
        }
      });
    });
  };

  logout = (returnTo?: string) => {
    axios.defaults.headers.common[AUTHORIZATION_HEADER_KEY] = undefined;
    this.auth0.logout(returnTo ? { returnTo } : {});
  };

  checkCallbackForAuthInfo = (location: { hash: any }) => {
    if (/access_token|id_token|error/.test(location.hash)) {
      return new Promise((resolve, reject) => {
        this.auth0.parseHash((err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            resolve(authResult);
          } else if (err) {
            reject(err);
          }
        });
      });
    }
  };

  getUserInfo = (accessToken: string) => {
    return new Promise((resolve, reject) => {
      this.auth0.client.userInfo(accessToken, function (err, user) {
        if (user) {
          resolve(user);
        } else if (err) {
          reject(err);
        }
      });
    });
  };

  signInWithPhone = () => {
    // the custom Universal login template in Auth0 tenant causes this to work as sms passwordless
    this.auth0.authorize();
  };

  signInWithEmail = () => {
    // the customer Universal login template requires this connection, otherwise sms passwordless will be used
    this.auth0.authorize({ connection: "Username-Password-Authentication" });
  };
}
