import * as React from "react";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { Auth0 as Auth0Service } from "../../services/Auth0";
import * as MeState from "../../store/Me";
import * as Auth0State from "../../store/Auth0";
import { LoginForm } from "./LoginForm";

// This component
// 0. requests "me" data from server (for getting appBaseUrl)
// 1. watches appBaseUrl from redux, creates Auth0Service and pushes it redux
// 2. pulls auth0 session and pushes it to redux

interface Injected {
  me: MeState.MeState;
  auth0: Auth0State.Auth0State;
}

type OwnProps = {
  shouldRenderLogin: boolean;
  isForTenantUser: boolean;
};

type Props = Injected &
  typeof MeState.actionCreators &
  typeof Auth0State.actionCreators &
  OwnProps;

class _Auth0 extends React.Component<Props> {
  componentDidMount() {
    this.props.requestMe();
    this.updateAuth0Service();
  }

  componentDidUpdate(prevProps: Props) {
    const appBaseUrl = this.props.me && this.props.me.appBaseUrl;
    const prevAppBaseUrl = prevProps.me && prevProps.me.appBaseUrl;
    if (appBaseUrl && appBaseUrl !== prevAppBaseUrl) {
      this.updateAuth0Service();
    }
  }

  updateAuth0Service = async () => {
    const { me } = this.props;
    if (
      me &&
      me.appBaseUrl &&
      me.auth0Audience &&
      me.auth0ClientId &&
      me.auth0Domain
    ) {
      const auth0 = new Auth0Service({
        appBaseUrl: me.appBaseUrl,
        auth0Audience: me.auth0Audience,
        auth0ClientId: me.auth0ClientId,
        auth0Domain: me.auth0Domain,
      });
      this.props.setAuth0Service({ service: auth0 });

      try {
        console.log("checking session...");
        const authResult = await auth0.checkSession();
        console.log("checking session done: ", { authResult });
        if (authResult) {
          this.setAuth0Session(authResult);
        } else {
          this.clearAuth0Session();
        }
      } catch (e) {
        this.clearAuth0Session();
      }
    }
  };

  setAuth0Session = (authResult: any) => {
    this.props.setAuth0Session({
      session: { ...authResult, isLoading: false },
    });
  };

  private clearAuth0Session() {
    this.props.setAuth0Session({
      session: {
        accessToken: "",
        idToken: "",
        expiresAt: 0,
        isLoading: false,
      },
    });
  }

  public render() {
    const {
      shouldRenderLogin,
      isForTenantUser,
      auth0: { session },
      me: { user },
    } = this.props;
    if (session && session.isLoading) {
      return null;
    }

    const isLoggedIn = !!session && !!session.accessToken;

    const isTenantUser =
      user !== undefined &&
      user.activeTenantId !== undefined &&
      user.activeTenantId !== null;
    const shouldRenderChildren = isLoggedIn && isTenantUser === isForTenantUser;
    if (shouldRenderChildren) {
      return this.props.children;
    }

    if (!isLoggedIn && shouldRenderLogin) {
      return (
        <div>
          <h3>Log In</h3>
          <LoginForm />
        </div>
      );
    }

    return <div />;
  }
}

export const Auth0: any = connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    me: state.me,
    auth0: state.auth0,
  }),
  {
    ...Auth0State.actionCreators,
    ...MeState.actionCreators,
  }
)(_Auth0);
