import { Action, Reducer } from "redux";
import { AppThunkAction } from "../store";
import {
  FetchParams,
  reduceSetFetchParams,
  defaultPageSize,
  reduceNextPage,
  reducePrevPage,
  apiClientFactory,
} from "../helpers";
import { ScreenInviteResponse } from "../api/ApiClient";

export interface AllScreenInvitesState extends FetchParams {
  isLoading: boolean;
  allScreenInvites: ScreenInviteResponse[];
}

interface RequestKeyRequestsAction {
  type: "REQUEST_ALL_SCREEN_INVITES";
}

interface SetFetchParams extends FetchParams {
  type: "SETFETCHPARAMS_ALL_SCREEN_INVITES";
}

interface NextPage {
  type: "NEXTPAGE_ALL_SCREEN_INVITES";
}

interface PrevPage {
  type: "PREVPAGE_ALL_SCREEN_INVITES";
}

interface ReceiveKeyRequestsAction {
  type: "RECEIVE_ALL_SCREEN_INVITES";
  screenInvites: ScreenInviteResponse[];
}

interface DeleteInvitePayload {
  id: string;
}
interface DeleteInviteAction extends DeleteInvitePayload {
  type: "DELETE_SCREEN_INVITE";
}

type KnownAction =
  | RequestKeyRequestsAction
  | ReceiveKeyRequestsAction
  | DeleteInviteAction
  | SetFetchParams
  | NextPage
  | PrevPage;

export const actionCreators = {
  deleteScreenInvite:
    (info: DeleteInvitePayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      await apiClientFactory().deleteScreenInvite(info.id);

      await dispatch(actionCreators.requestAllScreenInvites() as any);
    },

  setFetchParamsAllScreenInvites:
    ({
      page,
      pageSize,
      searchTerm,
    }: FetchParams): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const state = getState();
      if (state.screenInvites.isLoading) return;

      dispatch({
        type: "SETFETCHPARAMS_ALL_SCREEN_INVITES",
        page,
        pageSize,
        searchTerm,
      });

      await dispatch(actionCreators.requestAllScreenInvites() as any);
    },
  nextAllScreenInvitesPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.screenInvites.isLoading) return;

      dispatch({
        type: "NEXTPAGE_ALL_SCREEN_INVITES",
      });

      await dispatch(actionCreators.requestAllScreenInvites() as any);
    },
  prevAllScreenInvitesPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.screenInvites.isLoading) return;

      dispatch({
        type: "PREVPAGE_ALL_SCREEN_INVITES",
      });

      await dispatch(actionCreators.requestAllScreenInvites() as any);
    },

  requestAllScreenInvites:
    (): AppThunkAction<KnownAction> => (dispatch, getState) => {
      const {
        allScreenInvites: {
          page,
          pageSize,
          searchTerm,
          allScreenInvites: curAllScreenRequests,
        },
      } = getState();

      const fetchTask = apiClientFactory()
        .getAllScreenInvites(page, pageSize, searchTerm, undefined)
        .then(
          (data) => {
            dispatch({
              type: "RECEIVE_ALL_SCREEN_INVITES",
              screenInvites: data,
            });
          },
          (_error) => {
            dispatch({
              type: "RECEIVE_ALL_SCREEN_INVITES",
              screenInvites: curAllScreenRequests,
            });
          }
        );

      dispatch({
        type: "REQUEST_ALL_SCREEN_INVITES",
      });
      return fetchTask;
    },
};

const unloadedState: AllScreenInvitesState = {
  allScreenInvites: [],
  isLoading: false,
  page: 0,
  pageSize: defaultPageSize,
  searchTerm: "",
};

export const reducer: Reducer<AllScreenInvitesState> = (
  state: AllScreenInvitesState = unloadedState,
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "REQUEST_ALL_SCREEN_INVITES":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVE_ALL_SCREEN_INVITES":
      return {
        ...state,
        allScreenInvites: action.screenInvites,
        isLoading: false,
      };

    case "SETFETCHPARAMS_ALL_SCREEN_INVITES":
      return reduceSetFetchParams(state.isLoading, action, state);
    case "NEXTPAGE_ALL_SCREEN_INVITES":
      return reduceNextPage(state.allScreenInvites, state.isLoading, state);
    case "PREVPAGE_ALL_SCREEN_INVITES":
      return reducePrevPage(state.isLoading, state);

    case "DELETE_SCREEN_INVITE":
    default:
      return state;
  }
};
