import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as KeyRequestsState from "../../store/KeyRequests";
import { confirmPrompt } from "../../helpers";
import SelectLock from "../shared/SelectLock";
import {
  LockResponse,
  KeyRequestCredentialType,
  GuestKeyRequestResponse,
} from "../../api/ApiClient";

import { parse } from "query-string";
import MyAlerts from "../shared/Alert";

interface Injected {}

type RouteProps = RouteComponentProps<{}>;

type OwnProps = RouteProps & {
  header?: string;
};

type Props = Injected & typeof KeyRequestsState.actionCreators & OwnProps;

interface State {
  inProgress: Object;
  credentialType: KeyRequestCredentialType;
}

class SelectLockToOpen extends React.Component<Props, State> {
  state = {
    inProgress: {},
    credentialType: KeyRequestCredentialType.RemoteOpen,
  };

  myAlerts: MyAlerts | null = null;
  setMyAlerts = (myAlerts: MyAlerts) => {
    this.myAlerts = myAlerts;
  };

  static defaultProps = {
    header: "Open Door",
  };

  componentDidMount() {
    const {
      location: { search },
    } = this.props;
    const { unitId, unitName } = parse(search || "");
    if (unitId) {
      this.doOpenDoor({
        id: unitId as string,
        name: unitName as string,
      } as LockResponse);
    }
  }

  doOpenDoor = (lock: LockResponse) => {
    confirmPrompt({
      promptMessage: "Are you sure you want to open '" + lock.name + "'?",
      onConfirm: async () => {
        const { credentialType } = this.state;
        this.setState(
          {
            inProgress: {
              ...this.state.inProgress,
              [lock.id || ""]: true,
            },
          },
          async () => {
            try {
              const json = await this.props.postKeyRequest({
                lockIds: [lock.id || ""],
                credentialType,
              });
              const info = (json as any as GuestKeyRequestResponse)
                .tokenOrSecretInstruction;

              this.myAlerts &&
                this.myAlerts.addApiSuccessAlert(
                  info ? info : "Successfully opened door",
                  { dismissAfterMs: info ? undefined : 5000 }
                );
            } catch (e) {
              this.myAlerts && this.myAlerts.addApiErrorAlert(e);
            } finally {
              this.setState({
                inProgress: {
                  ...this.state.inProgress,
                  [lock.id || ""]: undefined,
                },
              });
            }
          }
        );
      },
    });
  };

  public render() {
    const { header } = this.props;

    const { inProgress } = this.state;

    const inProgressLocks = Object.keys(inProgress)
      .filter((k) => !!(inProgress as any)[k])
      .map((k) => ({ id: k } as LockResponse));

    return (
      <div>
        <h3>{header}</h3>
        <hr />
        <MyAlerts ref={this.setMyAlerts} />
        <form>
          {
            //@ts-ignore
            <SelectLock
              onSelected={this.doOpenDoor}
              inProgressLocks={inProgressLocks}
              inProgressLabel="Opening..."
              btnStyle={{ width: 130 }}
              unselectedLabel="Open"
              unselectedBtnClass="btn-primary"
            />
          }
        </form>
      </div>
    );
  }
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => ({}),
  KeyRequestsState.actionCreators
)(SelectLockToOpen);
