import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as SelectSharedAccountCredentialsState from "../../store/SharedAccountCredentials";
import { TenantResponse } from "../../api/ApiClient";

import Modal from "react-bootstrap-modal";

import "../shared/customModal.css";
import { RegisterAccount } from "./AdminAccounts";
import SelectSharedAccountCredentials from "../shared/SelectSharedAccountCredentials";

type OwnProps = RouteComponentProps<{ startDateIndex: string }>;

interface Injected {}
type Props = Injected &
  typeof SelectSharedAccountCredentialsState.actionCreators &
  OwnProps;

type ModalType = "sharedCredentials" | null;

interface State {
  inProgress: boolean;
  showingModal: ModalType;
}

class AdminSharedAccountCredentials extends React.Component<Props, State> {
  state: State = {
    inProgress: false,
    showingModal: null,
  };

  showAddCredModal = () => {
    this.setState({ showingModal: "sharedCredentials" });
  };

  closeModal = () => {
    this.setState({ showingModal: null });
  };

  onRegisteredCred = async () => {
    this.closeModal();
    this.props.requestSharedCredentials();
  };

  renderRegisterCred = () => {
    return (
      //@ts-ignore
      <RegisterAccount
        hideUseSharedCredentials={true}
        registerOverride={this.props.registerSharedCredentials}
        registerButtonLabel="Register"
        onRegistered={this.onRegisteredCred}
      />
    );
  };

  public render() {
    const { showingModal } = this.state;

    const button = (
      <button
        type="button"
        className="btn btn-primary"
        onClick={this.showAddCredModal}
      >
        Add Shared Credentials
      </button>
    );

    return (
      <div>
        <br />
        {button}
        <div>{this.renderSharedCredentialsTable()}</div>

        <Modal
          show={!!showingModal}
          onHide={this.closeModal}
          aria-labelledby="ModalHeader"
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="ModalHeader">
              {showingModal === "sharedCredentials"
                ? "Add Shared Credentials"
                : "?"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showingModal === "sharedCredentials"
              ? this.renderRegisterCred()
              : null}
          </Modal.Body>
          <Modal.Footer>
            <Modal.Dismiss className="btn btn-default">Close</Modal.Dismiss>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private renderButton = (tenant: TenantResponse) => {
    return null;
  };

  private renderSharedCredentialsTable() {
    return (
      <div>
        <p />
        &nbsp;
        <p />
        {
          //@ts-ignore
          <SelectSharedAccountCredentials
            pageSize={5}
            renderButton={this.renderButton}
          />
        }
        <hr />
      </div>
    );
  }
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    sites: state.sites,
  }),
  {
    ...SelectSharedAccountCredentialsState.actionCreators,
  }
)(AdminSharedAccountCredentials);
