import { useEffect, useState } from "react";
import * as React from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { apiClientFactory } from "../../helpers/index";

import {
  AccessControlDoorType,
  GatewayInfoResponse,
  LockAvailableFirmwareResponse,
  LockResponse,
  SiteSystemType,
} from "../../api/ApiClient";

function LockEdit() {
  // Declare a new state variable, which we'll call "count"
  const { lockId } = useParams();
  const location = useLocation();
  const [lock, setLock] = useState<LockResponse | undefined>(undefined);
  const [doorType, setDoorType] = useState<AccessControlDoorType | undefined>(
    undefined
  );
  const [name, setName] = useState<string | undefined>(undefined);
  const [noTourEnabled, setNoTourEnabled] = useState<boolean | undefined>(
    undefined
  );
  const [firmwareLock, setfirmwareLock] = useState<string | undefined>(
    undefined
  );
  const [firmwareLockUpgradeVersion, setfirmwareLockUpgradeVersion] = useState<
    string | undefined
  >(undefined);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [getFirmwareInProgress, setGetFirmwareInProgress] = useState(false);
  const [modelFirmware, setModelFirmware] = useState(
    new Map<string, LockAvailableFirmwareResponse[]>()
  );
  const [selectedModelFirmware, setSelectedModelFirmware] = useState(
    new Map<string, string>()
  );
  const [gatewayInfo, setGatewayInfo] = useState(
    undefined as GatewayInfoResponse | undefined
  );

  const isValid = name != null && name.length > 0 && doorType != null;
  const [gatewayOffline, setGatewayOffline] = useState(false);
  const api = apiClientFactory();

  const onEditSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setUpdateInProgress(true);
    e.preventDefault();
    try {
      api.putLock(lockId, {
        doorType: doorType,
        name: name,
        noTourEnabled: noTourEnabled,
      });
    } catch (exception) {
      console.log(exception);
    } finally {
      setUpdateInProgress(false);
    }
  };

  const onQueueFirmwareUpdateClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const gatewayFirmware = modelFirmware.get("gwe");
    if (gatewayFirmware) {
      const selectedFirmware = gatewayFirmware.find(
        (e) => e.version === firmwareLockUpgradeVersion
      );
      if (
        selectedFirmware &&
        selectedFirmware.version &&
        selectedFirmware.links &&
        selectedFirmware.links.length > 0
      ) {
        await api.startGatewayFirmwareUpgrade(lockId, "gwe", {
          url: selectedFirmware.links[0],
          version: selectedFirmware.version,
        });
      }
    }
  };

  const onQueueModelFirmwareUpdateClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    model: string | undefined
  ) => {
    e.preventDefault();

    if (model) {
      const selectedAvailableFirmware = modelFirmware.get(model);
      const targetFirmware = selectedModelFirmware.get(model);
      if (selectedAvailableFirmware && targetFirmware) {
        const selectedTargetFirmware = selectedAvailableFirmware.find(
          (e) => e.version === targetFirmware
        );
        if (
          selectedTargetFirmware &&
          selectedTargetFirmware.links &&
          selectedTargetFirmware.links.length > 0 &&
          selectedTargetFirmware.version
        ) {
          await api.startGatewayFirmwareUpgrade(lockId, model, {
            version: selectedTargetFirmware.version,
            url: selectedTargetFirmware.links[0],
          });
        }
      }
    }
  };
  const progressStyle: React.CSSProperties = {
    width: "100%",
  };
  useEffect(() => {
    (async () => {
      const localLock = await api.getLock(lockId, undefined);
      setLock(localLock);
      setDoorType(localLock.doorType);
      setName(localLock.name);
      setNoTourEnabled(localLock.noTourEnabled);
      setfirmwareLock(localLock.firmwareLock);
      if (localLock.deviceType === "gwe" && localLock.id && localLock.siteId) {
        setGetFirmwareInProgress(true);
        try {
          const gatewayInfo = await api.getGatewayInfo(localLock.id);
          setGatewayInfo(gatewayInfo);
          if (gatewayInfo && gatewayInfo.information) {
            setfirmwareLock(gatewayInfo.information.firmwareVersion);
            setfirmwareLockUpgradeVersion(
              gatewayInfo.information.firmwareVersion
            );
          }

          if (gatewayInfo && gatewayInfo.locks) {
            const distinctModelTypes = gatewayInfo.locks
              .map((v) => v.modelType)
              .concat("gwe")
              .filter((v, i, a) => a.indexOf(v) === i);
            let fw = new Map<string, LockAvailableFirmwareResponse[]>();
            for (const modelType of distinctModelTypes) {
              const deviceFirmware = await api.getLockFirmwareByDeviceType(
                localLock.siteId,
                modelType as string
              );
              var sortedFirmwares = deviceFirmware.sort(
                (a, b) =>
                  (b.releaseDate ? new Date(b.releaseDate).getTime() : 0) -
                  (a.releaseDate ? new Date(a.releaseDate).getTime() : 0)
              );
              fw.set(modelType as string, sortedFirmwares);
            }
            setModelFirmware(fw);
          }
        } catch (exception) {
          setGatewayOffline(true);
          console.log(exception);
        } finally {
          setGetFirmwareInProgress(false);
        }
      }
    })();
    // eslint-disable-next-line
  }, [location]);
  return (
    <React.Fragment>
      <main className="row">
        <div className="col-xs-12">
          <ol className="breadcrumb">
            <li>
              <NavLink to={"/admin/locks"} exact>
                Locks
              </NavLink>
            </li>
            <li className="active">Edit</li>
          </ol>
        </div>

        {lock === undefined ? (
          <progress style={progressStyle}></progress>
        ) : (
          <React.Fragment>
            <div className="col-xs-12 col-sm-6">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h3 className="panel-title">Edit Lock Details</h3>
                </div>
                <div className="panel-body">
                  <form onSubmit={(e) => onEditSubmit(e)}>
                    <fieldset disabled={updateInProgress}>
                      <div className="form-group">
                        <label htmlFor="lockName">Lock Name</label>
                        <input
                          className="form-control"
                          name="lockName"
                          placeholder="Lock Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <p className="help-block"></p>
                      </div>

                      <div className="form-group">
                        <label htmlFor="doorType">Door Type</label>
                        <select
                          className="form-control"
                          name="doorType"
                          value={doorType}
                          onChange={(e) => {
                            setDoorType(
                              e.target.value as AccessControlDoorType
                            );
                          }}
                        >
                          {Object.entries(AccessControlDoorType)
                            .filter((k) => isNaN(Number(k)))
                            .map(([k, v]) => (
                              <option key={k} value={v}>
                                {k}
                              </option>
                            ))}
                        </select>
                      </div>
                      {lock &&
                      lock.site &&
                      lock.site.account &&
                      lock.site.account.systemType ===
                        SiteSystemType.SchlageEngage ? (
                        <div className="form-group">
                          <div className="checkbox">
                            <label htmlFor={"noTourEnabled"}>
                              <input
                                type="checkbox"
                                checked={lock && lock.noTourEnabled}
                                id={"noTourEnabled"}
                                onChange={(e) => {
                                  setNoTourEnabled(e.target.checked);
                                }}
                              />
                              No Tour Enabled
                            </label>
                          </div>
                        </div>
                      ) : (
                        <React.Fragment />
                      )}
                      <input
                        className="btn btn-primary"
                        type="submit"
                        disabled={!isValid}
                      />
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
            {lock &&
              lock.site &&
              lock.site.account &&
              lock.site.account.systemType === SiteSystemType.SchlageEngage && (
                <div className="col-xs-12 col-sm-6">
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <h3 className="panel-title">Hardware Information</h3>
                    </div>
                    <div className="panel-body">
                      <dl>
                        <dt>Firmware Version</dt>
                        <dd>{firmwareLock}</dd>
                      </dl>
                    </div>
                  </div>
                </div>
              )}
            {lock &&
              lock.site &&
              lock.site.account &&
              lock.site.account.systemType === SiteSystemType.SchlageEngage &&
              lock.deviceType === "gwe" && (
                <div className="col-xs-12 col-sm-6">
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <h3 className="panel-title">Connected Lock Firmware</h3>
                    </div>
                    <div className="panel-body">
                      {gatewayOffline && (
                        <div className="alert alert-danger">
                          Gateway is Offline
                        </div>
                      )}
                      {getFirmwareInProgress ||
                      modelFirmware.get("gwe") === undefined ? (
                        <em>Checking for available firmware updates</em>
                      ) : (
                        <div className="form-group">
                          <select
                            className="form-control"
                            value={firmwareLockUpgradeVersion}
                            onChange={(e) =>
                              setfirmwareLockUpgradeVersion(e.target.value)
                            }
                          >
                            {(
                              modelFirmware.get(
                                "gwe"
                              ) as LockAvailableFirmwareResponse[]
                            ).map((e) => (
                              <option key={e.version} value={e.version}>
                                {e.description} ({e.version})
                              </option>
                            ))}
                          </select>
                          <button
                            disabled={
                              firmwareLockUpgradeVersion === undefined ||
                              firmwareLock === undefined ||
                              firmwareLockUpgradeVersion.includes(firmwareLock)
                            }
                            className="btn btn-default"
                            onClick={(e) => onQueueFirmwareUpdateClick(e)}
                          >
                            Queue Firmware Update
                          </button>
                        </div>
                      )}
                      {!getFirmwareInProgress &&
                        Array.from(modelFirmware)
                          .filter((e) => e[0] !== "gwe")
                          .map((e) => {
                            return {
                              model: e[0],
                              firmware: e[1],
                              locksAndFirmwares:
                                gatewayInfo && gatewayInfo.locks
                                  ? gatewayInfo.locks
                                      .filter((e1) => e1.modelType === e[0])
                                      .map((lock) => {
                                        let dtf =
                                          gatewayInfo &&
                                          gatewayInfo.information &&
                                          gatewayInfo.information
                                            .deviceTypeFirmware
                                            ? gatewayInfo.information.deviceTypeFirmware.find(
                                                (firmware) =>
                                                  firmware.modelType === e[0]
                                              )
                                            : undefined;
                                        let clf =
                                          dtf && dtf.connectedLockFirmware
                                            ? dtf.connectedLockFirmware.find(
                                                (clf) =>
                                                  clf.deviceId === lock.deviceId
                                              )
                                            : undefined;
                                        return {
                                          lock: lock,
                                          connectedLockFirmware: clf,
                                        };
                                      })
                                  : [],
                            };
                          })
                          .map((e) => (
                            <div key={e.model} className="well">
                              <h3>{e.model}</h3>
                              <div className="form-group">
                                <select
                                  className="form-control"
                                  value={selectedModelFirmware.get(e.model)}
                                  onChange={(event) => {
                                    let smf = new Map(
                                      selectedModelFirmware
                                    ).set(e.model, event.target.value);
                                    setSelectedModelFirmware(smf);
                                  }}
                                >
                                  {e.firmware.map((e) => (
                                    <option key={e.version} value={e.version}>
                                      {e.description} ({e.version})
                                    </option>
                                  ))}
                                </select>
                                <button
                                  className="btn btn-default"
                                  onClick={(event) =>
                                    onQueueModelFirmwareUpdateClick(
                                      event,
                                      e.model
                                    )
                                  }
                                >
                                  Queue Firmware Update
                                </button>
                              </div>
                              <div className="table-responsive">
                                <table className="table table-condensed">
                                  <thead>
                                    <tr>
                                      <th>Link</th>
                                      <th>Name</th>
                                      <th>S/N</th>
                                      <th>Retries</th>
                                      <th>Firmware Version</th>
                                      <th>Transfer</th>
                                      <th>Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {e.locksAndFirmwares.map(
                                      (lockAndFirmware) => (
                                        <tr key={lockAndFirmware.lock.deviceId}>
                                          <td>{lockAndFirmware.lock.linkId}</td>
                                          <td>
                                            {lockAndFirmware.lock.deviceName}
                                          </td>
                                          <td>
                                            {lockAndFirmware.lock.deviceId}
                                          </td>

                                          <td>
                                            {lockAndFirmware.connectedLockFirmware
                                              ? lockAndFirmware
                                                  .connectedLockFirmware.retries
                                              : ""}
                                          </td>
                                          <td>
                                            {lockAndFirmware.connectedLockFirmware
                                              ? lockAndFirmware
                                                  .connectedLockFirmware
                                                  .firmwareVersion
                                              : ""}
                                          </td>

                                          <td>
                                            {lockAndFirmware.connectedLockFirmware ? (
                                              <div
                                                className="progress-bar progress-bar-striped active"
                                                role="progressbar"
                                                data-toggle="tooltip"
                                                title={
                                                  "Started: " +
                                                  lockAndFirmware
                                                    .connectedLockFirmware
                                                    .startTime +
                                                  " Cancelled: " +
                                                  lockAndFirmware
                                                    .connectedLockFirmware
                                                    .cancelTime +
                                                  " Completed: " +
                                                  lockAndFirmware
                                                    .connectedLockFirmware
                                                    .completeTime
                                                }
                                                style={{
                                                  width:
                                                    lockAndFirmware
                                                      .connectedLockFirmware
                                                      .transferPercentage + "%",
                                                }}
                                              >
                                                {
                                                  lockAndFirmware
                                                    .connectedLockFirmware
                                                    .transferPercentage
                                                }
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                          <td>
                                            {lockAndFirmware.connectedLockFirmware
                                              ? lockAndFirmware
                                                  .connectedLockFirmware
                                                  .updateStatus
                                              : ""}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                </div>
              )}
          </React.Fragment>
        )}
      </main>
    </React.Fragment>
  );
}

export default LockEdit;
