import * as React from "react";

type PageControlProps = {
  currentPage: number;
  isLoading?: boolean;
  onNext: () => any;
  onPrev: () => any;
  disableNext?: boolean;
  disablePrev?: boolean;
};

export class PageControl extends React.Component<PageControlProps, {}> {
  onNext = async (e: any) => {
    const { isLoading } = this.props;
    e.preventDefault();
    if (this.props.disableNext || isLoading) return;
    try {
      await this.props.onNext();
    } catch (e) {
      alert("Error fetching next page: " + JSON.stringify(e));
    }
  };

  onPrev = async (e: any) => {
    const { isLoading } = this.props;
    e.preventDefault();
    if (this.props.disablePrev || isLoading) return;
    try {
      await this.props.onPrev();
    } catch (e) {
      alert("Error fetching previous page: " + JSON.stringify(e));
    }
  };

  public render() {
    const { currentPage } = this.props;
    return (
      <ul className="pagination">
        <li>
          <button onClick={this.onPrev}>Prev</button>
        </li>
        <li className="active">
          <span>{currentPage + 1}</span>
        </li>
        <li>
          <button onClick={this.onNext}>Next</button>
        </li>
      </ul>
    );
  }
}
