import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";
import {
  FetchParams,
  reduceSetFetchParams,
  defaultPageSize,
  reduceNextPage,
  reducePrevPage,
  apiClientFactory,
} from "../helpers";

import { SiteResponse } from "../api/ApiClient";

export interface SitesState extends FetchParams {
  isLoading: boolean;
  sites: SiteSyncingState[];
}

export interface SiteSyncingState extends SiteResponse {
  propertyExternalId: string;
  id: string;
  syncInProgress?: boolean;
  syncSuccess?: boolean;
  syncMessage?: string;
  accessCode?: string;
  proxyVersion?: number;
  password?: string;
  username?: string;
  sharedKey?: string;
  sharedKeyName?: string;
}

interface RequestSitesAction {
  type: "REQUEST_SITES";
}

interface SetFetchParams extends FetchParams {
  type: "SETFETCHPARAMS_SITES";
}

interface NextPage {
  type: "NEXTPAGE_SITES";
}

interface PrevPage {
  type: "PREVPAGE_SITES";
}

interface ReceiveSitesAction extends FetchParams {
  type: "RECEIVE_SITES";
  sites: SiteResponse[];
}

interface RegisterSiteActionPayload {
  systemSiteId: string;
  name: string;
  accountId: string;
  propertyExternalId: string;
  accessCode?: string;
  proxyVersion?: string;
  username?: string;
  password?: string;
  sharedKey?: string;
  sharedKeyName?: string;
  systemSiteKey?: string;
}
interface RegisterSiteAction extends RegisterSiteActionPayload {
  type: "REGISTER_SITE";
}

interface DeleteSiteActionPayload {
  id: string;
}
interface DeleteSiteAction extends DeleteSiteActionPayload {
  type: "DELETE_SITE";
}

interface SiteLockSyncActionPayload {
  id: string;
  success?: boolean;
  result?: string;
}
interface SiteLockSyncAction extends SiteLockSyncActionPayload {
  type: "SITE_LOCK_SYNC";
}

interface SiteLockSyncCompleteAction extends SiteLockSyncActionPayload {
  type: "SITE_LOCK_SYNC_COMPLETE";
}

type KnownAction =
  | RequestSitesAction
  | SetFetchParams
  | NextPage
  | PrevPage
  | ReceiveSitesAction
  | RegisterSiteAction
  | DeleteSiteAction
  | SiteLockSyncAction
  | SiteLockSyncCompleteAction;

export const actionCreators = {
  setFetchParamSites:
    ({
      page,
      pageSize,
      searchTerm,
    }: FetchParams): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const state = getState();
      if (state.sites.isLoading) return;

      dispatch({
        type: "SETFETCHPARAMS_SITES",
        page,
        pageSize,
        searchTerm,
      });

      await dispatch(actionCreators.requestSites() as any);
    },
  nextSitePage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.sites.isLoading) return;

      dispatch({
        type: "NEXTPAGE_SITES",
      });

      await dispatch(actionCreators.requestSites() as any);
    },
  prevSitePage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.sites.isLoading) return;

      dispatch({
        type: "PREVPAGE_SITES",
      });

      await dispatch(actionCreators.requestSites() as any);
    },

  requestSites: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const {
      sites: { page, pageSize, searchTerm, sites: curSites },
    } = getState();

    const fetchTask = apiClientFactory()
      .getSites(page, pageSize, searchTerm, undefined)

      .then(
        (data) => {
          dispatch({
            type: "RECEIVE_SITES",
            sites: data,
            page,
            pageSize,
            searchTerm,
          });
        },
        (_error) => {
          dispatch({
            type: "RECEIVE_SITES",
            sites: curSites,
            page,
            pageSize,
            searchTerm,
          });
        }
      );

    dispatch({
      type: "REQUEST_SITES",
    });
    return fetchTask;
  },

  registerSite:
    (info: RegisterSiteActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      let payload: any = {
        name: info.name,
        accountId: info.accountId,
        systemSiteId: info.systemSiteId,
        propertyExternalId: info.propertyExternalId,
      };
      if (info?.accessCode) {
        payload = { ...payload, accessCode: info.accessCode };
      }
      if (info?.proxyVersion) {
        payload = { ...payload, proxyVersion: info.proxyVersion };
      }
      if (info?.systemSiteKey) {
        payload = { ...payload, systemSiteKey: info.systemSiteKey };
      }
      if (info?.username) {
        payload = {
          ...payload,
          username: info.username,
          password: info.password,
          sharedKey: info.sharedKey,
          sharedKeyName: info.sharedKeyName,
        };
      }

      const json = await apiClientFactory().postSite(payload);

      return json;
    },

  deleteSite:
    (info: DeleteSiteActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      await apiClientFactory().deleteSite(info.id);

      await dispatch(actionCreators.requestSites() as any);
    },

  siteLockSync:
    (info: SiteLockSyncActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      dispatch({ type: "SITE_LOCK_SYNC", id: info.id });
      try {
        const result = await apiClientFactory().locksSync(info.id);
        dispatch({
          type: "SITE_LOCK_SYNC_COMPLETE",
          result: result,
          success: true,
          id: info.id,
        });
      } catch (ex) {
        dispatch({
          type: "SITE_LOCK_SYNC_COMPLETE",
          success: false,
          id: info.id,
        });
      }
    },

  siteACGSync:
    (info: SiteLockSyncActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      dispatch({ type: "SITE_LOCK_SYNC", id: info.id });
      try {
        const result = await apiClientFactory().acgSync(info.id);
        dispatch({
          type: "SITE_LOCK_SYNC_COMPLETE",
          result: result,
          success: true,
          id: info.id,
        });
      } catch (ex) {
        dispatch({
          type: "SITE_LOCK_SYNC_COMPLETE",
          success: false,
          id: info.id,
        });
      }
    },
};

const unloadedState: SitesState = {
  sites: [],
  isLoading: false,
  page: 0,
  pageSize: defaultPageSize,
  searchTerm: "",
};

export const reducer: Reducer<SitesState> = (
  state: SitesState = unloadedState,
  incomingAction: Action
): SitesState => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "REQUEST_SITES":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVE_SITES":
      return {
        ...state,
        sites: action.sites as SiteSyncingState[],
        isLoading: false,
        page: action.page,
        pageSize: action.pageSize,
        searchTerm: action.searchTerm,
      };

    case "SETFETCHPARAMS_SITES":
      return reduceSetFetchParams(state.isLoading, action, state);
    case "NEXTPAGE_SITES":
      return reduceNextPage(state.sites, state.isLoading, state);
    case "PREVPAGE_SITES":
      return reducePrevPage(state.isLoading, state);

    case "SITE_LOCK_SYNC":
      return {
        ...state,
        sites: [...state.sites].map((e) =>
          e.id === action.id
            ? {
                ...e,
                syncInProgress: true,
                syncMessage: action.result,
                syncSuccess: action.success,
              }
            : e
        ),
      };
    case "SITE_LOCK_SYNC_COMPLETE":
      return {
        ...state,
        sites: [...state.sites].map((e) =>
          e.id === action.id
            ? {
                ...e,
                syncInProgress: false,
                syncMessage: action.result,
                syncSuccess: action.success,
              }
            : e
        ),
      };
    case "REGISTER_SITE":
    case "DELETE_SITE":
    default:
      return state;
  }
};
