import React from "react";
import AdminAccounts from "./AdminAccounts";
import useGetSystemTypes from "./hooks/useGetSystemTypes";

const AdminAccountsContainer = () => {
  const { data = [], isLoading } = useGetSystemTypes();
  //@ts-ignore
  return <AdminAccounts systemTypes={data} isLoading={isLoading} />;
};

export default AdminAccountsContainer;
