import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import {
  FetchParams,
  reduceSetFetchParams,
  defaultPageSize,
  reduceNextPage,
  reducePrevPage,
  apiClientFactory,
  getExternalTenantId,
} from "../helpers";
import { ScreenInviteResponse } from "../api/ApiClient";

export interface ScreenInvitesState extends FetchParams {
  isLoading: boolean;
  screenInvites: ScreenInviteResponse[];
}

interface RequestScreenInvitesAction {
  type: "REQUEST_SCREENINVITES";
}

interface RequestScreenInviteActionPayload {
  id: string;
}

interface RequestScreenInviteAction extends RequestScreenInviteActionPayload {
  type: "REQUEST_SCREENINVITE";
}

interface SetFetchParams extends FetchParams {
  type: "SETFETCHPARAMS_SCREENINVITES";
}

interface NextPage {
  type: "NEXTPAGE_SCREENINVITES";
}

interface PrevPage {
  type: "PREVPAGE_SCREENINVITES";
}

interface ReceiveScreenInvitesAction {
  type: "RECEIVE_SCREENINVITES";
  screenInvites: ScreenInviteResponse[];
}

interface ReceiveScreenInviteAction {
  type: "RECEIVE_SCREENINVITE";
  screenInvite: ScreenInviteResponse;
}

interface ScreenCreateInviteActionPayload {
  guestSms: string;
  details: string;
  note: string;
  validFrom?: Date;
  validTo?: Date;
  screenIds: string[];
}
interface ScreenCreateInviteAction extends ScreenCreateInviteActionPayload {
  type: "SCREEN_CREATE_INVITE";
}

type KnownAction =
  | RequestScreenInvitesAction
  | RequestScreenInviteAction
  | SetFetchParams
  | NextPage
  | PrevPage
  | ReceiveScreenInvitesAction
  | ReceiveScreenInviteAction
  | ScreenCreateInviteAction;

export const actionCreators = {
  setFetchParamsScreenInvites:
    ({
      page,
      pageSize,
      searchTerm,
    }: FetchParams): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const state = getState();
      if (state.screenInvites.isLoading) return;

      dispatch({
        type: "SETFETCHPARAMS_SCREENINVITES",
        page,
        pageSize,
        searchTerm,
      });

      await dispatch(actionCreators.requestScreenInvites() as any);
    },
  nextScreenInvitePage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.screenInvites.isLoading) return;

      dispatch({
        type: "NEXTPAGE_SCREENINVITES",
      });

      await dispatch(actionCreators.requestScreenInvites() as any);
    },
  prevScreenInvitePage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.screenInvites.isLoading) return;

      dispatch({
        type: "PREVPAGE_SCREENINVITES",
      });

      await dispatch(actionCreators.requestScreenInvites() as any);
    },

  requestScreenInvites:
    (): AppThunkAction<KnownAction> => (dispatch, getState) => {
      const {
        auth0: {
          session: { accessToken },
        },
        screenInvites: { page, pageSize, screenInvites: curScreenInvites },
      } = getState();

      const fetchTask = apiClientFactory()
        .getScreenInvites(page, pageSize, {
          accessToken: accessToken || "",
        })
        .then(
          (data) => {
            dispatch({
              type: "RECEIVE_SCREENINVITES",
              screenInvites: data,
            });
          },
          (_error) => {
            dispatch({
              type: "RECEIVE_SCREENINVITES",
              screenInvites: curScreenInvites,
            });
          }
        );

      dispatch({
        type: "REQUEST_SCREENINVITES",
      });
      return fetchTask;
    },

  fetchScreenInvite:
    (info: RequestScreenInviteActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({
        type: "REQUEST_SCREENINVITE",
        id: info.id as string,
      });
      const json = await apiClientFactory().getScreenInvite(info.id);
      return dispatch({
        type: "RECEIVE_SCREENINVITE",
        screenInvite: json,
      });
    },

  screenCreateInvite:
    (info: ScreenCreateInviteActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const {
        me: { user },
      } = getState();

      const json = await apiClientFactory().postScreenInvite({
        guestSms: info.guestSms,
        details: info.details,
        note: info.note,
        screenIds: info.screenIds,
        validFrom: info.validFrom,
        validTo: info.validTo,
        tenantExternalId: getExternalTenantId(user),
        connectUserExternalId: user && user.externalId ? user.externalId : "",
      });

      return json;
    },
};

const unloadedState: ScreenInvitesState = {
  screenInvites: [],
  isLoading: false,
  page: 0,
  pageSize: defaultPageSize,
  searchTerm: "",
};

export const reducer: Reducer<ScreenInvitesState> = (
  state: ScreenInvitesState = unloadedState,
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "REQUEST_SCREENINVITES":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVE_SCREENINVITES":
      return {
        ...state,
        screenInvites: action.screenInvites,
        isLoading: false,
      };

    case "SETFETCHPARAMS_SCREENINVITES":
      return reduceSetFetchParams(state.isLoading, action, state);
    case "NEXTPAGE_SCREENINVITES":
      return reduceNextPage(state.screenInvites, state.isLoading, state);
    case "PREVPAGE_SCREENINVITES":
      return reducePrevPage(state.isLoading, state);

    case "REQUEST_SCREENINVITE":
    case "RECEIVE_SCREENINVITE":
    case "SCREEN_CREATE_INVITE":
    default:
      return state;
  }
};
