import React from "react";
import useGetSystemTypes from "./hooks/useGetSystemTypes";
import AdminTenants from "./AdminTenants";

const AdminTenetsContainer = () => {
  const { data = [], isLoading } = useGetSystemTypes();
  //@ts-ignore
  return <AdminTenants systemTypes={data} isLoading={isLoading} />;
};

export default AdminTenetsContainer;
