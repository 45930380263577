import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as LocksState from "../../store/Locks";
import * as SitesState from "../../store/Sites";
import * as SiteUsersState from "../../store/SiteUsers";
import { confirmPrompt } from "../../helpers";
import {
  SiteResponse,
  SiteSystemType,
  ConnectUserResponse,
  RegisterLockResponse,
  AccessControlDoorType,
  InviteResponse,
} from "../../api/ApiClient";
import SelectSite from "../shared/SelectSite";
import Modal from "react-bootstrap-modal";
import SelectClientUser from "../shared/SelectClientUser";

import "./adminSites.css";
import { SiteSyncingState } from "../../store/Sites";
import { CSVLockUploaderModal } from "./CSVLockUploaderModal";

type OwnProps = RouteComponentProps<{ startDateIndex: string }>;

interface Injected {}
type Props = Injected &
  typeof LocksState.actionCreators &
  typeof SitesState.actionCreators &
  typeof SiteUsersState.actionCreators &
  OwnProps;

type ModalType = "user" | "lock" | "csv" | null;

interface State {
  inProgress: Object | null;

  selectedSite: SiteResponse | null;
  showingModal: ModalType;

  resultEndpointId?: string;
  resultInviteCode?: string;

  credentialId: string;

  selectedUser: {
    externalId?: string | undefined;
    name?: string | undefined;
  } | null;
  selectingUser: boolean;
  systemUserId: string;
  siteId?: string;
  lockId?: string;
  lockName: string;
  systemLockId: string;
  applicationUserId: string;
  phone: string;
  endpointId?: string;
}

class AdminSites extends React.Component<Props, State> {
  state: State = {
    inProgress: null,

    selectedSite: null,
    showingModal: null,

    resultEndpointId: "",
    resultInviteCode: "",
    credentialId: "",
    selectedUser: null,
    selectingUser: true,
    systemUserId: "",
    siteId: "",
    lockId: "",
    lockName: "",
    systemLockId: "",
    applicationUserId: "",
    phone: "",
    endpointId: "",
  };

  addLockForSite = (site: SiteResponse) => {
    this.setState(
      {
        selectedSite: site,
        siteId: site.id || "",
      },
      () => {
        this.showAddCSVModal();
      }
    );
  };

  syncLocks = (id: string) => {
    this.props.siteLockSync({
      id: id,
    });
  };

  syncACG = (id: string) => {
    this.props.siteACGSync({
      id: id,
    });
  };

  addUserForSite = (site: SiteResponse) => {
    this.setState(
      {
        selectedSite: site,
        siteId: site.id || "",
      },
      () => {
        this.showAddUserModal();
      }
    );
  };

  registerLock = (e: any) => {
    e.preventDefault();

    this.setState(
      {
        inProgress: { ...(this.state.inProgress || {}), registerLock: true },
      },
      async () => {
        const { siteId, systemLockId, lockName: name } = this.state;
        try {
          const json: RegisterLockResponse = (await this.props.registerLock({
            siteId: siteId || "",
            systemLockId,
            name,
            doorType: AccessControlDoorType.Common,
          })) as any;
          alert("Successfully registered lock: " + JSON.stringify(json));
          this.setState({
            lockId: json.id,

            // clear inputs
            systemLockId: "",
          });
          await this.props.requestLocks();
        } catch (e) {
          alert("Error registering lock: " + JSON.stringify(e));
        } finally {
          this.setState({
            inProgress: {
              ...(this.state.inProgress || {}),
              registerLock: false,
            },
          });
        }
      }
    );
  };

  deleteSite = (site: SiteResponse) => {
    confirmPrompt({
      promptMessage: "Are you sure you want to delete this site?",
      onConfirm: async () => {
        try {
          await this.props.deleteSite({
            id: site.id || "",
          });
          await this.props.requestSites();
        } catch (e) {
          alert("Error deleting site: " + JSON.stringify(e));
        }
      },
    });
  };

  inviteAssaUser = (e: any) => {
    e.preventDefault();

    const { selectedUser, siteId } = this.state;
    this.setState(
      {
        inProgress: { ...(this.state.inProgress || {}), inviteAssaUser: true },
      },
      async () => {
        try {
          const json: InviteResponse = (await this.props.inviteAssaUser({
            siteId: siteId || "",
            phone: "",
            clientUserId:
              selectedUser && selectedUser.externalId
                ? selectedUser.externalId
                : "",
          })) as any;
          this.setState({
            resultEndpointId: json.endpointId,
            resultInviteCode: json.invitationCode,

            //clear inputs
          });
          alert(
            "Successfully invited Assa Abloy user to receive mobile access keys: " +
              JSON.stringify(json)
          );
          this.setState({
            endpointId: json.endpointId,
          });
          await this.props.requestSiteUsers();
        } catch (e) {
          alert("Error inviting Assa user: " + JSON.stringify(e));
        } finally {
          this.setState({
            inProgress: {
              ...(this.state.inProgress || {}),
              inviteAssaUser: false,
            },
          });
        }
      }
    );
  };

  registerOpenPathUser = (e: any) => {
    e.preventDefault();

    const { selectedUser, credentialId, systemUserId, siteId } = this.state;
    this.setState(
      {
        inProgress: { ...(this.state.inProgress || {}), openPathUser: true },
      },
      async () => {
        try {
          const json = await this.props.registerOpenPathUser({
            siteId: siteId || "",
            credentialId,
            systemUserId,
            clientUserId:
              selectedUser && selectedUser.externalId
                ? selectedUser.externalId
                : "",
          });
          await this.props.requestSiteUsers();
          this.setState({
            //clear inputs
            credentialId: "",
            systemUserId: "",
          });
          alert("Successfully registered OpenPath user" + JSON.stringify(json));
        } catch (e) {
          alert("Error registering OpenPath User: " + JSON.stringify(e));
        } finally {
          this.setState({
            inProgress: {
              ...(this.state.inProgress || {}),
              openPathUser: false,
            },
          });
        }
      }
    );
  };

  registerSiteUser = (e: any) => {
    e.preventDefault();

    const { selectedUser, credentialId, systemUserId, siteId } = this.state;
    this.setState(
      {
        inProgress: { ...(this.state.inProgress || {}), openPathUser: true },
      },
      async () => {
        try {
          const json = await this.props.registerSiteUser({
            siteId: siteId || "",
            credentialId,
            systemUserId,
            clientUserId:
              selectedUser && selectedUser.externalId
                ? selectedUser.externalId
                : "",
          });
          await this.props.requestSiteUsers();
          this.setState({
            //clear inputs
            credentialId: "",
            systemUserId: "",
          });
          alert("Successfully registered site user" + JSON.stringify(json));
        } catch (e) {
          alert("Error registering site User: " + JSON.stringify(e));
        } finally {
          this.setState({
            inProgress: {
              ...(this.state.inProgress || {}),
              openPathUser: false,
            },
          });
        }
      }
    );
  };

  registerButterflyUser = (e: any) => {
    e.preventDefault();

    const { selectedUser, systemUserId, siteId } = this.state;
    this.setState(
      {
        inProgress: { ...(this.state.inProgress || {}), butterflyUser: true },
      },
      async () => {
        try {
          const json = await this.props.registerButterflyUser({
            siteId: siteId || "",
            systemUserId,
            clientUserId:
              selectedUser && selectedUser.externalId
                ? selectedUser.externalId
                : "",
          });
          await this.props.requestSiteUsers();
          this.setState({
            //clear inputs
            systemUserId: "",
          });
          alert(
            "Successfully registered Butterfly user" + JSON.stringify(json)
          );
        } catch (e) {
          alert("Error registering Butterfly User: " + JSON.stringify(e));
        } finally {
          this.setState({
            inProgress: {
              ...(this.state.inProgress || {}),
              butterflyUser: false,
            },
          });
        }
      }
    );
  };

  showAddUserModal = () => {
    this.setState({ showingModal: "user" });
  };

  showAddLockModal = () => {
    this.setState({ showingModal: "lock" });
  };

  showAddCSVModal = () => {
    this.setState({ showingModal: "csv" });
  };

  closeModal = () => {
    this.setState({ showingModal: null });
  };

  selectSite = (site: SiteResponse) => {
    const { selectedSite } = this.state;
    const wasSelected = selectedSite && selectedSite.id === site.id;
    this.setState({
      selectedSite: wasSelected ? null : site,
    });
  };

  public render() {
    const { showingModal } = this.state;

    return (
      <div>
        <div>{this.renderSitesTable()}</div>

        <Modal
          show={!!showingModal}
          onHide={this.closeModal}
          aria-labelledby="ModalHeader"
          dialogClassName={showingModal !== "csv" && "custom-modal"}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="ModalHeader">
              {showingModal === "user"
                ? "Add User to Site"
                : showingModal === "csv"
                ? "CSV Lock Import"
                : "Add Lock"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showingModal === "user"
              ? this.renderRegisterUserWrapper()
              : showingModal === "csv"
              ? this.renderCsvUpload()
              : this.renderRegisterLock()}
          </Modal.Body>
          <Modal.Footer>
            <Modal.Dismiss className="btn btn-default">Close</Modal.Dismiss>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private renderSiteButton = (site: SiteSyncingState) => {
    return (
      <div className="d-flex justify-between">
        <button
          type="button"
          className={`btn btn-block btn-danger`}
          onClick={() => this.deleteSite(site)}
        >
          Delete
        </button>
        <button
          type="button"
          className={`btn btn-block btn-default`}
          onClick={() => this.addUserForSite(site)}
        >
          Add User
        </button>
        <React.Fragment>
          <React.Fragment>
            <button
              type="button"
              disabled={site.syncInProgress}
              className={`btn btn-block btn-default`}
              onClick={() => this.syncLocks(site.id)}
            >
              Sync Locks
            </button>
          </React.Fragment>
          {site &&
          site.account &&
          site.account.systemType &&
          [
            SiteSystemType.SaltoSpace,
            SiteSystemType.Assa,
            SiteSystemType.Butterfly,
          ].includes(site.account.systemType) ? (
            <button
              className={`btn btn-block btn-default`}
              onClick={() => this.addLockForSite(site)}
            >
              CSV Lock Import
            </button>
          ) : (
            <></>
          )}
        </React.Fragment>
        <React.Fragment>
          {site &&
            site.account &&
            site.account.systemType === SiteSystemType.SchlageEngage &&
            site.propertyExternalId && (
              <button
                type="button"
                disabled={site.syncInProgress}
                className={`btn btn-block btn-default`}
                onClick={() => this.syncACG(site.id)}
                style={{ marginBottom: "5px" }}
              >
                Map Locks
              </button>
            )}
        </React.Fragment>
        {site.syncMessage ? (
          <div
            className={
              site.syncSuccess
                ? "alert alert-success alert-dismissible"
                : "alert alert-danger alert-dismissible"
            }
            role="alert"
          >
            <span>{site.syncMessage}</span>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>
    );
  };

  private renderSitesTable() {
    return (
      <div>
        <p />
        {
          //@ts-ignore
          <SelectSite
            pageSize={5}
            renderButton={this.renderSiteButton}
            unselectedLabel="Delete"
            unselectedBtnClass="btn-danger"
          />
        }
        <hr />
      </div>
    );
  }

  private renderSelectUserButton = (clientUser: ConnectUserResponse) => {
    return (
      <div>
        <button
          type="button"
          className={`btn btn-primary`}
          onClick={() =>
            this.setState({ selectingUser: false, selectedUser: clientUser })
          }
        >
          Select
        </button>
        <p />
      </div>
    );
  };

  renderRegisterUserWrapper = () => {
    const { selectedUser, selectingUser } = this.state;
    return (
      <div>
        {(!selectedUser || selectingUser) && (
          <>
            <h3>Please Select a User</h3>

            <button
              type="button"
              className="btn btn-default"
              onClick={() =>
                this.state.selectedUser &&
                this.state.selectedUser.externalId &&
                this.setState({ selectingUser: false })
              }
            >
              Done selecting user
            </button>
            <p />

            <div className="form-group">
              <label htmlFor="clientUserId">Email</label>
              <input
                className="form-control"
                name="clientUserId"
                placeholder="email"
                value={selectedUser ? selectedUser.externalId : ""}
                style={{ fontSize: 23 }}
                onChange={(e) =>
                  this.setState({
                    selectedUser: {
                      externalId: e.target.value,
                      name: e.target.value,
                    },
                  })
                }
              />
              <p className="help-block">
                Can use this for dashboard @livly.io users
              </p>
            </div>

            <h3>Or</h3>
            {
              //@ts-ignore
              <SelectClientUser
                pageSize={5}
                renderButton={this.renderSelectUserButton}
              />
            }
          </>
        )}
        {!selectingUser && (
          <>
            <button
              type="button"
              className="btn btn-default"
              onClick={() =>
                this.setState({ selectingUser: true, selectedUser: null })
              }
            >
              Change User
            </button>
            {this.renderRegisterUser()}
          </>
        )}
      </div>
    );
  };

  renderRegisterUser = () => {
    const { selectedSite } = this.state;
    if (!selectedSite || !selectedSite.account) return null;
    switch (selectedSite.account.systemType) {
      case SiteSystemType.Assa:
        return this.renderRegisterAssaUser();
      case SiteSystemType.Openpath:
        return this.renderRegisterOpenPathUser();
      case SiteSystemType.Butterfly:
        return this.renderRegisterButterflyUser();
      case SiteSystemType.Stratis:
        return this.renderRegisterSiteUser();
      default:
        return <div>Adding a user not yet supported</div>;
    }
  };

  renderRegisterAssaUser = () => {
    const {
      selectedUser,
      resultEndpointId,
      resultInviteCode,
      selectedSite,
      inProgress,
    } = this.state;

    const { inviteAssaUser: inviteAssaUserInProgress = false } = (inProgress ||
      {}) as any;

    return (
      <div>
        <h5>Invite Assa User - Endpoint and Invitation Code</h5>
        <hr />
        <form>
          <div className="form-group">
            <label htmlFor="clientUserId">User</label>
            <input
              className="form-control"
              name="clientUserId"
              placeholder="user id"
              value={selectedUser ? selectedUser.name : "please select a user"}
              disabled
              style={{ fontSize: 23 }}
              onChange={(e) => {}}
            />
          </div>

          <div className="form-group">
            <label htmlFor="siteId">Site Id *</label>
            <input
              className="form-control"
              name="site id"
              placeholder="siteId"
              disabled
              value={selectedSite ? selectedSite.id : ""}
              onChange={(e) => {}}
            />
          </div>

          <div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={inviteAssaUserInProgress}
              onClick={this.inviteAssaUser}
            >
              Invite Assa User
            </button>
          </div>
          <div>Got Endpoint Id: {resultEndpointId} </div>
          <div>Got Invite Code: {resultInviteCode} </div>
        </form>
      </div>
    );
  };

  renderRegisterButterflyUser = () => {
    const { selectedUser, systemUserId, selectedSite, inProgress } = this.state;

    const { butterflyUser: butterflyUserInProgress = false } = (inProgress ||
      {}) as any;

    return (
      <div>
        <h5>Register Butterfly User</h5>
        <hr />
        <form>
          <div className="form-group">
            <label htmlFor="clientUserId">User</label>
            <input
              className="form-control"
              name="clientUserId"
              placeholder="user id"
              value={selectedUser ? selectedUser.name : "please select a user"}
              disabled
              style={{ fontSize: 23 }}
              onChange={(e) => {}}
            />
          </div>

          <div className="form-group">
            <label htmlFor="systemUserId">System User Id *</label>
            <input
              className="form-control"
              name="systemUserId"
              placeholder="system user id"
              value={systemUserId}
              onChange={(e) => this.setState({ systemUserId: e.target.value })}
            />
            <p className="help-block">(Try 7011 or 7012 - Butterfly user id)</p>
          </div>

          <div className="form-group">
            <label htmlFor="siteId">Site Id *</label>
            <input
              className="form-control"
              name="site id"
              placeholder="siteId"
              disabled
              value={selectedSite ? selectedSite.id : ""}
              onChange={(e) => {}}
            />
          </div>

          <div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={butterflyUserInProgress}
              onClick={this.registerButterflyUser}
            >
              Register Butterfly User
            </button>
          </div>
        </form>
      </div>
    );
  };

  renderRegisterOpenPathUser = () => {
    const {
      selectedUser,
      systemUserId,
      credentialId,
      selectedSite,
      inProgress,
    } = this.state;

    const { openPathUser: openPathUserInProgress = false } = (inProgress ||
      {}) as any;

    return (
      <div>
        <h5>Register OpenPath User - map to OpenPath IDs</h5>
        <hr />
        <form>
          <div className="form-group">
            <label htmlFor="clientUserId">User</label>
            <input
              className="form-control"
              name="clientUserId"
              placeholder="user id"
              value={selectedUser ? selectedUser.name : "please select a user"}
              disabled
              style={{ fontSize: 23 }}
              onChange={(e) => {}}
            />
          </div>

          <div className="form-group">
            <label htmlFor="systemUserId">System User Id *</label>
            <input
              className="form-control"
              name="systemUserId"
              placeholder="system user id"
              value={systemUserId}
              onChange={(e) => this.setState({ systemUserId: e.target.value })}
            />
            <p className="help-block">(Try 18965 - OpenPath user id)</p>
          </div>

          <div className="form-group">
            <label htmlFor="credentialId">CredentialId *</label>
            <input
              className="form-control"
              name="credentialId"
              placeholder="OpenPath credential id"
              value={credentialId}
              onChange={(e) => this.setState({ credentialId: e.target.value })}
            />
            <p className="help-block">(Try 5169 - OpenPath credential id)</p>
          </div>

          <div className="form-group">
            <label htmlFor="siteId">Site Id *</label>
            <input
              className="form-control"
              name="site id"
              placeholder="siteId"
              disabled
              value={selectedSite ? selectedSite.id : ""}
              onChange={(_e) => {}}
            />
          </div>

          <div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={openPathUserInProgress}
              onClick={this.registerOpenPathUser}
            >
              Register OpenPath User
            </button>
          </div>
        </form>
      </div>
    );
  };

  renderRegisterSiteUser = () => {
    const {
      selectedUser,
      systemUserId,
      credentialId,
      selectedSite,
      inProgress,
    } = this.state;

    const { openPathUser: openPathUserInProgress = false } = (inProgress ||
      {}) as any;

    return (
      <div>
        <h5>Register Site User - map to System IDs</h5>
        <hr />
        <form>
          <div className="form-group">
            <label htmlFor="clientUserId">User</label>
            <input
              className="form-control"
              name="clientUserId"
              placeholder="user id"
              value={selectedUser ? selectedUser.name : "please select a user"}
              disabled
              style={{ fontSize: 23 }}
              onChange={(e) => {}}
            />
          </div>

          <div className="form-group">
            <label htmlFor="systemUserId">System User Id *</label>
            <input
              className="form-control"
              name="systemUserId"
              placeholder="system user id"
              value={systemUserId}
              onChange={(e) => this.setState({ systemUserId: e.target.value })}
            />
          </div>

          <div className="form-group">
            <label htmlFor="credentialId">CredentialId</label>
            <input
              className="form-control"
              name="credentialId"
              placeholder="optional additional credential id"
              value={credentialId}
              onChange={(e) => this.setState({ credentialId: e.target.value })}
            />
          </div>

          <div className="form-group">
            <label htmlFor="siteId">Site Id *</label>
            <input
              className="form-control"
              name="site id"
              placeholder="siteId"
              disabled
              value={selectedSite ? selectedSite.id : ""}
              onChange={(_e) => {}}
            />
          </div>

          <div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={openPathUserInProgress}
              onClick={this.registerSiteUser}
            >
              Register Site User
            </button>
          </div>
        </form>
      </div>
    );
  };

  renderCsvUpload = () => {
    const { inProgress, siteId } = this.state;

    const { registerLock: registerLockInProgress = false } = (inProgress ||
      {}) as any;

    return (
      <CSVLockUploaderModal
        isDisabled={!!registerLockInProgress}
        siteId={String(siteId)}
        onHide={this.closeModal}
      />
    );
  };

  renderRegisterLock = () => {
    const { lockName, systemLockId, selectedSite, inProgress } = this.state;

    const { registerLock: registerLockInProgress = false } = (inProgress ||
      {}) as any;

    return (
      <form>
        <div className="form-group">
          <label htmlFor="lockName">Lock Name *</label>
          <input
            className="form-control"
            name="lockName"
            placeholder="lock name"
            value={lockName}
            onChange={(e) => this.setState({ lockName: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label htmlFor="siteId">Site Id *</label>
          <input
            className="form-control"
            name="site id"
            placeholder="siteId"
            disabled
            value={selectedSite ? selectedSite.id : ""}
            onChange={(e) => {}}
          />
        </div>

        <div className="form-group">
          <label htmlFor="systemLockId">System Lock Id *</label>
          <input
            className="form-control"
            name="systemLockId"
            placeholder="system lock id. e.g. CL0602"
            value={systemLockId}
            onChange={(e) => this.setState({ systemLockId: e.target.value })}
          />
          <p className="help-block">
            Try 101 for Assa, 215 for OpenPath, 3579 for Butterfly, "n/a" for
            Buzzly, Stratis or LivlySms
          </p>
        </div>

        <div>
          <button
            type="submit"
            disabled={!!registerLockInProgress}
            className="btn btn-primary"
            onClick={this.registerLock}
          >
            Register Lock
          </button>
        </div>
      </form>
    );
  };
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    sites: state.sites,
  }),
  {
    ...LocksState.actionCreators,
    ...SitesState.actionCreators,
    ...SiteUsersState.actionCreators,
  }
)(AdminSites);
