import * as React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as AllInvitesState from "../../store/AllInvites";
import * as Auth0State from "../../store/Auth0";
import { PageControl } from "../shared/PageControl";
import {
  formatLocalTime,
  inviteClassName,
  inviteStatus,
  confirmPrompt,
  defaultPageSize,
} from "../../helpers";
import { GuestInviteResponse } from "../../api/ApiClient";

import { debounce } from "lodash";
import { Spinner } from "../shared/Spinner";

interface Injected {
  allInvites: AllInvitesState.AllInvitesState;
  auth0: Auth0State.Auth0State;
}

type OwnProps = RouteComponentProps<{}>;

type Props = Injected & typeof AllInvitesState.fromMeActionCreators & OwnProps;

const timeFormat = "MMM Do, YYYY";

interface State {
  searchTerm: string;
}

class FromMeInvites extends React.Component<Props, State> {
  state = {
    searchTerm: "",
  };

  componentDidMount() {
    console.log("KeyRequests: Requesting key requests...");
    this.refresh();
  }

  componentDidUpdate(prevProps: Props) {
    const {
      auth0: {
        session: { accessToken: token },
      },
    } = this.props;
    const {
      auth0: {
        session: { accessToken: prevToken },
      },
    } = prevProps;

    if (token && token !== prevToken) {
      this.refresh();
    }
  }

  refresh = async () => {
    const {
      auth0: {
        session: { accessToken: token },
      },
    } = this.props;
    if (!token) {
      return;
    }

    try {
      const { searchTerm } = this.state;
      await this.props.setFetchParamsAllInvites({
        page: 0,
        pageSize: defaultPageSize,
        searchTerm,
      });
    } catch (e) {
      console.error("Error requesting invites", e);
      alert("Error requesting invites: " + JSON.stringify(e));
    }
  };

  handleTypedSearchTerm = (searchTerm: string) => {
    this.setState({ searchTerm }, this.searchInvite);
  };

  searchInvite = debounce(this.refresh, 1000);

  deleteInvite = (id: string) => {
    confirmPrompt({
      promptMessage: "Are you sure you want to delete this invite?",
      onConfirm: async () => {
        try {
          await this.props.deleteInvite({
            id,
          });
          //alert("Successfully deleted keyRequest: " + JSON.stringify(json));
        } catch (e) {
          alert("Error deleting invite: " + JSON.stringify(e));
        }
      },
    });
  };

  public render() {
    const { searchTerm, isLoading } = this.props.allInvites;
    const { searchTerm: typedSearchTerm } = this.state;

    return (
      <div>
        <p />
        <div className="form-group">
          <label htmlFor="typedSearchTerm">
            <span aria-label="search" role="img">
              🔎
            </span>{" "}
            Search For Invite
          </label>
          <input
            className="form-control"
            name="typedSearchTerm"
            placeholder="type here"
            value={typedSearchTerm}
            onChange={(e) => this.handleTypedSearchTerm(e.target.value)}
          />
        </div>
        <h4>{searchTerm ? `Invites Matching '${searchTerm}'` : "Invites"}</h4>
        <button disabled={isLoading} onClick={this.refresh}>
          {isLoading ? "Loading..." : "Refresh"}
        </button>
        <div style={{ height: 30 }}>{isLoading && <Spinner />}</div>
        {this.renderKeyRequestsTable()}
      </div>
    );
  }

  private renderLocks(keyRequest: GuestInviteResponse) {
    const locks = keyRequest ? keyRequest.lockIds || [] : [];
    const maxToListOut = 3;
    const listedOutStr = locks
      .map((l) => (l.lock ? l.lock.name : ""))
      .slice(0, maxToListOut)
      .join(", ");
    if (locks.length > maxToListOut) {
      return (
        <span>
          <b>{listedOutStr}</b>
          {`  + ${locks.length - maxToListOut} more`}
        </span>
      );
    } else {
      return <b>{listedOutStr}</b>;
    }
  }

  private renderKeyRequestsTable() {
    const now = new Date();
    const { isLoading } = this.props.allInvites;

    const pageControl = (
      <PageControl
        onNext={this.props.nextAllInvitesPage}
        onPrev={this.props.prevAllInvitesPage}
        currentPage={this.props.allInvites.page}
        isLoading={isLoading}
        disablePrev={this.props.allInvites.page === 0}
        disableNext={
          this.props.allInvites.allInvites.length <
          this.props.allInvites.pageSize
        }
      />
    );

    return (
      <div>
        {this.props.allInvites.allInvites.length > 10 && (
          <div>
            <p />
            {pageControl}
          </div>
        )}
        <table className="table table-hover">
          <thead>
            <tr>
              <th></th>
              <th>Unit(s)</th>
              <th></th>
              <th>To</th>
            </tr>
          </thead>
          <tbody>
            {this.props.allInvites.allInvites.map((keyRequest, i) => (
              <tr key={"f_" + i}>
                <td>
                  <p />
                  <Link to={`/myInvite?id=${keyRequest.id}`}>
                    <small>View Detail</small>
                  </Link>
                  <br />
                  <small className={`text-${inviteClassName(now, keyRequest)}`}>
                    {inviteStatus(now, keyRequest)}
                  </small>
                  <br />
                  <button
                    className="btn btn-danger"
                    onClick={() => this.deleteInvite(keyRequest.id || "")}
                  >
                    Delete
                  </button>
                </td>
                <td>
                  {this.renderLocks(keyRequest)}
                  {keyRequest.note && (
                    <p className="help-block">
                      Your private note to yourself: <br />
                      <b>{keyRequest.note}</b>
                    </p>
                  )}
                </td>
                <td>
                  {keyRequest.fromGuestId || ""}
                  <p />
                  <small>
                    Invited:{" "}
                    {formatLocalTime(
                      keyRequest.createdTimestamp as any,
                      timeFormat
                    )}
                  </small>
                </td>
                <td>{keyRequest.guestId}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {pageControl}
      </div>
    );
  }
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    allInvites: state.allInvites,
    auth0: state.auth0,
  }),
  { ...AllInvitesState.fromMeActionCreators }
)(FromMeInvites);
