import * as React from "react";
import { apiClientFactory } from "../../helpers";
import { KeyRequestCredentialType, LockResponse } from "../../api/ApiClient";
import { Spinner } from "../shared/Spinner";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

interface Injected {}

type OwnProps = {
  accessControlGroupId: string;
};

type Props = Injected & OwnProps;

interface EnrichedLockResponse extends LockResponse {
  assigned: boolean;
}

interface GroupedLocks {
  siteId: string;
  siteName: string;
  locks: EnrichedLockResponse[];
}

interface LockListBySiteProps {
  groupedLocks: GroupedLocks;
  searchTerm: string;
  accessControlGroupId: string;
  refresh: () => Promise<void>;
}

function LockListBySite(props: LockListBySiteProps) {
  const factory = apiClientFactory();
  const [expanded, setExpanded] = useState<boolean>(false);

  const [assignedLocks, setAssignedLocks] = useState<EnrichedLockResponse[]>(
    []
  );
  const [availableLocks, setAvailableLocks] = useState<EnrichedLockResponse[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleAddLock = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    accessControlGroupId: string,
    lockExternalId: string,
    siteId: string
  ): Promise<void> => {
    setIsLoading(true);
    e.preventDefault();
    await factory.addLockToAccessControlGroup(accessControlGroupId, {
      externalId: lockExternalId,
      siteId: siteId,
      credentialType: KeyRequestCredentialType.MobileKey,
    });
    await props.refresh();
    setIsLoading(false);
  };

  const handleDeleteLock = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    accessControlGroupId: string,
    lockId: string
  ): Promise<void> => {
    setIsLoading(true);
    e.preventDefault();
    await factory.deleteLockFromAccessControlGroup(
      accessControlGroupId,
      lockId
    );
    await props.refresh();
    setIsLoading(false);
  };

  useEffect(() => {
    const lockMatchesSearchTerm = (lock: EnrichedLockResponse): boolean => {
      return (
        (lock.name !== undefined &&
          lock.name.toLowerCase().includes(props.searchTerm.toLowerCase())) ||
        (lock.systemLockId !== undefined &&
          lock.systemLockId
            .toLowerCase()
            .includes(props.searchTerm.toLowerCase())) ||
        (lock.doorType !== undefined &&
          lock.doorType.toLowerCase().includes(props.searchTerm.toLowerCase()))
      );
    };

    setIsLoading(true);
    setExpanded(
      props.groupedLocks.locks.findIndex(
        (lock) =>
          lock.assigned === true ||
          (props.searchTerm !== "" && lockMatchesSearchTerm(lock))
      ) !== -1
    );
    setAssignedLocks(
      props.groupedLocks.locks.filter(
        (lock) =>
          lock.assigned &&
          (props.searchTerm === "" || lockMatchesSearchTerm(lock))
      )
    );
    setAvailableLocks(
      props.groupedLocks.locks.filter(
        (lock) =>
          !lock.assigned &&
          (props.searchTerm === "" || lockMatchesSearchTerm(lock))
      )
    );
    setIsLoading(false);
  }, [props.searchTerm, props.groupedLocks]);

  return (
    <div className="panel panel-default">
      <div className="panel-heading" role="tab" id="headingOne">
        <h4 className="panel-title">
          {props.groupedLocks.siteName}
          <button
            className="btn btn-default btn-xs"
            data-toggle="collapse"
            data-parent="#accordion"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
            onClick={(e) => setExpanded(!expanded)}
          >
            <i
              className={expanded ? "fa fa-minus" : "fa fa-plus"}
              aria-hidden="true"
            ></i>
          </button>
        </h4>
      </div>
      <div
        id="collapseOne"
        className={
          expanded ? "panel-collapse collapse in" : "panel-collapse collapse"
        }
        role="tabpanel"
        aria-labelledby="headingOne"
      >
        <div className="panel-body">
          <h5>Assigned Locks</h5>
          <table className="table table-hover">
            <thead>
              <tr>
                <th />
                <th>Name</th>
                <th>Door Type</th>
                <th>SystemLockId</th>
              </tr>
            </thead>
            <tbody>
              {assignedLocks.map((lock, i) => (
                <tr key={"f_" + i}>
                  <td>
                    <button
                      disabled={isLoading}
                      className="btn btn-danger"
                      onClick={(e) =>
                        handleDeleteLock(
                          e,
                          props.accessControlGroupId,
                          lock.id as string
                        )
                      }
                    >
                      Remove
                    </button>
                  </td>
                  <td>{lock.name}</td>
                  <td>{lock.doorType}</td>
                  <td>{lock.systemLockId}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <h5>Available Locks</h5>
          <table className="table table-hover">
            <thead>
              <tr>
                <th />
                <th>Name</th>
                <th>Door Type</th>
                <th>SystemLockId</th>
              </tr>
            </thead>
            <tbody>
              {availableLocks.map((lock, i) => (
                <tr key={"f_" + i}>
                  <td>
                    <button
                      disabled={isLoading}
                      className="btn btn-success"
                      onClick={(e) =>
                        handleAddLock(
                          e,
                          props.accessControlGroupId,
                          lock.externalId as string,
                          lock.siteId as string
                        )
                      }
                    >
                      Add
                    </button>
                  </td>
                  <td>{lock.name}</td>
                  <td>{lock.doorType}</td>
                  <td>{lock.systemLockId}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function useGetLocks(accessControlGroupId: string) {
  const factory = apiClientFactory();

  const getLocks = async () => {
    const [assignedLocks, locks] = await Promise.all([
      factory.getLocksForAccessControlGroup(
        accessControlGroupId,
        undefined,
        2147483647,
        undefined
      ),
      factory.getLocks(undefined, 2147483647, undefined, undefined),
    ]);

    const enrichedLocks: EnrichedLockResponse[] = locks
      .map((e) => {
        const assigned =
          assignedLocks.findIndex(
            (assignedLock) =>
              assignedLock.lock !== undefined && assignedLock.lock.id === e.id
          ) !== -1;
        return {
          ...e,
          assigned: assigned,
          selected: assigned,
          matched: false,
        };
      })
      .sort((a, b) =>
        (a.name ? a.name : "") > (b.name ? b.name : "") ? 1 : -1
      );
    const groupedLocks = enrichedLocks.reduce((rv, x) => {
      let entry = rv.find((e) => e.siteId === x.siteId);
      if (!entry) {
        entry = {
          siteId: x.siteId ? x.siteId : "",
          siteName: x.site && x.site.name ? x.site.name : "",
          locks: [],
        };
        rv.push(entry);
      }
      entry.locks.push(x);
      return rv;
    }, [] as GroupedLocks[]);

    return groupedLocks;
  };

  return useQuery(["access-group-locks", accessControlGroupId], getLocks);
}

function SelectAccessControlGroupLock(props: Props) {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { accessControlGroupId } = props;

  const {
    isLoading,
    data: groupedLocks,
    refetch,
  } = useGetLocks(accessControlGroupId);

  const refresh = async () => {
    await refetch();
  };

  const progressStyle: React.CSSProperties = {
    width: "100%",
  };

  return (
    <div>
      <h4>
        {searchTerm ? `Locks Matching '${searchTerm}'` : "Locks"}
        {isLoading && <Spinner />}
      </h4>
      {isLoading ? (
        <div className="jumbotron">
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped active"
              role="progressbar"
              aria-valuenow={100}
              style={progressStyle}
            >
              Loading
            </div>
          </div>
        </div>
      ) : (
        <div className="form-group">
          <label htmlFor="typedAccount">
            <span aria-label="search" role="img">
              🔎
            </span>{" "}
            Search For Lock
          </label>
          <input
            className="form-control"
            name="typedAccount"
            placeholder="type here"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      )}

      <div
        className="panel-group"
        id="accordion"
        role="tablist"
        aria-multiselectable="true"
      >
        {(groupedLocks ? groupedLocks : []).map((groupedLock) => (
          <LockListBySite
            key={groupedLock.siteId}
            groupedLocks={groupedLock}
            searchTerm={searchTerm}
            accessControlGroupId={accessControlGroupId}
            refresh={refresh}
          />
        ))}
      </div>
    </div>
  );
}

export default SelectAccessControlGroupLock;
