import { useEffect, useState } from "react";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { apiClientFactory } from "../helpers";

import {
  AccessControlGroupResponse,
  ApiException,
  OnboardUnitInfo,
} from "../api/ApiClient";
import moment from "moment";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function today() {
  const date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

function oneYearFromToday() {
  const date = today();
  date.setFullYear(date.getFullYear() + 1);
  return date;
}

function OnboardGuest() {
  // Declare a new state variable, which we'll call "count"
  const [loading, setLoading] = useState<boolean>(false);
  const [existingUser, setExistingUser] = useState<boolean>(false);
  const [accessControlGroups, setAccessControlGroups] = useState<
    AccessControlGroupResponse[]
  >([]);
  const [id, setId] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [validFrom, setValidFrom] = useState<Date>(today());
  const [validTo, setValidTo] = useState<Date>(oneYearFromToday());
  const history = useHistory();

  const location = useLocation();

  const { siteId } = useParams();
  const query = useQuery();
  const guestId = (function () {
    let t1 = query.get("guestId");
    t1 = t1 ? t1 : "";
    return t1;
  })();

  const fetchStuff = async function () {
    setLoading(true);
    const sites = await apiClientFactory().getSites(
      0,
      1000,
      undefined,
      undefined
    );

    if (sites && sites.length > 0) {
      const filteredSites = sites.filter((e) => e.id === siteId);
      if (filteredSites && filteredSites.length > 0) {
        const filteredSite = filteredSites[0];
        if (
          filteredSite &&
          filteredSite.account &&
          filteredSite.account.tenantId
        ) {
          const tenantId = filteredSite.account.tenantId;
          const accessControlGroups =
            await apiClientFactory().getAccessControlGroups(
              0,
              1000,
              undefined,
              undefined,
              undefined
            );
          if (accessControlGroups && accessControlGroups.length > 0) {
            const tenantAccessControlGroups = accessControlGroups.filter(
              (e) =>
                e.tenants &&
                e.tenants.length > 0 &&
                e.tenants.map((e2) => e2.tenantId).indexOf(tenantId) > -1
            );
            setAccessControlGroups(tenantAccessControlGroups);
          }
        }
      }
    }
    if (guestId !== "") {
      setExistingUser(true);
      setId(guestId);
      const guest = await apiClientFactory().getSiteGuest(siteId, guestId);
      if (guest) {
        if (guest.firstName) {
          setFirstName(guest.firstName);
        }
        if (guest.lastName) {
          setLastName(guest.lastName);
        }
        if (guest.accessControlGroups && guest.accessControlGroups.length > 0) {
          setSelectedGroups(
            guest.accessControlGroups.map((e) => e.id) as string[]
          );
        }
        if (guest.validFrom) {
          setValidFrom(new Date(guest.validFrom));
        }
        if (guest.validTo) {
          setValidTo(new Date(guest.validTo));
        }
      }
    }
    //const response = await myApiClient(siteId, guestId);
    setLoading(false);
  };

  const submit = async function (event: React.FormEvent) {
    setError("");
    event.preventDefault();
    const newValidFrom = moment(validFrom).format("YYYY-MM-DDTHH:mm:ss");
    const newValidTo = moment(validTo).format("YYYY-MM-DDTHH:mm:ss");
    setLoading(true);
    const acgs = accessControlGroups.filter(
      (e) => selectedGroups.indexOf(e.id as string) >= 0
    );
    let unitAcgs: OnboardUnitInfo[] = acgs
      .filter((e) => e.unitNumber && e.unitNumber.length > 0)
      .map((e) => {
        return {
          unitUniqueName: e.unitNumber ? e.unitNumber : "n/a",
          buildingUniqueName: e.propertyExternalId
            ? e.propertyExternalId
            : "n/a",
          validFrom: newValidFrom,
          validTo: newValidTo,
        };
      });
    const additionalGroups = acgs.filter(
      (e) => !e.unitNumber || e?.unitNumber?.length === 0
    );
    additionalGroups.forEach((e) => {
      let unitAcg: OnboardUnitInfo | undefined;
      unitAcg = unitAcgs.find(
        (e2) => e.propertyExternalId === e2.buildingUniqueName
      );
      if (!unitAcg) {
        unitAcg = {
          unitUniqueName: "n/a",
          buildingUniqueName: e.propertyExternalId
            ? e.propertyExternalId
            : "n/a",
          validFrom: newValidFrom,
          validTo: newValidTo,
        };
        unitAcgs = [...unitAcgs, unitAcg];
      }
      unitAcg.groups = unitAcg.groups
        ? [
            ...unitAcg.groups,
            { groupUniqueName: e.externalId ? e.externalId : "" },
          ]
        : [{ groupUniqueName: e.externalId ? e.externalId : "" }];
    });

    try {
      await apiClientFactory().createOnboardInvite({
        validFrom: newValidFrom,
        validTo: newValidTo,
        attemptUpdatingExistingAssaEndpoints: false,
        guestId: id,
        guestFirstName: firstName,
        guestLastName: lastName,
        units: unitAcgs,
      });
      history.push({
        pathname: `/admin/sites/${siteId ? siteId : ""}/guests/${
          guestId ? encodeURIComponent(guestId) : ""
        }`,
      });
    } catch (error) {
      const apiError = error as ApiException;
      if (apiError) {
        setError(apiError.message);
      }
    }
    setLoading(false);
  };

  const progressStyle: React.CSSProperties = {
    width: "100%",
  };

  useEffect(() => {
    (async () => {
      fetchStuff();
    })();
    // eslint-disable-next-line
  }, [location]);
  return (
    <main className="container">
      {error !== "" ? (
        <div className="alert alert-danger">{error}</div>
      ) : (
        <React.Fragment />
      )}
      {loading ? (
        <div className="panel-body">
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped active"
              role="progressbar"
              aria-valuenow={100}
              style={progressStyle}
            >
              Loading
            </div>
          </div>
        </div>
      ) : (
        <div className="panel">
          <form className="form" onSubmit={(e) => submit(e)}>
            {!existingUser ? (
              <div className="form-group">
                <label htmlFor="guestId">ID: (email, phone)</label>
                <input
                  type="text"
                  id="guestId"
                  className="form-control"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                />
              </div>
            ) : (
              <React.Fragment />
            )}
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                className="form-control"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                className="form-control"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="validFrom">Valid From</label>
              <input
                type="datetime-local"
                id="validFrom"
                className="form-control"
                value={
                  validFrom
                    ? moment(validFrom).format("YYYY-MM-DDTHH:mm:ss")
                    : ""
                }
                onChange={(e) => {
                  const newdate = moment(e.target.value).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  );
                  setValidFrom(new Date(newdate));
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="validTo">Valid To</label>
              <input
                type="datetime-local"
                id="validTo"
                className="form-control"
                value={
                  validTo ? moment(validTo).format("YYYY-MM-DDTHH:mm:ss") : ""
                }
                onChange={(e) => {
                  const newdate = moment(e.target.value).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  );
                  setValidTo(new Date(newdate));
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="acgList">Access Control Groups</label>
              {accessControlGroups && accessControlGroups.length > 0 ? (
                accessControlGroups.map((e) => (
                  <div key={e.id} className="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedGroups.indexOf(e.id as string) >= 0}
                        onChange={(ev) =>
                          ev.target.checked
                            ? setSelectedGroups([
                                ...selectedGroups,
                                e.id,
                              ] as string[])
                            : setSelectedGroups(
                                selectedGroups.filter((e1) => e1 !== e.id)
                              )
                        }
                      />
                      <p>{e.name}</p>
                      <p>
                        <sub>
                          {e.propertyExternalId} :{" "}
                          {e.unitNumber ? e.unitNumber : "-"} : {e.type}
                        </sub>
                      </p>
                    </label>
                  </div>
                ))
              ) : (
                <div className="alert alert-warning">
                  No Access Control Groups Found
                </div>
              )}
            </div>

            <div className="form-group">
              <input
                type="submit"
                className="btn btn-block btn-primary"
                disabled={
                  (selectedGroups.length === 0 && !existingUser) ||
                  id.length === 0
                }
                value={existingUser ? "Update" : "Onboard"}
              />
            </div>
          </form>
        </div>
      )}
    </main>
  );
}
export default OnboardGuest;
