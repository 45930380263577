import { Action, Reducer } from "redux";
import { Auth0 as Auth0Service } from "../services/Auth0";
import { apiFetchParams } from "../helpers/api-fetch-params";

export interface Auth0Session {
  isLoading: boolean;
  accessToken: string | null;
  idToken: string | null;
  expiresAt: number;
}

export interface Auth0State {
  session: Auth0Session;
  service: Auth0Service | null;
}

interface SetAuth0SessionPayload {
  session: Auth0Session;
}
interface SetAuth0SessionAction extends SetAuth0SessionPayload {
  type: "SET_AUTH0_SESSION";
}

interface SetAuth0ServicePayload {
  service: Auth0Service;
}
interface SetAuth0ServiceAction extends SetAuth0ServicePayload {
  type: "SET_AUTH0_SERVICE";
}

// // This is only needed to prevent typescript errors with exhaustive check which seem to require > 1 action type
// interface DummyAction {
//   type: "DUMMY_ACTION";
// }

type KnownAction = SetAuth0SessionAction | SetAuth0ServiceAction;

export const actionCreators = {
  setAuth0Session: (info: SetAuth0SessionPayload) => {
    // side effect: update the global accessToken, used in myFetch
    apiFetchParams.accessToken = info.session
      ? info.session.accessToken || ""
      : "";

    return {
      type: "SET_AUTH0_SESSION",
      session: info.session,
    } as SetAuth0SessionAction;
  },
  setAuth0Service: (info: SetAuth0ServicePayload) =>
    ({
      type: "SET_AUTH0_SERVICE",
      service: info.service,
    } as SetAuth0ServiceAction),
};

const unloadedState: Auth0State = {
  session: {
    isLoading: true,
    accessToken: null,
    idToken: null,
    expiresAt: 0,
  },
  service: null,
};

export const reducer: Reducer<Auth0State> = (
  state: Auth0State = unloadedState,
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "SET_AUTH0_SESSION": {
      return {
        ...state,
        session: action.session,
      };
    }
    case "SET_AUTH0_SERVICE":
      return { ...state, service: action.service };
    default:
      return state;
  }
};
