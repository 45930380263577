import React, { useMemo, useState } from "react";
import toLocalTime from "utils/toLocalTime";
import { DefaultRootState, useSelector } from "react-redux";
import * as MeState from "../store/Me";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Popover,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Glyphicon, Radio } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "./logoLC.jpeg";
import { Spinner } from "./shared/Spinner";
import { SiteStatus } from "./SiteStatus";
import useGetSitesStatus, { sitesStatus } from "./hooks/useGetSitesStatus";
import useDebounce from "utils/useDebounce";

type Props = { me: MeState.MeState } & DefaultRootState;

export enum SiteStatusEnum {
  Offline = "Offline",
  Unknown = "Unknown",
  Online = "Online",
}

export default function Home() {
  const user = useSelector((state: Props) => state?.me?.user);
  const classes = useStyles();
  const [showSiteStatus, setShowSiteStatus] = useState(false);
  const { data = [], isLoading, refetch } = useGetSitesStatus();
  const [filter, setFilter] = React.useState("");
  const [fuzzySearch, setFuzzySearch] = React.useState("");

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: SiteStatusEnum) => {
    setFilter(event);
  };

  const open = Boolean(anchorEl);

  let timeOfDay;
  const date = toLocalTime(Date.now());

  const hours = date.hour();
  if (hours < 12) {
    timeOfDay = "Morning";
  } else if (hours >= 12 && hours < 17) {
    timeOfDay = "Afternoon";
  } else {
    timeOfDay = "Evening";
  }

  const debouncedSearch = useDebounce(fuzzySearch, 500);

  const filteredUsers = useMemo(() => {
    if (filter === "" && debouncedSearch === "") {
      return data;
    }
    if (debouncedSearch !== "") {
      setFilter("");
      let search = debouncedSearch.toLowerCase();
      return data.filter(
        (data) =>
          data.name.toLowerCase().match(search) ||
          data.siteName.toLowerCase().match(search)
      );
    }

    return data.filter((data) => data.status === filter);
  }, [data, filter, debouncedSearch]);

  type GroupByObject = { [key: string]: sitesStatus[] };

  const groppeddata = useMemo(() => {
    return filteredUsers.reduce((acc: GroupByObject, curr: sitesStatus) => {
      (acc[curr["siteName"]] = acc[curr["siteName"]] || []).push(curr);
      return acc;
    }, {});
  }, [filteredUsers]);

  return (
    <Box
      mt={4}
      mb={2}
      display="flex"
      minHeight={user ? "95vh" : "fit-content"}
      height={user ? "100%" : "fit-content"}
    >
      {user && (
        <>
          <Box width={"75%"}>
            <Box>
              <Typography variant="h4">Good {timeOfDay},</Typography>
              <Typography variant="h2">
                <b>{user?.name}</b>
              </Typography>
              <hr style={{ borderTop: "2px solid #eeeeee" }} />
            </Box>
            <Box mt={5}>
              <Box>
                <Typography variant="h4" className={classes.header}>
                  <Glyphicon
                    glyph="lock"
                    style={{ marginRight: "10px", color: "#1F2937" }}
                  />
                  Lock Automation Management
                </Typography>
                <Box className={classes.underLine} mt={2} />
              </Box>
              <Box mt={5}>
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <Link to="/admin/sites">
                      <div className={classes.contentSection}>
                        <Glyphicon
                          glyph="modal-window"
                          style={{ fontSize: "25px", color: "rgb(64 139 228)" }}
                        />
                        <Box mt={1}>
                          <Typography
                            variant="h5"
                            className={classes.contentPara}
                          >
                            <strong>Sites</strong>
                          </Typography>
                        </Box>
                      </div>
                    </Link>
                  </Grid>
                  <Grid item xs={4}>
                    <Link to="/admin/accessControlGroups">
                      <div className={classes.contentSection}>
                        <Glyphicon
                          glyph="cog"
                          style={{ fontSize: "25px", color: "rgb(64 139 228)" }}
                        />
                        <Box mt={1}>
                          <Typography
                            variant="h5"
                            className={classes.contentPara}
                          >
                            <strong>Access Control Groups</strong>
                          </Typography>
                        </Box>
                      </div>
                    </Link>
                  </Grid>

                  <Grid item xs={4}>
                    <Link to="/admin/locks">
                      <div className={classes.contentSection}>
                        <Glyphicon
                          glyph="lock"
                          style={{ fontSize: "25px", color: "rgb(64 139 228)" }}
                        />
                        <Box mt={1}>
                          <Typography
                            variant="h5"
                            className={classes.contentPara}
                          >
                            <strong>Locks</strong>
                          </Typography>
                        </Box>
                      </div>
                    </Link>
                  </Grid>
                  <Grid item xs={4}>
                    <Link to="/keyRequests">
                      <div className={classes.contentSection}>
                        <Glyphicon
                          glyph="list"
                          style={{ fontSize: "25px", color: "rgb(64 139 228)" }}
                        />
                        <Box mt={1}>
                          <Typography
                            variant="h5"
                            className={classes.contentPara}
                          >
                            <strong>Access Log</strong>
                          </Typography>
                        </Box>
                      </div>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {showSiteStatus && (
              <Box mt={8}>
                <Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Box>
                      <Typography variant="h4" className={classes.header}>
                        <Glyphicon
                          glyph="cog"
                          style={{ marginRight: "10px", color: "#1F2937" }}
                        />
                        Site Status
                      </Typography>
                      <Box className={classes.underLine} mt={2} />
                    </Box>
                    <Box
                      width={"35%"}
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box display={"flex"} marginRight={2}>
                        <IconButton onClick={handleClick}>
                          <Glyphicon
                            glyph="filter"
                            style={{ color: "black" }}
                          />
                        </IconButton>
                      </Box>
                      <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Paper elevation={3}>
                          <Box
                            p={2}
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"space-evenly"}
                          >
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              onClick={() =>
                                handleChange(SiteStatusEnum.Online)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <Radio
                                checked={filter === SiteStatusEnum.Online}
                                value={SiteStatusEnum.Online}
                                name="radio-buttons"
                                style={{ marginBottom: "5px" }}
                              />
                              <span>Online</span>
                            </Box>
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              onClick={() =>
                                handleChange(SiteStatusEnum.Offline)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <Radio
                                checked={filter === SiteStatusEnum.Offline}
                                value={SiteStatusEnum.Offline}
                                name="radio-buttons"
                                style={{ marginBottom: "5px" }}
                              />
                              <span>Offline</span>
                            </Box>
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              onClick={() =>
                                handleChange(SiteStatusEnum.Unknown)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <Radio
                                checked={filter === SiteStatusEnum.Unknown}
                                value={SiteStatusEnum.Unknown}
                                name="radio-buttons"
                                style={{ marginBottom: "5px" }}
                              />

                              <span>Unknown</span>
                            </Box>

                            <Box
                              mt={2}
                              display={"flex"}
                              justifyContent={"center"}
                            >
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                  setFilter("");
                                  handleClose();
                                }}
                              >
                                Clear
                              </Button>
                            </Box>
                          </Box>
                        </Paper>
                      </Popover>
                      <TextField
                        placeholder="search.."
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        size="small"
                        InputProps={{
                          className: classes.textField,
                        }}
                        onChange={(e) => setFuzzySearch(e.target.value)}
                      ></TextField>
                    </Box>
                  </Box>
                  {isLoading && (
                    <>
                      <Spinner />
                    </>
                  )}
                  {filteredUsers &&
                    filteredUsers.length > 0 &&
                    Object.keys(groppeddata).map((status, i) => (
                      <>
                        <Box mt={5} className={classes.statusHeader}>
                          <Box>Current status of {status}</Box>
                          <Box>
                            <Box
                              display={"flex"}
                              justifyContent={"space-evenly"}
                              alignItems={"center"}
                            >
                              <Box display={"flex"} marginRight={2}>
                                <Glyphicon
                                  glyph="ok-sign"
                                  style={{
                                    marginRight: "10px",
                                    color: "#2AAC76",
                                  }}
                                />
                                <span style={{ fontSize: "1.5rem" }}>
                                  Online
                                </span>
                              </Box>
                              <Box display={"flex"} marginRight={2}>
                                <Glyphicon
                                  glyph="minus-sign"
                                  style={{
                                    marginRight: "10px",
                                    color: "#CC2553",
                                  }}
                                />
                                <span style={{ fontSize: "1.5rem" }}>
                                  Offline
                                </span>
                              </Box>
                              <Box display={"flex"} marginRight={2}>
                                <Glyphicon
                                  glyph="warning-sign"
                                  style={{
                                    marginRight: "10px",
                                    color: "#E2AC37",
                                  }}
                                />
                                <span style={{ fontSize: "1.5rem" }}>
                                  Unknown
                                </span>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box className={classes.statusBody}>
                          {groppeddata[status].length > 0 &&
                            groppeddata[status].map((newStatus, index) => (
                              <Box
                                className={
                                  groppeddata[status].length > 1
                                    ? classes.statusBodyChild
                                    : classes.statusBodyChildFull
                                }
                                key={index}
                              >
                                <SiteStatus
                                  siteStatusData={newStatus}
                                  classes={classes}
                                />
                                {newStatus?.children &&
                                  newStatus?.children.length > 0 && (
                                    <Box mt={2}>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={
                                            <Glyphicon glyph="chevron-down" />
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                        >
                                          <Typography variant="h5">
                                            Children sites{" "}
                                          </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box
                                            display={"flex"}
                                            flexDirection={"column"}
                                            width={"100%"}
                                          >
                                            {newStatus?.children.map(
                                              (childStatus) => (
                                                <SiteStatus
                                                  siteStatusData={childStatus}
                                                  classes={classes}
                                                />
                                              )
                                            )}
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                    </Box>
                                  )}
                              </Box>
                            ))}
                        </Box>
                      </>
                    ))}
                </Box>
              </Box>
            )}
          </Box>
          <Box className={classes.profile}>
            <Box display={"flex"} flexDirection="column" alignItems={"center"}>
              <Box mb={8}>
                <Typography variant="h4">
                  <img
                    src={Logo}
                    alt="logo"
                    width={40}
                    style={{ marginRight: "10px" }}
                  />
                  <strong>LivlyConnect.</strong>
                </Typography>
                <Box className={classes.floatUnderline} mt={1} />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box mr={2}>
                  <Avatar>{user?.name && user?.name[0]}</Avatar>
                </Box>
                <Box>
                  <Typography variant="h5" className={classes.primaryTextBold}>
                    {user?.name}
                  </Typography>
                  <a href={`mailto:${user?.email}`}>
                    <Typography variant="h5" display="block" color="primary">
                      {user?.email}
                    </Typography>
                  </a>

                  <Typography variant="h5">
                    Super Admin: <b>{user?.superUser ? "Yes" : "No"}</b>
                  </Typography>
                </Box>
              </Box>
              <Box mt={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setShowSiteStatus(!showSiteStatus);
                    if (!showSiteStatus) {
                      refetch();
                    }
                  }}
                >
                  {showSiteStatus ? "Hide Site Status" : "Site Status"}{" "}
                </Button>
              </Box>
            </Box>
            <p>
              Follow us:{" "}
              <a href="https://twitter.com/LivlyConnect" rel="noopener">
                <i className="fa fa-twitter" aria-hidden="true"></i>
              </a>
            </p>
          </Box>
        </>
      )}
    </Box>
  );
}

const useStyles = makeStyles((_theme) => ({
  textField: {
    fontSize: "12px",
  },
  contentSection: {
    background: "#F3F4F6",
    padding: "2rem",
    borderRadius: "10px",
    boxShadow:
      "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
  },
  contentPara: {
    fontSize: "1.7rem",
  },
  header: {
    fontWeight: 900,
  },
  underLine: {
    width: "8rem",
    height: "0.25rem",
    background: "#5FA5F8",
  },
  floatUnderline: {
    float: "right",
    width: "8rem",
    height: "0.25rem",
    background: "#5FA5F8",
  },
  profile: {
    borderLeft: "2px solid rgb(238, 238, 238)",
    marginLeft: "20px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "25%",
    justifyContent: "space-between",
    paddingTop: "2rem",
  },
  primaryTextBold: {
    fontWeight: "bold",
  },
  statusHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "15px",
    backgroundColor: "#F5F5F5",
    border: "1px solid #E8E8E8",
    fontWeight: 600,
    fontSize: "2rem",
  },
  statusBody: {
    borderStyle: "solid",
    borderColor: "#E8E8E8",
    borderWidth: "1px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  statusBodyChildFull: {
    width: "100%",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderBottom: "1px solid #E8E8E8",
  },
  statusBodyChild: {
    width: "50%",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderBottom: "1px solid #E8E8E8",
    "&:nth-of-type(odd)": {
      borderRight: "1px solid #E8E8E8",
    },
  },
  statusChildren: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
}));
