import * as React from "react";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import * as Auth0State from "../../store/Auth0";
import { RouteComponentProps } from "react-router";
import { parse } from "query-string";

interface Injected {
  auth0: Auth0State.Auth0State;
}

type RouteProps = RouteComponentProps<{}>;

type OwnProps = RouteProps & {};

type Props = Injected & typeof Auth0State.actionCreators & OwnProps;

class _Auth0Callback extends React.Component<Props> {
  setAuth0Session = (authResult: any) => {
    this.props.setAuth0Session({
      session: { ...authResult, isLoading: false },
    });
  };

  handleAuth0Callback = (err: any, authResult: any) => {
    const { history } = this.props;
    if (err) {
      alert("Auth0 callback error: " + JSON.stringify(err));
      history.replace("/");
    } else if (authResult) {
      this.setAuth0Session(authResult);
      const { myOriginalPathname } = parse(window.location.search || "");
      if (myOriginalPathname) {
        history.replace(myOriginalPathname as string);
      } else {
        history.replace("/");
      }
    }
  };

  public render() {
    const { service, session } = this.props.auth0;
    console.log("IS LOADING?", { isLoading: session && session.isLoading });
    return service && !session.isLoading ? (
      <Auth0CallbackDo
        handleCallback={this.handleAuth0Callback}
        auth0={service}
      />
    ) : null;
  }
}

class Auth0CallbackDo extends React.Component<{
  auth0: any;
  handleCallback: (err: any, authResult: any) => any;
}> {
  componentDidMount() {
    this.checkCallbackForAuthInfo();
  }

  async checkCallbackForAuthInfo() {
    const { auth0, handleCallback } = this.props;
    try {
      console.log("GETTING callback info...");
      const authResult = await auth0.checkCallbackForAuthInfo(window.location);
      console.log("GETTING callback info DONE.");
      handleCallback(null, authResult);
    } catch (e) {
      handleCallback(e, null);
    }
  }

  render() {
    return <div>Callback loading...</div>;
  }
}

export const Auth0Callback = connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    auth0: state.auth0,
  }),
  {
    ...Auth0State.actionCreators,
  }
)(_Auth0Callback);
