import * as React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as LocksState from "../../store/Locks";
import SelectLock from "../shared/SelectLock";

import Modal from "react-bootstrap-modal";

import "./adminSites.css";
import { confirmPrompt } from "../../helpers";
import SelectSite from "../shared/SelectSite";
import {
  AccessControlDoorType,
  KeyRequestCredentialType,
  LockResponse,
  SiteResponse,
  SiteSystemType,
} from "../../api/ApiClient";
import { MenuItem, SplitButton } from "react-bootstrap";

type OwnProps = RouteComponentProps<{ startDateIndex: string }>;

interface Injected {
  locks: LocksState.LocksState;
}

type Props = Injected & typeof LocksState.actionCreators & OwnProps;

type ModalType = "lock" | null;

interface State {
  inProgress: boolean;
  showingModal: ModalType;
  //lock: LockResponse | null;
}

class AdminLocks extends React.Component<Props, State> {
  state: State = {
    inProgress: false,
    showingModal: null,
    //lock: null
  };

  showAddLockModal = () => {
    this.setState({ showingModal: "lock" });
  };

  closeModal = () => {
    this.setState({ showingModal: null });
  };

  onRegistered = async () => {
    this.closeModal();
    this.props.requestLocks();
  };

  renderRegisterLock = () => {
    //@ts-ignore
    return <RegisterLock onRegistered={this.onRegistered} />;
  };

  public render() {
    const { showingModal } = this.state;

    const button = (
      <button
        type="button"
        className="btn btn-primary"
        onClick={this.showAddLockModal}
      >
        Add Lock
      </button>
    );

    return (
      <div>
        <br />
        {button}
        <div>{this.renderLocksTable()}</div>

        <Modal
          show={!!showingModal}
          onHide={this.closeModal}
          aria-labelledby="ModalHeader"
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="ModalHeader">
              {showingModal === "lock" ? "Add Lock" : "Edit Lock"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showingModal === "lock" ? (
              this.renderRegisterLock()
            ) : (
              <React.Fragment />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Modal.Dismiss className="btn btn-default">Close</Modal.Dismiss>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  deleteLock = (lock: LockResponse) => {
    confirmPrompt({
      promptMessage: "Are you sure you want to delete this Lock?",
      onConfirm: async () => {
        try {
          await this.props.deleteLock({
            id: lock.id || "",
          });
          await this.props.requestLocks();
        } catch (e) {
          alert("Error deleting Lock: " + JSON.stringify(e));
        }
      },
    });
  };

  toggleFlag = (flag: number) => {
    const flags = this.props.locks.flags ? this.props.locks.flags : 0;
    if (flags && flags & flag) {
      this.props.editSyncFlags(flags - flag);
    } else {
      this.props.editSyncFlags(flags | flag);
    }
  };

  private renderLockButton = (lock: LockResponse) => {
    return (
      <div>
        <button
          type="button"
          className={`btn btn-danger`}
          onClick={() => this.deleteLock(lock)}
        >
          Delete
        </button>
        <Link to={`/admin/locks/${lock.id}/edit`} className="btn btn-default">
          Edit
        </Link>
        {lock &&
        lock.site &&
        lock.site.account &&
        lock.site.account.systemType === SiteSystemType.SchlageEngage ? (
          <React.Fragment>
            {lock.lastSeenTimeStamp && (
              <SplitButton
                disabled={this.props.locks.syncLockInProgress}
                bsStyle={"default"}
                title={"Sync"}
                id={`sync-split-button`}
                onClick={() => {
                  this.props.syncLock(
                    lock.id as string,
                    this.props.locks.flags
                  );
                }}
              >
                <MenuItem eventKey="1" onClick={() => this.toggleFlag(1)}>
                  {this.props.locks.flags &&
                  (this.props.locks.flags & 1) === 1 ? (
                    <span className="fa fa-check-circle"></span>
                  ) : (
                    <span className="fa fa-times-circle"></span>
                  )}
                  Audits
                </MenuItem>
                <MenuItem eventKey="2" onClick={() => this.toggleFlag(2)}>
                  {this.props.locks.flags &&
                  (this.props.locks.flags & 2) === 2 ? (
                    <span className="fa fa-check-circle"></span>
                  ) : (
                    <span className="fa fa-times-circle"></span>
                  )}
                  Config
                </MenuItem>
                <MenuItem eventKey="3" onClick={() => this.toggleFlag(4)}>
                  {this.props.locks.flags &&
                  (this.props.locks.flags & 4) === 4 ? (
                    <span className="fa fa-check-circle"></span>
                  ) : (
                    <span className="fa fa-times-circle"></span>
                  )}
                  Database
                </MenuItem>
              </SplitButton>
            )}
            <Link
              to={`/admin/locks/${lock.id}/events`}
              className="btn btn-default"
            >
              Events
            </Link>
            <Link
              to={`/admin/locks/${lock.id}/automation`}
              className="btn btn-default"
            >
              Automation
            </Link>
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
      </div>
    );
  };

  private renderLocksTable() {
    return (
      <div>
        <p />
        &nbsp;
        <p />
        {
          //@ts-ignore
          <SelectLock pageSize={5} renderButton={this.renderLockButton} />
        }
        <hr />
      </div>
    );
  }
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    locks: state.locks,
  }),
  {
    ...LocksState.actionCreators,
  }
)(AdminLocks);

type RegisterLockOwnProps = {
  lock: LockResponse;
  onRegistered: () => any;
};

type RegisterLockInjected = typeof LocksState.actionCreators;

type RegisterLockProps = RegisterLockInjected &
  typeof LocksState.actionCreators &
  RegisterLockOwnProps;

interface RegisterLockState {
  inProgress: boolean;

  //create lock
  externalId: string;
  name: string;
  systemLockId: string;
  credentialType: KeyRequestCredentialType;
  doorType: AccessControlDoorType;

  //select Site
  selectedSite: {
    id?: string;
    name?: string;
  } | null;
  selectingSite: boolean;
}

class RegisterLockComp extends React.Component<
  RegisterLockProps,
  RegisterLockState
> {
  state: RegisterLockState = {
    inProgress: false,

    //create lock
    externalId: "",
    name: "",
    systemLockId: "",
    doorType: AccessControlDoorType.Guest,
    credentialType: KeyRequestCredentialType.MobileKey,

    selectedSite: null,
    selectingSite: false,
  };

  private renderSelectSiteButton = (site: SiteResponse) => {
    return (
      <div>
        <button
          type="button"
          className={`btn btn-primary`}
          onClick={() =>
            this.setState({ selectingSite: false, selectedSite: site })
          }
        >
          Select
        </button>
        <p />
      </div>
    );
  };

  renderSelectSite = () => {
    const { selectedSite, selectingSite } = this.state;
    return (
      <div>
        {(!selectedSite || selectingSite) && (
          <>
            <h3>Please Select a Site</h3>
            {
              //@ts-ignore
              <SelectSite
                pageSize={5}
                renderButton={this.renderSelectSiteButton}
              />
            }
          </>
        )}
        {selectedSite && (
          <>
            <div>
              Selected Site:{" "}
              <b>
                {selectedSite.id} {selectedSite.name}
              </b>
            </div>
            <button
              type="button"
              className="btn btn-default"
              onClick={() =>
                this.setState({ selectingSite: true, selectedSite: null })
              }
            >
              Change Site
            </button>
            <p />
          </>
        )}
      </div>
    );
  };

  register = (e: any) => {
    e.preventDefault();

    this.setState(
      {
        inProgress: true,
      },
      async () => {
        const state = this.state;
        try {
          const json = (await this.props.registerLock({
            siteId: state.selectedSite ? state.selectedSite.id || "" : "",
            name: state.name,
            systemLockId: state.systemLockId,
            doorType: state.doorType,
          })) as any;
          alert("Successfully registered lock: " + JSON.stringify(json));
          this.props.onRegistered();
          this.setState({
            //clear inputs
            externalId: "",
            name: "",
            systemLockId: "",
            doorType: AccessControlDoorType.Guest,
            credentialType: KeyRequestCredentialType.MobileKey,
          });
        } catch (e) {
          alert("Error registering lock: " + JSON.stringify(e));
        } finally {
          this.setState({
            inProgress: false,
          });
        }
      }
    );
  };

  render() {
    const { inProgress, name, systemLockId, doorType } = this.state;

    return (
      <form>
        <div className="form-group">
          <label htmlFor="name">Name *</label>
          <input
            className="form-control"
            name="name"
            placeholder="Name"
            value={name}
            onChange={(e) => {
              this.setState({ name: e.target.value });
            }}
          />
        </div>

        {this.renderSelectSite()}

        <div className="form-group">
          <label htmlFor="systemLockId">System Lock Id *</label>
          <input
            className="form-control"
            name="systemLockId"
            placeholder="SystemLockId"
            value={systemLockId}
            onChange={(e) => {
              this.setState({ systemLockId: e.target.value });
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="doorType">Door Type</label>
          <select
            className="form-control"
            name="doorType"
            value={doorType}
            onChange={(e) => {
              let doorType = e.target.value as AccessControlDoorType;
              this.setState({ doorType: doorType });
            }}
          >
            {Object.keys(AccessControlDoorType)
              .filter((k) => isNaN(Number(k)))
              .map((k, i) => (
                <option key={k} value={i}>
                  {k}
                </option>
              ))}
          </select>
        </div>
        <div>
          <button
            type="submit"
            disabled={!!inProgress}
            className="btn btn-primary"
            onClick={this.register}
          >
            Register Lock
          </button>
        </div>
      </form>
    );
  }
}

const RegisterLock = connect<any, any, RegisterLockOwnProps, ApplicationState>(
  (state) => ({
    locks: state.locks,
  }),
  LocksState.actionCreators
)(RegisterLockComp);
