import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";
import {
  FetchParams,
  reduceSetFetchParams,
  defaultPageSize,
  reduceNextPage,
  reducePrevPage,
  apiClientFactory,
  LockIdentifier,
} from "../helpers";
import {
  KeyRequestResponse,
  KeyRequestCredentialType,
  KeyChainRequest,
} from "../api/ApiClient";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface KeyRequestsState extends FetchParams {
  isLoading: boolean;
  keyRequests: KeyRequestResponse[];
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestKeyRequestsAction {
  type: "REQUEST_KEYREQUESTS";
}

interface SetFetchParams extends FetchParams {
  type: "SETFETCHPARAMS_KEYREQUESTS";
}

interface NextPage {
  type: "NEXTPAGE_KEYREQUESTS";
}

interface PrevPage {
  type: "PREVPAGE_KEYREQUESTS";
}

interface ReceiveKeyRequestsAction extends FetchParams {
  type: "RECEIVE_KEYREQUESTS";
  keyRequests: KeyRequestResponse[];
}

interface DeleteKeyRequestActionPayload {
  id: string;
}
interface DeleteKeyRequestAction extends DeleteKeyRequestActionPayload {
  type: "DELETE_KEYREQUEST";
}

interface PostKeyRequestActionPayload {
  destinationEmail?: string;
  destinationPhone?: string;
  pinCode?: string;
  lockSlot?: string;
  validFrom?: Date;
  validTo?: Date;
  lockIds: string[];
  credentialType: KeyRequestCredentialType;
}
interface PostKeyRequestAction extends PostKeyRequestActionPayload {
  type: "POST_KEY_REQUEST";
}

interface PostKeyRequestFreeFormActionPayload {
  destinationEmail?: string;
  destinationPhone?: string;
  pinCode?: string;
  lockSlot?: string;
  validFrom?: Date;
  validTo?: Date;
  description: string;
  lockIdentifiers: LockIdentifier[];
  siteId: string;
  credentialType: KeyRequestCredentialType;
}
interface PostKeyRequestFreeFormAction
  extends PostKeyRequestFreeFormActionPayload {
  type: "POST_KEY_REQUEST_FREE_FORM";
}

interface GetPinActionPayload {
  lockIds: string[];
}
interface GetPinAction extends GetPinActionPayload {
  type: "GET_PIN";
}

interface GuestCreateInviteActionPayload {
  guestSms: string;
  details: string;
  note: string;
  destinationEmail?: string;
  destinationPhone?: string;
  pinCode?: string;
  lockSlot?: string;
  validFrom?: Date;
  validTo?: Date;
  lockIds: string[];
  credentialType: KeyRequestCredentialType;
}

interface GuestCreateInviteAction extends GuestCreateInviteActionPayload {
  type: "GUEST_CREATE_INVITE";
}

interface InviteFromInvite extends GuestCreateInviteActionPayload {
  fromInviteId: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
  | RequestKeyRequestsAction
  | SetFetchParams
  | NextPage
  | PrevPage
  | ReceiveKeyRequestsAction
  | DeleteKeyRequestAction
  | PostKeyRequestFreeFormAction
  | PostKeyRequestAction
  | GuestCreateInviteAction
  | GetPinAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

function createActionCreators({ fromMeMode } = { fromMeMode: false }) {
  const actionCreators = {
    setFetchParamsKeyRequests:
      ({
        page,
        pageSize,
        searchTerm,
      }: FetchParams): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        const state = getState();
        if (state.keyRequests.isLoading) return;

        dispatch({
          type: "SETFETCHPARAMS_KEYREQUESTS",
          page,
          pageSize,
          searchTerm,
        });

        await dispatch(actionCreators.requestKeyRequests() as any);
      },
    nextKeyRequestPage:
      (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const state = getState();
        if (state.keyRequests.isLoading) return;

        dispatch({
          type: "NEXTPAGE_KEYREQUESTS",
        });

        await dispatch(actionCreators.requestKeyRequests() as any);
      },
    prevKeyRequestPage:
      (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const state = getState();
        if (state.keyRequests.isLoading) return;

        dispatch({
          type: "PREVPAGE_KEYREQUESTS",
        });

        await dispatch(actionCreators.requestKeyRequests() as any);
      },

    requestKeyRequests:
      (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const {
          keyRequests: { page, pageSize, keyRequests: curKeyRequests },
        } = getState();

        const fetchTask1 = fromMeMode
          ? apiClientFactory().getFromMeAccessLog(page, pageSize)
          : apiClientFactory().getKeyRequests(page, pageSize, undefined);

        const fetchTask = fetchTask1.then(
          (data: any) => {
            dispatch({
              type: "RECEIVE_KEYREQUESTS",
              keyRequests: data,
              page,
              pageSize,
              searchTerm: "",
            });
          },
          (_error) => {
            dispatch({
              type: "RECEIVE_KEYREQUESTS",
              keyRequests: curKeyRequests,
              page,
              pageSize,
              searchTerm: "",
            });
          }
        );

        dispatch({
          type: "REQUEST_KEYREQUESTS",
        });
        return fetchTask;
      },

    postKeyRequest:
      (info: PostKeyRequestActionPayload): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        const {
          me: { user },
        } = getState();

        const json = await apiClientFactory().postKeyRequest({
          credentialType: info.credentialType,
          lockIds: info.lockIds,
          systemLockIds: [],
          destinationEmail: info.destinationEmail,
          destinationPhone: info.destinationPhone,
          pinCode: info.pinCode,
          lockSlot: info.lockSlot,
          validFrom: info.validFrom,
          validTo: info.validTo,
          connectUserExternalId: user && user.externalId ? user.externalId : "",
        });

        return json;
      },

    guestCreateInvite:
      (info: KeyChainRequest): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        const {
          me: { user },
        } = getState();

        const json = await apiClientFactory().postInvite({
          guestSms: info.guestSms,
          details: info.details,
          note: info.note,
          locks: info.locks.map((id) => ({
            lockGuid: id.lockGuid,
            credentialType: id.credentialType,
          })),
          destinationEmail: info.destinationEmail,
          destinationPhone: info.destinationPhone,
          pinCode: info.pinCode,
          lockSlot: info.lockSlot,
          validFrom: info.validFrom,
          validTo: info.validTo,
          connectUserExternalId: user && user.externalId ? user.externalId : "",
        });

        return json;
      },

    createInviteFromInvite:
      (info: InviteFromInvite): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        const {
          me: { user },
        } = getState();

        const json = await apiClientFactory().inviteFromInvite({
          keyRingId: info.fromInviteId,
          guestSms: info.guestSms,
          details: info.details,
          note: info.note,
          locks: info.lockIds.map((id) => ({
            lockGuid: id,
            credentialType: info.credentialType,
          })),
          destinationEmail: info.destinationEmail,
          destinationPhone: info.destinationPhone,
          pinCode: info.pinCode,
          lockSlot: info.lockSlot,
          validFrom: info.validFrom,
          validTo: info.validTo,
          connectUserExternalId: user && user.externalId ? user.externalId : "",
          shouldNotCheckGuestId: false,
        });

        return json;
      },

    getPin:
      (info: GetPinActionPayload): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        const {
          me: { user },
        } = getState();

        const json = await apiClientFactory().getPin({
          credentialType: KeyRequestCredentialType.MobileKey, // doesn't matter
          lockIds: info.lockIds,
          systemLockIds: [],
          connectUserExternalId: user && user.externalId ? user.externalId : "",
        });

        return json;
      },

    freeFormOpenDoor:
      (
        info: PostKeyRequestFreeFormActionPayload
      ): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        const {
          me: { user },
        } = getState();

        const json = await apiClientFactory().postKeyRequest({
          credentialType: info.credentialType,
          systemLockIds: info.lockIdentifiers
            ? info.lockIdentifiers.map((l) => l.unitName)
            : [],
          lockIds: [],
          details: info.description,
          destinationEmail: info.destinationEmail,
          destinationPhone: info.destinationPhone,
          pinCode: info.pinCode,
          lockSlot: info.lockSlot,
          validFrom: info.validFrom,
          validTo: info.validTo,
          connectUserExternalId: user && user.externalId ? user.externalId : "",
          siteId: info.siteId,
        });

        return json;
      },

    deleteKeyRequest:
      (info: DeleteKeyRequestActionPayload): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        await apiClientFactory().deleteKeyRequest(info.id);

        await dispatch(actionCreators.requestKeyRequests() as any);
      },
  };
  return actionCreators;
}
export const actionCreators = createActionCreators();
export const fromMeActionCreators = createActionCreators({
  fromMeMode: true,
});

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: KeyRequestsState = {
  keyRequests: [],
  isLoading: false,
  page: 0,
  pageSize: defaultPageSize,
  searchTerm: "",
};

export const reducer: Reducer<KeyRequestsState> = (
  state: KeyRequestsState = unloadedState,
  incomingAction: Action
) => {
  console.log("ACTION", incomingAction);
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "REQUEST_KEYREQUESTS":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVE_KEYREQUESTS":
      return {
        ...state,
        keyRequests: action.keyRequests,
        isLoading: false,
        page: action.page,
        pageSize: action.pageSize,
        searchTerm: action.searchTerm,
      };

    case "SETFETCHPARAMS_KEYREQUESTS":
      return reduceSetFetchParams(state.isLoading, action, state);
    case "NEXTPAGE_KEYREQUESTS":
      return reduceNextPage(state.keyRequests, state.isLoading, state);
    case "PREVPAGE_KEYREQUESTS":
      return reducePrevPage(state.isLoading, state);

    case "POST_KEY_REQUEST":
    case "POST_KEY_REQUEST_FREE_FORM":
    case "GET_PIN":
    case "GUEST_CREATE_INVITE":
    case "DELETE_KEYREQUEST":
    default:
      return state;
  }
};
