import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as LocksState from "../../store/Locks";
import * as SitesState from "../../store/Sites";
import * as AccountsState from "../../store/Accounts";
import * as SiteUsersState from "../../store/SiteUsers";
import * as ScreensState from "../../store/Screens";
import { confirmPrompt } from "../../helpers";
import {
  AccountResponse,
  SiteSystemType,
  SharedAccountCredentialsResponse,
} from "../../api/ApiClient";
import SelectAccount from "../shared/SelectAccount";
import { RegisterOrEditScreen } from "./AdminScreens";

import Modal from "react-bootstrap-modal";
import "../shared/customModal.css";
import SelectSharedAccountCredentials from "../shared/SelectSharedAccountCredentials";

import { Spinner } from "components/shared/Spinner";
import EditAdminAccount from "./EditAdminAccount";
import "react-toggle/style.css";
import Toggle from "react-toggle";

type OwnProps = RouteComponentProps<{ startDateIndex: string }>;

interface Injected {
  systemTypes: SiteSystemType[];
  isLoading: boolean;
}
type Props = Injected &
  typeof AccountsState.actionCreators &
  typeof SitesState.actionCreators &
  typeof ScreensState.actionCreators &
  OwnProps;

type ModalType = "account" | "site" | "screen" | null;

interface State {
  inProgress: Object | null;

  selectedAccount: AccountResponse | null;
  showingModal: ModalType;
  propertyExternalId: string;
  //register site under account
  systemSiteId?: string;
  siteName: string;
  openEditModal: boolean;
  accountId: string | null;
  accessCode: string;
  proxyVersion: number;
  username?: string;
  password?: string;
  sharedKey?: string;
  sharedKeyName?: string;
  systemSiteKey?: string;
  isNewSite?: boolean;
}

function isDigitalSignageType(type: SiteSystemType | undefined) {
  switch (type) {
    case SiteSystemType.LivlyDigital:
      return true;
    default:
      return false;
  }
}

class AdminAccounts extends React.Component<Props, State> {
  state: State = {
    inProgress: null,
    openEditModal: false,
    selectedAccount: null,
    showingModal: null,
    propertyExternalId: "",
    //register site under account
    systemSiteId: "",
    siteName: "",
    accountId: null,
    accessCode: "",
    proxyVersion: 2,
    username: "",
    password: "",
    sharedKey: "",
    sharedKeyName: "",
    systemSiteKey: "",
    isNewSite: true,
  };

  onRegistered = () => {
    this.closeModal();
    this.props.requestAccounts();
  };

  addSiteForAccount = (account: AccountResponse) => {
    this.setState(
      {
        selectedAccount: account,
      },
      () => {
        this.showAddSiteModal();
      }
    );
  };

  addScreenForAccount = (account: AccountResponse) => {
    this.setState(
      {
        selectedAccount: account,
      },
      () => {
        this.showAddScreenModal();
      }
    );
  };

  registerSite = (e: any) => {
    e.preventDefault();

    this.setState(
      {
        inProgress: { ...(this.state.inProgress || {}), registerSite: true },
      },
      async () => {
        const {
          systemSiteId,
          siteName,
          selectedAccount,
          propertyExternalId,
          username,
          password,
          sharedKey,
          sharedKeyName,
          systemSiteKey,
        } = this.state;
        try {
          let payload: any = {
            systemSiteId,
            name: siteName,
            accountId: selectedAccount ? selectedAccount.id || "" : "",
            propertyExternalId: propertyExternalId,
          };

          if (selectedAccount?.systemType) {
            if (selectedAccount.systemType === SiteSystemType.SchlageEngage) {
              if (
                (systemSiteId && !systemSiteKey) ||
                (!systemSiteKey && systemSiteId)
              ) {
                return alert("System Site Id and Key are required");
              }
              payload = {
                ...payload,
                accessCode: this.state.accessCode,
                systemSiteKey: this.state.systemSiteKey,
              };
            }
            if (
              [SiteSystemType.Assa, SiteSystemType.SaltoSpace].includes(
                selectedAccount.systemType
              )
            ) {
              payload = {
                ...payload,
                proxyVersion: this.state.proxyVersion,
              };

              if (username && username?.length > 0) {
                if (password === "") {
                  return alert("Password is required");
                }

                if (
                  (sharedKey && !sharedKeyName) ||
                  (!sharedKey && sharedKeyName)
                ) {
                  return alert("Shared Key and Shared Key Name are required");
                }
                payload = {
                  ...payload,
                  username,
                  password,
                  sharedKey,
                  sharedKeyName,
                };
              }
            }
          }

          const json = (await this.props.registerSite(payload)) as any;

          this.setState({
            //clear inputs
            systemSiteId: "",
            siteName: "",
            propertyExternalId: "",
            username: "",
            password: "",
            sharedKey: "",
            sharedKeyName: "",
          });

          alert("Successfully registered site: " + JSON.stringify(json));
        } catch (e) {
          alert("Error registering site: " + JSON.stringify(e));
        } finally {
          this.setState({
            inProgress: {
              ...(this.state.inProgress || {}),
              registerSite: false,
            },
          });
        }
      }
    );
  };

  deleteAccount = (account: AccountResponse) => {
    confirmPrompt({
      promptMessage: "Are you sure you want to delete this account?",
      onConfirm: async () => {
        try {
          await this.props.deleteAccount({
            id: account.id || "",
          });
          await this.props.requestAccounts();
        } catch (e) {
          alert("Error deleting account: " + JSON.stringify(e));
        }
      },
    });
  };

  editAccount = (account: AccountResponse) => {
    this.setState({ openEditModal: true, accountId: String(account.id) });
  };

  showAddAccountModal = () => {
    this.setState({ showingModal: "account" });
  };

  showAddSiteModal = () => {
    this.setState({ showingModal: "site" });
  };

  showAddScreenModal = () => {
    this.setState({ showingModal: "screen" });
  };

  closeModal = () => {
    this.setState({ showingModal: null });
  };

  selectAccount = (account: AccountResponse) => {
    const { selectedAccount } = this.state;
    const wasSelected = selectedAccount && selectedAccount.id === account.id;
    this.setState({
      selectedAccount: wasSelected ? null : account,
    });
  };

  onRegisteredScreen = async () => {
    this.closeModal();
    await this.props.requestScreens();
  };

  public render() {
    const { showingModal, selectedAccount, openEditModal, accountId } =
      this.state;
    const { isLoading } = this.props;
    return (
      <div>
        <p />
        <div>{this.renderAccountsTable()}</div>

        <Modal
          show={!!showingModal}
          onHide={this.closeModal}
          aria-labelledby="ModalHeader"
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="ModalHeader">
              {showingModal === "account"
                ? "Add Lock System Account"
                : showingModal === "site"
                ? "Add Site"
                : showingModal === "screen"
                ? "Add Screen Tile"
                : "error"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isLoading ? (
              <div
                style={{
                  marginBottom: "50px",
                }}
              >
                <Spinner />
              </div>
            ) : (
              <>
                {showingModal === "account" ? (
                  this.renderRegisterAccount()
                ) : showingModal === "site" ? (
                  this.renderRegisterSite()
                ) : showingModal === "screen" ? (
                  //@ts-ignore
                  <RegisterOrEditScreen
                    accountId={selectedAccount ? selectedAccount.id || "" : ""}
                    onRegistered={() => this.onRegisteredScreen()}
                  />
                ) : (
                  "error"
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Modal.Dismiss className="btn btn-default">Close</Modal.Dismiss>
          </Modal.Footer>
        </Modal>
        {accountId && (
          <EditAdminAccount
            accountId={accountId}
            open={openEditModal}
            onClose={() => this.setState({ openEditModal: false })}
          />
        )}
      </div>
    );
  }

  private renderAccountButton = (account: AccountResponse) => {
    return (
      <div>
        <button
          type="button"
          className={`btn btn-danger`}
          onClick={() => this.deleteAccount(account)}
        >
          Delete
        </button>
        <p />
        <button
          type="button"
          className={`btn btn-default`}
          onClick={() => this.editAccount(account)}
        >
          Edit
        </button>

        {!isDigitalSignageType(account.systemType) && (
          <React.Fragment>
            <p />
            <div className="d-flex justify-between">
              <button
                type="button"
                className={`btn btn-sm btn-default`}
                onClick={() => this.addSiteForAccount(account)}
              >
                Add Site
              </button>
            </div>
          </React.Fragment>
        )}
        {isDigitalSignageType(account.systemType) && (
          <React.Fragment>
            <p />
            <div className="d-flex justify-between">
              <button
                type="button"
                className={`btn btn-sm btn-default`}
                onClick={() => this.addScreenForAccount(account)}
              >
                Add Screen Tile
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  private renderAccountsTable() {
    const button = (
      <button
        type="button"
        className="btn btn-primary"
        onClick={this.showAddAccountModal}
      >
        Set Up Lock System Account
      </button>
    );

    return (
      <div>
        {button}
        <p />
        &nbsp;
        <p />
        {
          //@ts-ignore
          <SelectAccount
            pageSize={5}
            renderButton={this.renderAccountButton}
            unselectedLabel="Delete"
            unselectedBtnClass="btn-danger"
          />
        }
        <hr />
      </div>
    );
  }

  renderRegisterAccount = () => {
    return (
      //@ts-ignore
      <RegisterAccount
        onRegistered={this.onRegistered}
        systemTypes={this.props.systemTypes}
      />
    );
  };

  renderRegisterSite = () => {
    const {
      selectedAccount,
      systemSiteId,
      inProgress,
      siteName,
      propertyExternalId,
      accessCode,
      proxyVersion,
      username,
      password,
      sharedKey,
      sharedKeyName,
      systemSiteKey,
      isNewSite,
    } = this.state;
    const { systemTypes } = this.props;

    const { registerSite: registerSiteInProgress = false } = (inProgress ||
      {}) as any;

    return (
      <form>
        <div className="form-group">
          <label htmlFor="siteName">Name *</label>
          <input
            className="form-control"
            name="siteName"
            placeholder="site name"
            value={siteName}
            onChange={(e) => this.setState({ siteName: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label htmlFor="siteAccountName">Account Name</label>
          <input
            className="form-control"
            name="siteAccountName"
            disabled
            placeholder="Site Account Name"
            value={selectedAccount ? selectedAccount.name || "" : ""}
            onChange={(_e) => {}}
          />
        </div>

        <div className="form-group">
          <label htmlFor="systemType">System Type *</label>
          <select
            className="form-control"
            name="systemType"
            disabled
            placeholder="System Type"
            value={selectedAccount ? selectedAccount.systemType || "" : ""}
            onChange={(e) => {}}
          >
            {systemTypes
              .filter((k) => isNaN(Number(k)))
              .map((k) => (
                <option key={k} value={k}>
                  {k}
                </option>
              ))}
          </select>
        </div>
        {selectedAccount &&
        [SiteSystemType.SchlageEngage].includes(selectedAccount.systemType!) ? (
          <>
            {" "}
            <div className="form-group">
              <label style={{ display: "flex", alignItems: "center" }}>
                <Toggle
                  defaultChecked={isNewSite}
                  icons={false}
                  onChange={(e) =>
                    this.setState({ isNewSite: e.target.checked })
                  }
                />
                <span style={{ marginLeft: 4 }}>
                  {isNewSite ? "New Site" : "Existing site"}
                </span>
              </label>
            </div>
            {!isNewSite && (
              <>
                <div className="form-group">
                  <label htmlFor="systemSiteId">System Site Id *</label>
                  <input
                    className="form-control"
                    name="systemSiteId"
                    placeholder="system site id"
                    value={systemSiteId}
                    onChange={(e) =>
                      this.setState({ systemSiteId: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="systemSiteKey">System Site Key * </label>
                  <input
                    className="form-control"
                    name="systemSiteKey"
                    placeholder="system site key"
                    value={systemSiteKey}
                    onChange={(e) =>
                      this.setState({ systemSiteKey: e.target.value })
                    }
                  />
                </div>
              </>
            )}
          </>
        ) : (
          <div className="form-group">
            <label htmlFor="systemSiteId">System Site Id *</label>
            <input
              className="form-control"
              name="systemSiteId"
              placeholder="system site id"
              value={systemSiteId}
              onChange={(e) => this.setState({ systemSiteId: e.target.value })}
            />
          </div>
        )}

        <div className="form-group">
          <label htmlFor="propertyExternalId">Property External Id </label>
          <input
            className="form-control"
            name="propertyExternalId"
            placeholder="property external id"
            value={propertyExternalId}
            onChange={(e) =>
              this.setState({ propertyExternalId: e.target.value })
            }
          />
        </div>

        {selectedAccount &&
          selectedAccount.systemType === SiteSystemType.SchlageEngage && (
            <div className="form-group">
              <label htmlFor="accessCode">Access code </label>
              <input
                className="form-control"
                name="accessCode"
                placeholder="Access code"
                maxLength={12}
                value={accessCode}
                onChange={(e) => this.setState({ accessCode: e.target.value })}
              />
            </div>
          )}
        {selectedAccount &&
          [SiteSystemType.Assa, SiteSystemType.SaltoSpace].includes(
            selectedAccount.systemType!
          ) && (
            <>
              <div className="form-group">
                <label htmlFor="proxyVerion">Proxy Version </label>
                <input
                  className="form-control"
                  name="proxyVerion"
                  placeholder=">Proxy Version"
                  type="number"
                  min="1"
                  max="2"
                  step="1"
                  value={proxyVersion}
                  onChange={(e) => {
                    const inputValue = parseFloat(e.target.value);
                    if (
                      !isNaN(inputValue) &&
                      inputValue >= 1 &&
                      inputValue <= 2
                    ) {
                      this.setState({ proxyVersion: inputValue });
                    }
                  }}
                />
              </div>
              {proxyVersion === 2 && (
                <>
                  <div className="form-group">
                    <label htmlFor="username">Proxy Username</label>
                    <input
                      className="form-control"
                      name="username"
                      placeholder="Proxy Username"
                      value={username}
                      onChange={(e) => {
                        this.setState({ username: e.target.value });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Proxy Password</label>
                    <input
                      className="form-control"
                      name="password"
                      placeholder="Proxy Password"
                      value={password}
                      onChange={(e) => {
                        this.setState({ password: e.target.value });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sharedKey">
                      Shared Key{" "}
                      {(sharedKey && sharedKey?.length > 0) ||
                      (sharedKeyName && sharedKeyName?.length > 0)
                        ? "*"
                        : "(optional)"}
                    </label>
                    <input
                      className="form-control"
                      name="sharedKey"
                      placeholder="Shared Key"
                      value={sharedKey}
                      onChange={(e) => {
                        this.setState({ sharedKey: e.target.value });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sharedKeyName">
                      Shared Key Name{" "}
                      {(sharedKey && sharedKey?.length > 0) ||
                      (sharedKeyName && sharedKeyName?.length > 0)
                        ? "*"
                        : "(optional)"}
                    </label>
                    <input
                      className="form-control"
                      name="sharedKeyName"
                      placeholder="Shared Key Name"
                      value={sharedKeyName}
                      onChange={(e) => {
                        this.setState({ sharedKeyName: e.target.value });
                      }}
                    />
                  </div>
                </>
              )}
            </>
          )}
        <div>
          <button
            type="submit"
            disabled={!!registerSiteInProgress}
            className="btn btn-primary"
            onClick={this.registerSite}
          >
            Register Site
          </button>
        </div>
      </form>
    );
  };
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    accounts: state.accounts,
  }),
  {
    ...AccountsState.actionCreators,
    ...LocksState.actionCreators,
    ...SitesState.actionCreators,
    ...SiteUsersState.actionCreators,
    ...ScreensState.actionCreators,
  }
)(AdminAccounts);

type RegisterOwnProps = {
  registerOverride: (info: any) => any;
  hideUseSharedCredentials?: boolean;
  registerButtonLabel?: string;
  onRegistered: () => any;
  systemTypes: SiteSystemType[];
};

interface RegisterInjected {}
type RegisterProps = RegisterInjected &
  typeof AccountsState.actionCreators &
  RegisterOwnProps;

interface RegisterState {
  inProgress: boolean;

  //create account
  systemType: SiteSystemType;
  name: string;
  //credentials
  clientId: string;
  clientSecret: string;
  clientId2: string;
  clientSecret2: string;
  username: string;
  password: string;
  accessToken: string;
  refreshToken: string;
  apiUrl: string;
  tokenUrl: string;

  //select shared credentials
  selectedSharedCreds: {
    id?: string;
    name?: string;
    systemType?: SiteSystemType | undefined;
  } | null;
  selectingSharedCreds: boolean;
}

class RegisterAccountComp extends React.Component<
  RegisterProps,
  RegisterState
> {
  state: RegisterState = {
    inProgress: false,

    //create account
    systemType: SiteSystemType.Assa,
    name: "",
    //credentials
    clientId: "",
    clientSecret: "",
    clientId2: "",
    clientSecret2: "",
    username: "",
    password: "",
    accessToken: "",
    refreshToken: "",
    apiUrl: "",
    tokenUrl: "",

    //select shared credentials
    selectedSharedCreds: null,
    selectingSharedCreds: false,
  };

  registerAccount = (e: any) => {
    e.preventDefault();

    this.setState(
      {
        inProgress: true,
      },
      async () => {
        const state = this.state;
        try {
          const registerFunc =
            this.props.registerOverride ||
            (state.selectedSharedCreds
              ? this.props.registerAccountWithSharedCredentials
              : this.props.registerAccount);
          let payload: any = {
            sharedCredId: state.selectedSharedCreds
              ? state.selectedSharedCreds.id
              : "",
            systemType: state.systemType,
            name: state.name,
            clientId: state.clientId,
            clientSecret: state.clientSecret,
            username: state.username,
            password: state.password,
            accessToken: state.accessToken,
            refreshToken: state.refreshToken,
            apiUrl: state.apiUrl,
            tokenUrl: state.tokenUrl,
          };
          if (state.systemType === SiteSystemType.Brivo) {
            payload = {
              ...payload,
              clientId2: state.clientId2,
              clientSecret2: state.clientSecret2,
            };
          }
          const json = (await registerFunc(payload)) as any;
          alert("Successfully registered account: " + JSON.stringify(json));
          this.props.onRegistered();
          this.setState({
            //clear inputs
            name: "",
            clientId: "",
            clientSecret: "",
            username: "",
            password: "",
            accessToken: "",
            refreshToken: "",
            apiUrl: "",
            tokenUrl: "",
          });
        } catch (e) {
          alert("Error registering account: " + JSON.stringify(e));
        } finally {
          this.setState({
            inProgress: false,
          });
        }
      }
    );
  };

  private renderSelectSharedCredButton = (
    sharedCred: SharedAccountCredentialsResponse
  ) => {
    return (
      <div>
        <button
          type="button"
          className={`btn btn-primary`}
          onClick={() =>
            this.setState({
              selectingSharedCreds: false,
              selectedSharedCreds: sharedCred,
            })
          }
        >
          Select
        </button>
        <p />
      </div>
    );
  };

  renderSelectSharedCredentials = () => {
    const { selectingSharedCreds, selectedSharedCreds } = this.state;
    return (
      <div>
        {selectingSharedCreds && (
          <>
            <h3>Please Select Shared Credentials</h3>
            <button
              type="button"
              className="btn btn-sm"
              onClick={() => {
                this.setState({ selectingSharedCreds: false });
              }}
            >
              Cancel
            </button>
            <p />
            {
              //@ts-ignore
              <SelectSharedAccountCredentials
                pageSize={5}
                renderButton={this.renderSelectSharedCredButton}
              />
            }
          </>
        )}
        {selectedSharedCreds && (
          <>
            <div>
              <h4>
                Selected Shared Credentials:{" "}
                <b>
                  {selectedSharedCreds.name} ({selectedSharedCreds.systemType})
                </b>
              </h4>
            </div>
            <button
              type="button"
              className="btn btn-default"
              onClick={() =>
                this.setState({
                  selectingSharedCreds: true,
                  selectedSharedCreds: null,
                })
              }
            >
              Change Shared Credentials
            </button>
          </>
        )}
      </div>
    );
  };

  render() {
    const {
      inProgress,
      systemType,
      name,
      selectingSharedCreds,
      selectedSharedCreds,
    } = this.state;
    const { hideUseSharedCredentials, registerButtonLabel, systemTypes } =
      this.props;

    return (
      <form>
        <div className="form-group">
          <label htmlFor="name">Name *</label>
          <input
            className="form-control"
            name="name"
            placeholder="Account Name"
            value={name}
            onChange={(e) => this.setState({ name: e.target.value })}
          />
        </div>

        {(selectingSharedCreds || selectedSharedCreds) && (
          <div>
            {this.renderSelectSharedCredentials()}
            <p />
          </div>
        )}

        {!selectingSharedCreds && !selectedSharedCreds && (
          <>
            {!hideUseSharedCredentials && (
              <>
                <button
                  type="button"
                  className="btn btn-sm"
                  onClick={() =>
                    this.setState({
                      selectingSharedCreds: true,
                      selectedSharedCreds: null,
                    })
                  }
                >
                  Use Shared Credentials...
                </button>
                <p />
              </>
            )}
            <div className="form-group">
              <label htmlFor="systemType">System Type *</label>
              <select
                className="form-control"
                name="systemType"
                placeholder="System Type"
                value={systemType}
                onChange={(e) => {
                  let systemType = e.target.value as SiteSystemType;
                  this.setState({ systemType: systemType });
                }}
              >
                {systemTypes &&
                  systemTypes.map((k) => (
                    <option key={k} value={k}>
                      {k}
                    </option>
                  ))}
              </select>
            </div>

            {this.renderCredentials(systemType)}
          </>
        )}

        <div>
          <button
            type="submit"
            disabled={!!inProgress}
            className="btn btn-primary"
            onClick={this.registerAccount}
          >
            {registerButtonLabel || "Register Account"}
          </button>
        </div>
      </form>
    );
  }

  private renderCredentials(systemType: SiteSystemType) {
    switch (systemType) {
      case SiteSystemType.Assa:
        return this.renderAssaCredentials();
      case SiteSystemType.Openpath:
        return this.renderOpenPathCredentials();
      case SiteSystemType.Butterfly:
        return this.renderButterflyCredentials();
      case SiteSystemType.Brivo:
        return this.renderBrivoCredentials();
      case SiteSystemType.SmartThings:
        return this.renderSmartThingsCredentials();
      case SiteSystemType.Buzzly:
        return this.renderBuzzlyCredentials();
      case SiteSystemType.Stratis:
        return this.renderStratisCredentials();
      case SiteSystemType.LivlySms:
        return this.renderLivlySmsCredentials();
      case SiteSystemType.LivlyDigital:
        return this.renderLivlyDigitalCredentials();
      case SiteSystemType.SaltoSpace:
        return this.renderSaltoSpaceCredentials();
      case SiteSystemType.SchlageEngage:
        return this.renderSchlageEngageCredentials();
      default:
        return this.renderAllCredentials();
    }
  }

  private renderAllCredentials() {
    const {
      username,
      password,
      clientId,
      clientSecret,
      apiUrl,
      tokenUrl,
      accessToken,
    } = this.state;
    return (
      <React.Fragment>
        <div className="form-group">
          <label htmlFor="username">User Name *</label>
          <input
            className="form-control"
            name="username"
            placeholder="username"
            value={username}
            onChange={(e) => this.setState({ username: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password *</label>
          <input
            className="form-control"
            name="password"
            type="password"
            placeholder="password"
            value={password}
            onChange={(e) => this.setState({ password: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="clientId">ClientId *</label>
          <input
            className="form-control"
            name="clientId"
            placeholder="Client Id"
            value={clientId}
            onChange={(e) => this.setState({ clientId: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="clientSecret">Client Secret *</label>
          <input
            className="form-control"
            name="clientSecret"
            type="password"
            placeholder="Client Secret"
            value={clientSecret}
            onChange={(e) => this.setState({ clientSecret: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="messageString">Message String *</label>
          <input
            className="form-control"
            name="messageString"
            placeholder="Message String"
            value={accessToken}
            onChange={(e) => this.setState({ accessToken: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="apiUrl">API URL *</label>
          <input
            className="form-control"
            name="apiUrl"
            placeholder="Api Url"
            value={apiUrl}
            onChange={(e) => this.setState({ apiUrl: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="tokenUrl">Token URL *</label>
          <input
            className="form-control"
            name="tokenUrl"
            placeholder="Token Url"
            value={tokenUrl}
            onChange={(e) => this.setState({ tokenUrl: e.target.value })}
          />
        </div>
      </React.Fragment>
    );
  }

  private renderAssaCredentials() {
    const { username, password, apiUrl } = this.state;
    return (
      <React.Fragment>
        <div className="form-group">
          <label htmlFor="username">Assa User Name *</label>
          <input
            className="form-control"
            name="username"
            placeholder="Assa User Name"
            value={username}
            onChange={(e) => this.setState({ username: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Assa Password *</label>
          <input
            className="form-control"
            name="password"
            type="password"
            placeholder="Assa Password"
            value={password}
            onChange={(e) => this.setState({ password: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="apiUrl">API URL *</label>
          <input
            className="form-control"
            name="apiUrl"
            placeholder="Api Url"
            value={apiUrl}
            onChange={(e) => this.setState({ apiUrl: e.target.value })}
          />
        </div>
      </React.Fragment>
    );
  }

  private renderOpenPathCredentials() {
    const { username, password, clientId } = this.state;
    return (
      <React.Fragment>
        <div className="form-group">
          <label htmlFor="username">OpenPath User Name *</label>
          <input
            className="form-control"
            name="username"
            placeholder="username"
            value={username}
            onChange={(e) => this.setState({ username: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">OpenPath Password *</label>
          <input
            className="form-control"
            name="password"
            type="password"
            placeholder="password"
            value={password}
            onChange={(e) => this.setState({ password: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="clientId">ORG ID *</label>
          <input
            className="form-control"
            name="clientId"
            placeholder="Org ID"
            value={clientId}
            onChange={(e) => this.setState({ clientId: e.target.value })}
          />
        </div>
      </React.Fragment>
    );
  }

  private renderButterflyCredentials() {
    const { username, password, clientId, clientSecret, apiUrl, tokenUrl } =
      this.state;
    return (
      <React.Fragment>
        <div className="form-group">
          <label htmlFor="username">User Name *</label>
          <input
            className="form-control"
            name="username"
            placeholder="username"
            value={username}
            onChange={(e) => this.setState({ username: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password *</label>
          <input
            className="form-control"
            name="password"
            type="password"
            placeholder="password"
            value={password}
            onChange={(e) => this.setState({ password: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="clientId">ClientId *</label>
          <input
            className="form-control"
            name="clientId"
            placeholder="Client Id"
            value={clientId}
            onChange={(e) => this.setState({ clientId: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="clientSecret">Client Secret *</label>
          <input
            className="form-control"
            name="clientSecret"
            type="password"
            placeholder="Client Secret"
            value={clientSecret}
            onChange={(e) => this.setState({ clientSecret: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="apiUrl">API URL *</label>
          <input
            className="form-control"
            name="apiUrl"
            placeholder="Api Url"
            value={apiUrl}
            onChange={(e) => this.setState({ apiUrl: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="tokenUrl">Token URL *</label>
          <input
            className="form-control"
            name="tokenUrl"
            placeholder="Token Url"
            value={tokenUrl}
            onChange={(e) => this.setState({ tokenUrl: e.target.value })}
          />
        </div>
      </React.Fragment>
    );
  }

  private renderBrivoCredentials() {
    const {
      username,
      password,
      clientId,
      clientSecret,
      clientId2,
      clientSecret2,
      accessToken,
    } = this.state;
    return (
      <React.Fragment>
        <div className="form-group">
          <label htmlFor="username">User Name *</label>
          <input
            className="form-control"
            name="username"
            placeholder="username"
            value={username}
            onChange={(e) => this.setState({ username: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password *</label>
          <input
            className="form-control"
            name="password"
            type="password"
            placeholder="password"
            value={password}
            onChange={(e) => this.setState({ password: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="clientId">ClientId *</label>
          <input
            className="form-control"
            name="clientId"
            placeholder="Client Id"
            value={clientId}
            onChange={(e) => this.setState({ clientId: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="clientSecret">Client Secret *</label>
          <input
            className="form-control"
            name="clientSecret"
            type="password"
            placeholder="Client Secret"
            value={clientSecret}
            onChange={(e) => this.setState({ clientSecret: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="clientId">ClientId 2 *</label>
          <input
            className="form-control"
            name="clientId"
            placeholder="Client Id"
            value={clientId2}
            onChange={(e) => this.setState({ clientId2: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="clientSecret">Client Secret 2 *</label>
          <input
            className="form-control"
            name="clientSecret"
            type="password"
            placeholder="Client Secret"
            value={clientSecret2}
            onChange={(e) => this.setState({ clientSecret2: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label htmlFor="accessToken"> Access Token *</label>
          <input
            className="form-control"
            name="accessToken"
            placeholder=" Access Token"
            value={accessToken}
            onChange={(e) => this.setState({ accessToken: e.target.value })}
          />
        </div>
      </React.Fragment>
    );
  }

  private renderSmartThingsCredentials() {
    const { accessToken, apiUrl } = this.state;
    return (
      <React.Fragment>
        <div className="form-group">
          <label htmlFor="accessToken">Personal Access Token *</label>
          <input
            className="form-control"
            name="accessToken"
            type="password"
            placeholder="Personal Access Token"
            value={accessToken}
            onChange={(e) => this.setState({ accessToken: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="apiUrl">API URL *</label>
          <input
            className="form-control"
            name="apiUrl"
            placeholder="Api Url"
            value={apiUrl}
            onChange={(e) => this.setState({ apiUrl: e.target.value })}
          />
        </div>
      </React.Fragment>
    );
  }

  private renderBuzzlyCredentials() {
    const { accessToken, password } = this.state;
    return (
      <React.Fragment>
        <div className="form-group">
          <label htmlFor="accessToken">Secret Url *</label>
          <input
            className="form-control"
            name="accessToken"
            placeholder="Secret Url"
            value={accessToken}
            onChange={(e) => this.setState({ accessToken: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Secret Number</label>
          <input
            className="form-control"
            name="password"
            type="password"
            placeholder="e.g. Livly"
            value={password}
            onChange={(e) => this.setState({ password: e.target.value })}
          />
        </div>
      </React.Fragment>
    );
  }

  private renderStratisCredentials() {
    const { apiUrl, tokenUrl, clientId, clientSecret, username } = this.state;
    return (
      <React.Fragment>
        <div className="form-group">
          <label htmlFor="apiUrl">Audience *</label>
          <input
            className="form-control"
            name="apiUrl"
            placeholder="Audience"
            value={apiUrl}
            onChange={(e) => this.setState({ apiUrl: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="tokenUrl">TokenUrl *</label>
          <input
            className="form-control"
            name="tokenUrl"
            placeholder="TokenUrl"
            value={tokenUrl}
            onChange={(e) => this.setState({ tokenUrl: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="clientId">ClientId *</label>
          <input
            className="form-control"
            name="clientId"
            placeholder="clientId"
            value={clientId}
            onChange={(e) => this.setState({ clientId: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="clientSecret">Private Key *</label>
          <input
            className="form-control"
            name="clientSecret"
            placeholder="Private Key"
            value={clientSecret}
            onChange={(e) => this.setState({ clientSecret: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="username">Kid *</label>
          <input
            className="form-control"
            name="username"
            placeholder="Kid"
            value={username}
            onChange={(e) => this.setState({ username: e.target.value })}
          />
        </div>
      </React.Fragment>
    );
  }

  private renderLivlySmsCredentials() {
    const { accessToken, username, password, apiUrl, clientId, clientSecret } =
      this.state;
    return (
      <React.Fragment>
        <div className="form-group">
          <label htmlFor="apiUrl">API URL *</label>
          <input
            className="form-control"
            name="apiUrl"
            placeholder="Api Url"
            value={apiUrl}
            onChange={(e) => this.setState({ apiUrl: e.target.value })}
          />
          <p className="help-block">
            e.g.
            https://api.twilio.com/2010-04-01/Accounts/AC2bf986a8165cf8113ef9006d4d8ac513/Messages.json
          </p>
        </div>
        <div className="form-group">
          <label htmlFor="username">User Name *</label>
          <input
            className="form-control"
            name="username"
            placeholder="User Name"
            value={username}
            onChange={(e) => this.setState({ username: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password *</label>
          <input
            className="form-control"
            name="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => this.setState({ password: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="messageString">Message String *</label>
          <input
            className="form-control"
            name="messageString"
            placeholder="Message String"
            value={accessToken}
            onChange={(e) => this.setState({ accessToken: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="fromNumber">From Number *</label>
          <input
            className="form-control"
            name="fromNumber"
            placeholder="From Number, e.g. +13125845040"
            value={clientId}
            onChange={(e) => this.setState({ clientId: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="toNumber">To Number *</label>
          <input
            className="form-control"
            name="toNumber"
            placeholder="To Number"
            value={clientSecret}
            onChange={(e) => this.setState({ clientSecret: e.target.value })}
          />
        </div>
      </React.Fragment>
    );
  }

  private renderLivlyDigitalCredentials() {
    return null;
  }

  private renderSaltoSpaceCredentials() {
    return null;
  }

  private renderSchlageEngageCredentials() {
    const { username, password } = this.state;
    return (
      <React.Fragment>
        <div className="form-group">
          <label htmlFor="username">User Name *</label>
          <input
            className="form-control"
            name="username"
            placeholder="User Name"
            value={username}
            onChange={(e) => this.setState({ username: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password *</label>
          <input
            className="form-control"
            name="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => this.setState({ password: e.target.value })}
          />
        </div>
      </React.Fragment>
    );
  }
}

export const RegisterAccount = connect<
  any,
  any,
  RegisterOwnProps,
  ApplicationState
>((state) => ({}), {
  ...AccountsState.actionCreators,
})(RegisterAccountComp);
