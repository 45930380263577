import moment, { Moment } from "moment-timezone";

export default function toLocalTime(
  timestamp: Date | string | number,
  timezone?: string | null
): Moment {
  if (timezone) {
    return moment.utc(timestamp).tz(timezone);
  }
  return moment.utc(timestamp).local();
}
