import * as React from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  AccessControlGroupResponse,
  AccessControlGroupType,
  AccessScheduleResponse,
  SiteResponse,
} from "../../api/ApiClient";
import SelectAccessControlGroupLocks from "./SelectAccessControlGroupLocks";
import { apiClientFactory } from "../../helpers";
import { useState } from "react";
import { Spinner } from "../shared/Spinner";

interface AccessScheduleResponse2 extends AccessScheduleResponse {
  enabled: boolean;
  siteId: string | undefined;
  siteName: string | undefined;
}

interface ParamTypes {
  accessControlGroupId: string;
}

function useGetGroupDetails(accessControlGroupId: string) {
  const factory = apiClientFactory();

  const getAccessGroupDetails = async () => {
    const result = await factory.getAccessControlGroup(
      accessControlGroupId,
      undefined
    );

    return result;
  };

  return useQuery(
    ["access-group-details", accessControlGroupId],
    getAccessGroupDetails
  );
}

function useGetAccessGroupSites(
  accessControlGroupId: string,
  accessGroup: AccessControlGroupResponse | undefined
) {
  const factory = apiClientFactory();

  const externalIds = ((accessGroup && accessGroup.tenants) || [])
    .filter((t) => t.tenant != null && t.tenant.externalId != null)
    .map((t) => t.tenant!.externalId);

  const getSites = () => {
    return Promise.all(
      externalIds.map((id) =>
        factory.getSites(undefined, undefined, undefined, id)
      )
    );
  };

  return useQuery(["access-group-sites", accessControlGroupId], getSites, {
    enabled: accessGroup != null && externalIds.length > 0,
  });
}

function useGetAccessGroupSchedules(
  accessControlGroupId: string,
  accessGroup: AccessControlGroupResponse | undefined,
  sites: SiteResponse[]
) {
  const factory = apiClientFactory();

  const getSchedules = () => {
    return Promise.all(
      sites.map((site) =>
        factory.getAccessSchedules(site.id!).then((schedule) => {
          return {
            ...schedule,
            //this will be toggled in child component
            enabled: false,
            siteId: site.id,
            siteName: site.name,
          } as AccessScheduleResponse2;
        })
      )
    );
  };

  return useQuery(
    ["access-group-schedules", accessControlGroupId],
    getSchedules,
    {
      enabled: sites.length > 0,
    }
  );
}

function AccessControlGroupContainer() {
  const { accessControlGroupId } = useParams() as ParamTypes;
  const {
    isLoading,
    data: accessGroup,
    refetch: refetchGroup,
  } = useGetGroupDetails(accessControlGroupId);
  const {
    isLoading: isLoadingSites,
    data: sites,
    refetch,
  } = useGetAccessGroupSites(accessControlGroupId, accessGroup);

  const flatSites = sites ? sites.flat() : [];

  const { isLoading: isLoadingSchedules, data: schedules } =
    useGetAccessGroupSchedules(accessControlGroupId, accessGroup, flatSites);

  const flatSchedules = schedules ? schedules.flat() : [];

  if (isLoading || isLoadingSites || isLoadingSchedules) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  if (accessGroup == null) {
    return <div>error loading access group</div>;
  }

  /* return (
    <div>
      <h3>group</h3>
      <pre>{JSON.stringify(accessGroup, null, 2)}</pre>

      <br />
      <h3>sites</h3>
      <pre>{JSON.stringify(flatSites, null, 2)}</pre>

      <br />
      <h3>schedules</h3>
      <pre>{JSON.stringify(flatSchedules, null, 2)}</pre>
    </div>
  ); */

  const handleRefetch = async () => {
    await refetchGroup();
    await refetch();
  };
  return (
    <AccessControlGroupDetail
      accessGroup={accessGroup}
      schedules={flatSchedules}
      refetch={handleRefetch}
    />
  );
}

function AccessControlGroupDetail({
  accessGroup,
  schedules,
  refetch,
}: {
  accessGroup: AccessControlGroupResponse;
  schedules: AccessScheduleResponse2[];
  refetch: () => Promise<void>;
}) {
  const existingIds = accessGroup.accessSchedules
    ? accessGroup.accessSchedules.map((e) => e.id)
    : [];
  const { accessControlGroupId } = useParams() as ParamTypes;
  const factory = apiClientFactory();
  const [loading, setLoading] = useState(false);
  const [accessControlGroup, setAccessControlGroup] =
    useState<AccessControlGroupResponse>();
  const [accessSchedules, setAccessSchedules] = useState<
    AccessScheduleResponse2[]
  >(
    schedules.map((s) => ({
      ...s,
      enabled: existingIds.indexOf(s.id) !== -1,
    }))
  );

  React.useEffect(() => {
    if (accessGroup) {
      setAccessControlGroup(accessGroup);
    }
  }, [accessGroup]);

  const timeNumberToTimeString = function (time: number | undefined) {
    return `${Math.floor((time ? time : 0) / 60)
      .toString()
      .padStart(2, "0")}:${((time ? time : 0) % 60)
      .toString()
      .padStart(2, "0")}`;
  };

  const progressStyle: React.CSSProperties = {
    width: "100%",
  };

  const onEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const result = await apiClientFactory().patchAccessControlGroup(
      accessControlGroupId,
      accessControlGroup
    );
    setAccessControlGroup(result);
    refetch();
    setLoading(false);
  };

  const handleScheduleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    e: AccessScheduleResponse2
  ) => {
    const editedAccessSchedules = [...accessSchedules];
    for (const editedAccessSchedule of editedAccessSchedules) {
      if (editedAccessSchedule.id === e.id) {
        editedAccessSchedule.enabled = event.target.checked;
      }
    }
    setAccessSchedules(editedAccessSchedules);
  };

  const handleSaveSchedules = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault();

    factory.putAccessControlGroupSchedules(
      accessControlGroupId,
      accessSchedules.filter((e) => e.enabled).map((e) => e.id as string)
    );
  };

  return !accessControlGroup ? (
    <div className="jumbotron">
      <div className="progress">
        <div
          className="progress-bar progress-bar-striped active"
          role="progressbar"
          aria-valuenow={100}
          style={progressStyle}
        >
          Loading
        </div>
      </div>
    </div>
  ) : (
    <div className="row">
      <div className="col-xs-12">
        <ol className="breadcrumb">
          <li>
            <NavLink to={"/admin/accessControlGroups"} exact>
              Access Control Groups
            </NavLink>
          </li>
          <li className="active">
            {accessControlGroup
              ? accessControlGroup.name
              : accessControlGroupId}
          </li>
        </ol>
      </div>
      <div className="col-xs-12 col-sm-6">
        <h4>Details</h4>
        <div className="well">
          <form onSubmit={(e) => onEditSubmit(e)}>
            <div className="form-group">
              <label htmlFor="acgName">Name</label>
              <input
                className="form-control"
                name="acgName"
                placeholder="Name"
                value={accessControlGroup.name}
                onChange={(e) => {
                  setAccessControlGroup({
                    ...accessControlGroup,
                    name: e.target.value,
                  });
                }}
              />
              <p className="help-block"></p>
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <input
                className="form-control"
                name="description"
                placeholder="Description"
                value={accessControlGroup.description}
                onChange={(e) => {
                  setAccessControlGroup({
                    ...accessControlGroup,
                    description: e.target.value,
                  });
                }}
              />
              <p className="help-block"></p>
            </div>
            <div className="form-group">
              <label htmlFor="externalId">External ID</label>
              <input
                className="form-control"
                name="externalId"
                placeholder="External ID"
                value={accessControlGroup.externalId}
                onChange={(e) => {
                  setAccessControlGroup({
                    ...accessControlGroup,
                    externalId: e.target.value,
                  });
                }}
              />
              <p className="help-block"></p>
            </div>
            <div className="form-group">
              <label htmlFor="propertyExternalId">Property External ID</label>
              <input
                className="form-control"
                name="propertyExternalId"
                placeholder="Property External ID"
                value={accessControlGroup.propertyExternalId}
                onChange={(e) => {
                  setAccessControlGroup({
                    ...accessControlGroup,
                    propertyExternalId: e.target.value,
                  });
                }}
              />
              <p className="help-block"></p>
            </div>
            <div className="form-group">
              <label htmlFor="unitNumber">Unit Number</label>
              <input
                className="form-control"
                name="unitNumber"
                placeholder="Unit Number"
                value={accessControlGroup.unitNumber}
                onChange={(e) => {
                  setAccessControlGroup({
                    ...accessControlGroup,
                    unitNumber: e.target.value,
                  });
                }}
              />
              <p className="help-block"></p>
            </div>
            <div className="form-group">
              <label htmlFor="acgType">Type</label>
              <select
                className="form-control"
                name="acgType"
                value={accessControlGroup.type}
                onChange={(e) => {
                  setAccessControlGroup({
                    ...accessControlGroup,
                    type: e.target.value as AccessControlGroupType,
                  });
                }}
              >
                {Object.values(AccessControlGroupType).map((k) => (
                  <option key={k} value={k}>
                    {k === AccessControlGroupType.CommonDoors
                      ? "Common"
                      : k === AccessControlGroupType.Regular
                      ? "Regular"
                      : k === AccessControlGroupType.DefaultForProperty
                      ? "Default"
                      : k === AccessControlGroupType.Master
                      ? "Master"
                      : k === AccessControlGroupType.DefaultForGuests
                      ? "Default for Guests"
                      : k === AccessControlGroupType.Unit
                      ? "Unit"
                      : k}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <div className="checkbox">
                <label htmlFor={"noTourEnabled"}>
                  <input
                    type="checkbox"
                    checked={accessControlGroup.noTourEnabled}
                    id={"noTourEnabled"}
                    onChange={(e) => {
                      setAccessControlGroup({
                        ...accessControlGroup,
                        noTourEnabled: e.target.checked,
                      });
                    }}
                  />
                  No Tour Enabled
                </label>
              </div>
            </div>

            <button
              className="btn btn-primary"
              type="submit"
              disabled={loading}
            >
              {loading ? "Updating..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
      <div className="col-xs-12 col-sm-6">
        <SelectAccessControlGroupLocks
          accessControlGroupId={accessControlGroupId}
        />
      </div>
      <div className="col-xs-12 col-sm-6">
        <h4>Schedules</h4>
        <React.Fragment>
          {accessControlGroup.type === AccessControlGroupType.Master ? (
            <p className="alert alert-warning">
              A master access group cannot have restrictive schedules
            </p>
          ) : (
            <React.Fragment>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Enabled</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>S</th>
                    <th>M</th>
                    <th>T</th>
                    <th>W</th>
                    <th>T</th>
                    <th>F</th>
                    <th>S</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {accessSchedules
                    ? accessSchedules.map((e) => (
                        <tr key={e.id}>
                          <td>
                            <div className="checkbox">
                              <label>
                                <input
                                  type="checkbox"
                                  checked={e.enabled}
                                  onChange={(event) =>
                                    handleScheduleChange(event, e)
                                  }
                                />
                              </label>
                            </div>
                          </td>
                          <td>
                            <input
                              type="time"
                              className="form-control"
                              value={timeNumberToTimeString(
                                e.time ? e.time : 0
                              )}
                              disabled
                            />
                          </td>
                          <td>
                            <input
                              type="time"
                              className="form-control"
                              value={timeNumberToTimeString(
                                (e.time ? e.time : 0) +
                                  (e.length ? e.length : 0)
                              )}
                              disabled
                            />
                          </td>
                          <td>
                            <div className="checkbox">
                              <label>
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={e.sunday}
                                />
                              </label>
                            </div>
                          </td>
                          <td>
                            <div className="checkbox">
                              <label>
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={e.monday}
                                />
                              </label>
                            </div>
                          </td>
                          <td>
                            <div className="checkbox">
                              <label>
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={e.tuesday}
                                />
                              </label>
                            </div>
                          </td>
                          <td>
                            <div className="checkbox">
                              <label>
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={e.wednesday}
                                />
                              </label>
                            </div>
                          </td>
                          <td>
                            <div className="checkbox">
                              <label>
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={e.thursday}
                                />
                              </label>
                            </div>
                          </td>
                          <td>
                            <div className="checkbox">
                              <label>
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={e.friday}
                                />
                              </label>
                            </div>
                          </td>
                          <td>
                            <div className="checkbox">
                              <label>
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={e.saturday}
                                />
                              </label>
                            </div>
                          </td>
                          <td>
                            <Link
                              to={`/admin/sites/${e.siteId}/accessschedules`}
                              className="btn btn-primary"
                            >{`Edit`}</Link>
                          </td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
              <button
                className="btn btn-default"
                onClick={(event) => handleSaveSchedules(event)}
              >
                Save Schedules
              </button>
            </React.Fragment>
          )}
        </React.Fragment>
      </div>

      <div>
        <Link to="/admin/accessControlGroups">View all groups</Link>
      </div>
    </div>
  );
}

export default AccessControlGroupContainer;
