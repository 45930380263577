import * as React from "react";

export const Spinner = () => (
  <div
    style={{
      position: "relative",
    }}
  >
    <i
      className="fa fa-circle-o-notch fa-spin"
      style={{
        position: "absolute",
        top: 10,
        left: 0,
        color: "grey",
        fontSize: 17,
      }}
    />
  </div>
);
