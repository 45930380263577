import React from "react";
import Modal from "react-bootstrap-modal";
import "../shared/customModal.css";
import useGetAccountById, {
  accountCredentialInterface,
} from "./hooks/useGetAccountById";
import { Spinner } from "components/shared/Spinner";
import { SiteSystemType } from "api/ApiClient";
import usePatchAccount from "./hooks/usePatchAccount";

const EditAdminAccount = ({
  open,
  onClose,
  accountId,
}: {
  open: boolean;
  onClose: () => void;
  accountId: string;
}) => {
  const { data, isLoading } = useGetAccountById(accountId);
  const updateAccount = usePatchAccount(accountId);
  const [info, setInfo] = React.useState<accountCredentialInterface | null>(
    null
  );
  React.useEffect(() => {
    if (data) {
      //@ts-ignore
      setInfo(data.accountCredentials as accountCredentialInterface);
    }
  }, [data]);

  const onSubmit = () => {
    if (info !== null) {
      updateAccount.mutate(info, {
        onSuccess() {
          handleClose();
        },
        onError() {
          alert("Error updating account ");
        },
      });
    }
  };

  const handleClose = () => {
    onClose();
    setInfo(null);
  };

  return (
    <Modal
      show={open}
      onHide={handleClose}
      aria-labelledby="edit-account"
      aria-describedby="edit-account-by-Id"
    >
      <Modal.Header closeButton>
        <Modal.Title id="EditModalHeader">
          Edit Account: {accountId}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ width: "50vw" }}>
        {isLoading ? (
          <div
            style={{
              marginBottom: "50px",
            }}
          >
            <Spinner />
          </div>
        ) : (
          <>
            <div className="form-group">
              <label htmlFor="username">User Name *</label>
              <input
                className="form-control"
                name="username"
                placeholder="username"
                value={info?.username}
                //@ts-ignore
                onChange={(e) => setInfo({ ...info, username: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password *</label>
              <input
                className="form-control"
                name="password"
                type="password"
                placeholder="password"
                value={info?.password}
                //@ts-ignore
                onChange={(e) => setInfo({ ...info, password: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="clientId">ClientId *</label>
              <input
                className="form-control"
                name="clientId"
                placeholder="Client Id"
                value={info?.clientId}
                //@ts-ignore
                onChange={(e) => setInfo({ ...info, clientId: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="clientSecret">Client Secret *</label>
              <input
                className="form-control"
                name="clientSecret"
                type="password"
                placeholder="Client Secret"
                value={info?.clientSecret}
                onChange={(e) =>
                  //@ts-ignore
                  setInfo({ ...info, clientSecret: e.target.value })
                }
              />
            </div>
            {
              //@ts-ignore
              data?.systemType === SiteSystemType.Brivo && (
                <>
                  {" "}
                  <div className="form-group">
                    <label htmlFor="clientId">ClientId 2 *</label>
                    <input
                      className="form-control"
                      name="clientId"
                      placeholder="Client Id"
                      value={info?.clientId2}
                      onChange={(e) =>
                        //@ts-ignore
                        setInfo({ ...info, clientId2: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="clientSecret">Client Secret 2 *</label>
                    <input
                      className="form-control"
                      name="clientSecret"
                      type="password"
                      placeholder="Client Secret"
                      value={info?.clientSecret2}
                      onChange={(e) =>
                        //@ts-ignore
                        setInfo({ ...info, clientSecret2: e.target.value })
                      }
                    />
                  </div>
                </>
              )
            }

            <div className="form-group">
              <label htmlFor="accessToken"> Access Token *</label>
              <input
                className="form-control"
                name="accessToken"
                placeholder=" Access Token"
                value={info?.accessToken}
                onChange={(e) =>
                  //@ts-ignore
                  setInfo({ ...info, accessToken: e.target.value })
                }
              />
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-md btn-primary"
          onClick={() => onSubmit()}
          disabled={updateAccount.isLoading}
        >
          Save
        </button>
        <Modal.Dismiss
          className="btn btn-default"
          disabled={updateAccount.isLoading}
        >
          Close
        </Modal.Dismiss>
      </Modal.Footer>
    </Modal>
  );
};

export default EditAdminAccount;
