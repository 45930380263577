import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import {
  FetchParams,
  reduceSetFetchParams,
  defaultPageSize,
  reduceNextPage,
  reducePrevPage,
  apiClientFactory,
} from "../helpers";

import { AccountResponse, SiteSystemType } from "../api/ApiClient";

export interface AccountsState extends FetchParams {
  isLoading: boolean;
  accounts: AccountResponse[];
}

interface RequestAccountsAction {
  type: "REQUEST_ACCOUNTS";
}

interface SetFetchParams extends FetchParams {
  type: "SETFETCHPARAMS_ACCOUNTS";
}

interface NextPage {
  type: "NEXTPAGE_ACCOUNTS";
}

interface PrevPage {
  type: "PREVPAGE_ACCOUNTS";
}

interface ReceiveAccountsAction extends FetchParams {
  type: "RECEIVE_ACCOUNTS";
  accounts: AccountResponse[];
}

interface RegisterAccountActionPayload {
  systemType: SiteSystemType;
  name: string;

  //credentials
  clientId: string;
  clientSecret: string;
  clientId2?: string;
  clientSecret2?: string;
  username: string;
  password: string;
  accessToken: string;
  refreshToken: string;
  apiUrl: string;
  tokenUrl: string;
}
interface RegisterAccountAction extends RegisterAccountActionPayload {
  type: "REGISTER_ACCOUNT";
}

interface RegisterAccountWithSharedCredPayload {
  name: string;
  sharedCredId: string;
}

interface DeleteAccountActionPayload {
  id: string;
}
interface DeleteAccountAction extends DeleteAccountActionPayload {
  type: "DELETE_ACCOUNT";
}

type KnownAction =
  | RequestAccountsAction
  | SetFetchParams
  | NextPage
  | PrevPage
  | ReceiveAccountsAction
  | RegisterAccountAction
  | DeleteAccountAction;

export const actionCreators = {
  setFetchParamAccounts:
    ({
      page,
      pageSize,
      searchTerm,
    }: FetchParams): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const state = getState();
      if (state.accounts.isLoading) return;

      dispatch({
        type: "SETFETCHPARAMS_ACCOUNTS",
        page,
        pageSize,
        searchTerm,
      });

      await dispatch(actionCreators.requestAccounts() as any);
    },
  nextAccountPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.accounts.isLoading) return;

      dispatch({
        type: "NEXTPAGE_ACCOUNTS",
      });

      await dispatch(actionCreators.requestAccounts() as any);
    },
  prevAccountPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.accounts.isLoading) return;

      dispatch({
        type: "PREVPAGE_ACCOUNTS",
      });

      await dispatch(actionCreators.requestAccounts() as any);
    },

  requestAccounts: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const {
      accounts: { page, pageSize, searchTerm, accounts: curAccounts },
    } = getState();

    const fetchTask = apiClientFactory()
      .getAccounts(page, pageSize, searchTerm, undefined)
      .then(
        (data) => {
          dispatch({
            type: "RECEIVE_ACCOUNTS",
            accounts: data,
            page,
            pageSize,
            searchTerm,
          });
        },
        (_error) => {
          dispatch({
            type: "RECEIVE_ACCOUNTS",
            accounts: curAccounts,
            page,
            pageSize,
            searchTerm,
          });
        }
      );

    dispatch({
      type: "REQUEST_ACCOUNTS",
    });
    return fetchTask;
  },

  registerAccount:
    (info: RegisterAccountActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const {
        me: { user },
      } = getState();
      let paylaod: any = {
        tenantExternalId:
          user && user.activeTenantId ? user.activeTenantId : "",
        systemType: info.systemType,
        name: info.name,
        clientId: info.clientId,
        clientSecret: info.clientSecret,
        username: info.username,
        password: info.password,
        accessToken: info.accessToken,
        refreshToken: info.refreshToken,
        apiUrl: info.apiUrl,
        tokenUrl: info.tokenUrl,
      };
      if (info.systemType === SiteSystemType.Brivo) {
        paylaod = {
          ...paylaod,
          clientId2: info.clientId2,
          clientSecret2: info.clientSecret2,
        };
      }
      const json = await apiClientFactory().postAccount(paylaod);

      return json;
    },

  registerAccountWithSharedCredentials:
    (info: RegisterAccountWithSharedCredPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const {
        me: { user },
      } = getState();
      const json = await apiClientFactory().postAccountWithSharedCredentials({
        tenantExternalId:
          user && user.activeTenantId ? user.activeTenantId : "",
        name: info.name,
        sharedAccountCredentialsId: info.sharedCredId,
      });

      return json;
    },

  deleteAccount:
    (info: DeleteAccountActionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      await apiClientFactory().deleteAccount(info.id);

      await dispatch(actionCreators.requestAccounts() as any);
    },
};

const unloadedState: AccountsState = {
  accounts: [],
  isLoading: false,
  page: 0,
  pageSize: defaultPageSize,
  searchTerm: "",
};

export const reducer: Reducer<AccountsState> = (
  state: AccountsState = unloadedState,
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "REQUEST_ACCOUNTS":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVE_ACCOUNTS":
      return {
        ...state,
        accounts: action.accounts,
        isLoading: false,
        page: action.page,
        pageSize: action.pageSize,
        searchTerm: action.searchTerm,
      };

    case "SETFETCHPARAMS_ACCOUNTS":
      return reduceSetFetchParams(state.isLoading, action, state);
    case "NEXTPAGE_ACCOUNTS":
      return reduceNextPage(state.accounts, state.isLoading, state);
    case "PREVPAGE_ACCOUNTS":
      return reducePrevPage(state.isLoading, state);

    case "REGISTER_ACCOUNT":
    case "DELETE_ACCOUNT":
    default:
      return state;
  }
};
